import { LinkProps, createTheme } from '@mui/material'
import { LinkBehavior } from './LinkBehavior'
import { SelectIcon } from '../icons'

export const theme = createTheme({
  palette: {
    primary: { main: '#0C4F3F' },
    secondary: { main: '#ABABAB' },
    text: { primary: '#4B4B4B' },
    error: { main: '#BC3A31' }
  },
  typography: {
    h1: {
      fontSize: 32,
      fontWeight: 700,
      lineHeight: '40px',
      letterSpacing: 0
    },
    h2: {
      fontSize: 18,
      fontWeight: 500,
      lineHeight: '24px',
      letterSpacing: 0
    }
  },
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior
      } as LinkProps
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: 12,
          textTransform: 'none',
          borderRadius: 8,
          padding: '12px 16px',
          lineHeight: '16px',
          height: 40,
          fontWeight: 500,
          border: '1px solid',
          letterSpacing: 0
        },
        startIcon: {
          width: 12,
          marginLeft: 0,
          marginRight: 10,
          fill: 'currentcolor'
        }
      },
      defaultProps: { variant: 'outlined', disableElevation: true, LinkComponent: LinkBehavior }
    },
    MuiIconButton: {
      styleOverrides: { root: { borderRadius: 8 } }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: 15,
          fontWeight: 500,
          textTransform: 'none',
          lineHeight: '21px'
        }
      },
      defaultProps: { LinkComponent: LinkBehavior }
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          height: 1
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          height: 52,
          fontSize: 15,
          color: '#4B4B4B',
          '&.MuiInputBase-adornedEnd': {
            paddingRight: 24
          }
        },
        input: {
          '&:-webkit-autofill': {
            WebkitBackgroundClip: 'text',
            backgroundClip: 'text'
          },
          '&::placeholder': {
            color: '#ABABAB',
            opacity: 1,
            fontSize: 15,
            letterSpacing: 0,
            fontWeight: 400,
            fontStyle: 'normal'
          },
          height: 'inherit'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 8
        },
        notchedOutline: {
          padding: '0px 18px',
          border: '1px solid #ABABAB',
          boxShadow: '0px 2px 3px 0px #00000014',
          '& legend': {
            fontSize: '0.80em'
          }
        },
        input: {
          padding: '0 24px',
          '&.Mui-disabled': {
            opacity: 1,
            color: '#4B4B4B',
            WebkitTextFillColor: 'inherit'
          }
        },
        multiline: {
          height: 'inherit',
          padding: '16px 24px'
        },
        inputMultiline: {
          padding: 0
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: 15,
          color: '#ABABAB',
          fontStyle: 'normal',
          letterSpacing: 0,
          transform: 'translate(24px, 16px) scale(1)',
          '&.Mui-disabled': { color: '#ABABAB' }
        },
        shrink: {
          transform: 'translate(24px, -9px) scale(0.8)'
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: 15,
          padding: '10px 28px',
          lineHeight: '20px',
          '&.Mui-selected': {
            backgroundColor: '#D3DFDC',
            '&:hover': {
              backgroundColor: '#D3DFDC'
            }
          },
          '&:hover': {
            backgroundColor: '#ABABAB33'
          }
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          boxShadow: '-1px -1px 4px 0px #00000026, 1px 1px 4px 0px #00000026'
        }
      }
    },
    MuiList: {
      styleOverrides: {
        root: {
          paddingTop: 0,
          paddingBottom: 0
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          right: 14,
          top: 'auto'
        }
      },
      defaultProps: { IconComponent: SelectIcon }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          letterSpacing: 0
        }
      }
    }
  }
})

import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useTypeSelector } from '../../hooks/useTypeSelector'

interface PrivateRouteProps {
  children?: any
  roles: string[]
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ children, roles }) => {
  const location = useLocation()
  const { isAuth, user } = useTypeSelector((state) => state.user)
  return isAuth ? (
    roles.includes(user?.role) ? (
      children
    ) : (
      <Navigate to="/403" state={{ from: location.pathname }} replace />
    )
  ) : (
    <Navigate to="/login" state={{ from: location.pathname }} />
  )
}

import { useMemo, useRef } from 'react'

export type ClickHandler<T> = (param: T) => void

export const useDoubleClick = <T>(onSingleClick: ClickHandler<T>, onDoubleClick: ClickHandler<T>, latency = 250) => {
  const clickCount = useRef(0)

  const clickHandler: ClickHandler<T> = useMemo(() => {
    return (param) => {
      clickCount.current += 1
      setTimeout(() => {
        if (clickCount.current === 1) {
          onSingleClick(param)
        } else if (clickCount.current === 2) {
          onDoubleClick(param)
        }
        clickCount.current = 0
      }, latency)
    }
  }, [onSingleClick, onDoubleClick, latency])

  return clickHandler
}

import React, { useEffect } from 'react'
import { Typography } from '@mui/material'
import { Breadcrumbs } from '../../components/Breadcrumbs/Breadcrumbs'
import { MuiTabs } from '../../components/MuiComponents/MuiTabs/MuiTabs'
import { useActions } from '../../hooks/useActions'
import { VgTable, VgTableColumnType } from '../../components/VgTable/VgTable'
import { Warehouse } from '../../types/warehousesTypes'
import { useTypeSelector } from '../../hooks/useTypeSelector'
import { useNavigate } from 'react-router-dom'
import { Section } from '../../types/sectionsTypes'
import { LabelRow } from '../../components/Label/LabelRow'
import { StyledCell } from '../../infrasturcture/theme/styled'
import styles from './Users.module.css'
import { Label } from '../../components/Label/Label'

const warehousesColumns: VgTableColumnType<Warehouse>[] = [
  {
    key: 'name',
    title: 'Название',
    width: '30%'
  },
  {
    key: 'region',
    title: 'Регион',
    width: '25%',
    render: (_, { region }) => region
  },
  {
    key: 'address',
    title: 'Почтовый адрес',
    width: '45%',
    render: (_, item) => <StyledCell>{item.address}</StyledCell>
  }
]

const sectionsColumns: VgTableColumnType<Section>[] = [
  {
    key: 'name',
    title: 'Название',
    width: '30%'
  },
  {
    key: 'subsections',
    title: 'Подразделы',
    width: '70%',
    render: (_, { subsections }) => (
      <LabelRow style={{ margin: '-7px 0' }}>
        {subsections?.map((item, i) => (
          <Label key={`${item}-${i}`} text={item} />
        ))}
      </LabelRow>
    )
  }
]

export const Access: React.FC = () => {
  const navigate = useNavigate()
  const { getWarehousesThunkCreator, getSectionsThunkCreator } = useActions()
  const { warehouses } = useTypeSelector((state) => state.warehouses)
  const { sections } = useTypeSelector((state) => state.sections)

  const tabs = [
    {
      label: 'Склады',
      content: (
        <VgTable
          onRowClick={(w) => navigate(`/users/access/${w.id}?type=warehouses`)}
          columns={warehousesColumns}
          data={warehouses}
        />
      )
    },
    {
      label: 'Разделы',
      content: (
        <VgTable onRowClick={(s) => navigate(`/users/access/${s.id}?type=sections`)} columns={sectionsColumns} data={sections} />
      )
    }
  ]

  useEffect(() => {
    getWarehousesThunkCreator({ isBooking: false })
    getSectionsThunkCreator()
  }, [])

  return (
    <div className={styles.container}>
      <Breadcrumbs />
      <Typography variant="h1" color="text.primary" sx={{ mb: '28px' }}>
        Доступы
      </Typography>
      <MuiTabs tabs={tabs} />
    </div>
  )
}

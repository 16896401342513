import React from 'react'
import styles from './BalancesProduct.module.css'

interface BalancesProductProps {
  photo: string | null
  name: string
  code: string
}

export const BalancesProduct: React.FC<BalancesProductProps> = ({ photo, name, code }) => {
  return (
    <div className={styles.container}>
      <div className={styles.photo}>
        {photo && (
          <img className={styles.photoImage} src={`${process.env.REACT_APP_API_URL}/static/images/${photo}`} alt={name} />
        )}
      </div>
      <div className={styles.text}>
        <span className={styles.name}>{name}</span>
        <span className={styles.code}>{code}</span>
      </div>
    </div>
  )
}

import React, { useEffect, useMemo, useRef, useState } from 'react'
import { InputSearch } from '../Input/InputSearch'
import { Box, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper, Typography } from '@mui/material'

interface Option<T> {
  label: string
  value: T
}

interface GroupedOptions<T> {
  [key: string]: Option<T>[]
}

interface OperationSearchProps<T> {
  options: Option<T>[]
  onSelect: (value: T) => void
  renderOption?: (value: T) => React.ReactNode
  onFocus?: () => void
  disabled?: boolean
}

export const OperationSearch = <T,>({ options, onSelect, renderOption, onFocus, disabled = false }: OperationSearchProps<T>) => {
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState<string>('')
  const anchorRef = useRef<HTMLButtonElement>(null)

  const handleOpen = () => {
    !disabled && setOpen(true)
  }

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }

  const handleSelect = (value: T) => () => {
    onSelect(value)
    setOpen(false)
    setValue('')
  }

  const prevOpen = React.useRef(open)

  const searchOptions = useMemo(() => {
    return options
      ?.filter((item) => item.label?.toLowerCase().includes(value.toLowerCase()))
      ?.sort((a, b) => a.label.localeCompare(b.label))
      .reduce<GroupedOptions<T>>((grouped, option) => {
        const key = option.label.charAt(0).toUpperCase()
        return { ...grouped, [key]: [...(grouped[key] || []), option] }
      }, {})
  }, [value, options])

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus()
    }

    prevOpen.current = open
  }, [open])

  return (
    <Box
      ref={anchorRef}
      id="composition-button"
      aria-controls={open ? 'composition-menu' : undefined}
      aria-expanded={open ? 'true' : undefined}
      aria-haspopup="true"
      sx={{ position: 'relative', width: '100%' }}
    >
      <InputSearch
        onClick={handleOpen}
        onChange={(e) => setValue(e.target.value)}
        onFocus={onFocus}
        value={value}
        placeholder="Введите название или код товара"
      />

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
        style={{ zIndex: 100, width: 'inherit' }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom-start' ? 'center top' : 'center bottom'
            }}
          >
            <Paper sx={{ maxHeight: 343, overflowY: 'auto' }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="composition-menu" aria-labelledby="composition-button">
                  {Object.entries(searchOptions).map(([key, options]) => (
                    <MenuList key={key}>
                      <Typography variant="h3" sx={{ fontWeight: 700, fontSize: '15px', lineHeight: '21px', p: '15px 28px' }}>
                        {key}
                      </Typography>
                      {options.map(({ label, value }, i) => (
                        <MenuItem key={i} onClick={handleSelect(value)} sx={{ whiteSpace: 'normal', pt: '8px', pb: '8px' }}>
                          {renderOption ? renderOption(value) : label}
                        </MenuItem>
                      ))}
                    </MenuList>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  )
}

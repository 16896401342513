import React from 'react'
import { LazyLoadImage, LazyLoadImageProps } from 'react-lazy-load-image-component'

interface ImageProps extends LazyLoadImageProps {
  className?: string
}

export const Image: React.FC<ImageProps> = ({ className, ...rest }) => {
  return <LazyLoadImage className={className} {...rest} />
}

/* eslint-disable no-unused-vars */
import React from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import logo from '../../assets/images/icons/logo.svg'
import { useActions } from '../../hooks/useActions'
import { useTypeSelector } from '../../hooks/useTypeSelector'
import { Link, useNavigate } from 'react-router-dom'
import styles from './Login.module.css'
import { Button } from '@mui/material'
import { InputPassword } from '../../components/Input/InputPassword'
import { TempInput } from '../../components/Input/TempInput'
import { EmailIcon } from '../../infrasturcture/icons'

interface LoginFields {
  email: string
  password: string
}

export const Login: React.FC = () => {
  const navigate = useNavigate()
  const {
    control,
    handleSubmit,
    formState: { isSubmitting }
  } = useForm<LoginFields>({ defaultValues: { email: '', password: '' } })

  const { errors, authError } = useTypeSelector((state) => state.user)
  const { loginThunkCreator } = useActions()

  const onSubmit: SubmitHandler<LoginFields> = async (data) => {
    const { email, password } = data
    return loginThunkCreator(email, password, navigate)
  }

  return (
    <div className={styles.container}>
      <div className={styles.form}>
        <div className={styles.formIcon}>
          <img src={logo} alt="logo" />
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TempInput
            className={styles.formInput}
            control={control}
            name="email"
            label={(errors?.email || (authError === 'Unauthorized' ? 'У вас нет доступа' : null)) ?? 'Почта'}
            placeholder="Почта"
            icon={<EmailIcon />}
            error={!!errors?.email}
            size="medium"
          />
          <InputPassword
            className={styles.formInput}
            control={control}
            name="password"
            label={errors?.password ?? 'Пароль'}
            placeholder="Пароль"
            error={!!errors?.password}
            size="medium"
          />
          <Button className={styles.button} variant="contained" color="primary" type="submit" disabled={isSubmitting} fullWidth>
            Войти
          </Button>
        </form>
        {/* <Link className={styles.link} to="/login">
          Забыли пароль?
        </Link> */}
      </div>
    </div>
  )
}

import { CircularProgress } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'

const style = {
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'gray'
}

export const PageLoader: React.FC = () => {
  return (
    <Box sx={style}>
      <CircularProgress color="inherit" />
    </Box>
  )
}

import { ManufacturersAction, ManufacturersActionTypes, ManufacturersState } from '../../types/manufacturersTypes'

const initialState: ManufacturersState = {
  manufacturers: [],
  manufacturer: null,
  isLoaded: false,
  errors: {}
}

export const manufacturersReducer = (state = initialState, action: ManufacturersAction): ManufacturersState => {
  switch (action.type) {
    case ManufacturersActionTypes.GET_MANUFACTURERS:
      return {
        ...state,
        isLoaded: false,
        manufacturers: action.payload,
        manufacturer: null
      }
    case ManufacturersActionTypes.GET_MANUFACTURER:
      return {
        ...state,
        isLoaded: false,
        manufacturer: action.payload
      }
    case ManufacturersActionTypes.UPDATE_MANUFACTURER:
      return {
        ...state,
        errors: {},
        manufacturer: action.payload
      }
    case ManufacturersActionTypes.SET_MANUFACTURER:
      return {
        ...state,
        manufacturer: action.payload
      }
    case ManufacturersActionTypes.SET_MANUFACTURERS_LOADED:
      return {
        ...state,
        isLoaded: action.payload
      }
    case ManufacturersActionTypes.SET_MANUFACTURERS_ERRORS:
      return {
        ...state,
        errors: action.payload
      }
    default:
      return state
  }
}

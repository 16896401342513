import { WarehousesAction, WarehousesActionTypes, WarehousesState } from '../../types/warehousesTypes'

const initialState: WarehousesState = {
  warehouses: [],
  warehouse: null,
  filterWarehouses: [],
  isLoaded: false,
  errors: {}
}

export const warehousesReducer = (state = initialState, action: WarehousesAction): WarehousesState => {
  switch (action.type) {
    case WarehousesActionTypes.GET_WAREHOUSES:
      return {
        ...state,
        isLoaded: false,
        warehouses: action.payload,
        errors: {},
        warehouse: null
      }
    case WarehousesActionTypes.UPDATE_WAREHOUSE:
      return {
        ...state,
        errors: {},
        warehouse: action.payload
      }
    case WarehousesActionTypes.GET_WAREHOUSE:
      return {
        ...state,
        warehouse: action.payload,
        isLoaded: false
      }
    case WarehousesActionTypes.GET_FILTER_WAREHOUSES:
      return {
        ...state,
        filterWarehouses: action.payload
      }
    case WarehousesActionTypes.SET_WAREHOUSES_LOADED:
      return {
        ...state,
        isLoaded: action.payload
      }

    case WarehousesActionTypes.SET_WAREHOUSE:
      return {
        ...state,
        warehouse: action.payload
      }
    case WarehousesActionTypes.SET_WAREHOUSES:
      return {
        ...state,
        warehouses: action.payload
      }
    case WarehousesActionTypes.SET_FILTER_WAREHOUSES:
      return {
        ...state,
        filterWarehouses: action.payload
      }
    case WarehousesActionTypes.SET_WAREHOUSES_ERRORS:
      return {
        ...state,
        errors: action.payload
      }
    default:
      return state
  }
}

import { MenuItem, TextField } from '@mui/material'
import React from 'react'
import { Control, Controller } from 'react-hook-form'

interface TempSelectProps {
  control: Control<any>
  onChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  label: string
  name: string
  error?: boolean
  options: Array<{ label: string; value: any }>
  fullWidth?: boolean
  disabled?: boolean
  required?: boolean
}

export const TempSelect: React.FC<TempSelectProps> = ({
  control,
  onChange,
  label,
  name,
  options,
  error = false,
  fullWidth = true,
  disabled = false,
  required = false
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field }) => (
        <TextField
          {...field}
          onChange={(e) => {
            field.onChange(e)
            onChange?.(e)
          }}
          select
          label={label}
          disabled={disabled}
          required={required}
          error={error}
          fullWidth={fullWidth}
          InputLabelProps={error ? { shrink: true } : {}}
          SelectProps={{
            MenuProps: {
              sx: { mt: '16px' },
              PaperProps: {
                sx: { maxHeight: 205, maxWidth: 200 }
              }
            }
          }}
          sx={{ '& .MuiSelect-icon': { display: disabled ? 'none' : 'block' } }}
        >
          {options.map((item, i) => (
            <MenuItem key={i} value={item.value} sx={{ whiteSpace: 'pre-wrap' }}>
              {item.label}
            </MenuItem>
          ))}
        </TextField>
      )}
    />
  )
}

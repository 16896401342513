import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useTypeSelector } from '../../hooks/useTypeSelector'

interface PublicRouteProps {
  children?: any
}

interface LocationState {
  from: string
}

export const GuestRoute: React.FC<PublicRouteProps> = ({ children }) => {
  const location = useLocation()
  const from = (location.state as LocationState)?.from || '/products'
  const { isAuth } = useTypeSelector(state => state.user)

  return isAuth ? <Navigate to={from} /> : children
}

import React from 'react'
import { useTypeSelector } from '../../hooks/useTypeSelector'
import { roles } from '../../types/userTypes'
import { Button, Typography } from '@mui/material'
import { Breadcrumbs } from '../../components/Breadcrumbs/Breadcrumbs'
import { StyledHeader } from '../../infrasturcture/theme/styled'
import { PlusIcon } from '../../infrasturcture/icons'
import { ProductsContent } from './ui/ProductsContent'
import styles from './Products.module.css'

const accessRoles: string[] = [roles.ADMINISTRATOR, roles.SUPER_MANAGER, roles.MANAGER]

export const Products: React.FC = () => {
  const { user } = useTypeSelector((state) => state.user)

  return (
    <div className={styles.container}>
      <Breadcrumbs />
      <StyledHeader sx={{ mb: '40px' }}>
        <Typography variant="h1">Товары</Typography>
        {accessRoles.includes(user?.role) && (
          <Button href="/products/create" color="primary" startIcon={<PlusIcon />}>
            Добавить товар
          </Button>
        )}
      </StyledHeader>
      <ProductsContent />
    </div>
  )
}

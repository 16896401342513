import { instance } from '../infrasturcture/http'
import { Product, ProductFilters } from '../types/productsTypes'

export default class ProductsApi {
  static async getProducts(
    limit: number | null = 30,
    page: number | null = 1,
    search: string | null = null,
    filters: ProductFilters | null = null
  ) {
    const response = await instance.get('products', {
      params: {
        limit,
        page,
        search,
        section_id: filters?.section?.id,
        subsections: filters?.subsections.join(',') || null,
        custom: filters?.custom || null
      }
    })
    return response.data.data
  }

  static async getProduct(id: string) {
    const response = await instance.get(`products/${id}`)
    return response.data.data
  }

  static async createProduct(product: Product) {
    const formData = new FormData()
    Object.entries(product).forEach(([name, value]) => formData.append(name, value))
    const response = await instance.post('products', formData)
    return response.data.data
  }

  static async editProduct(product: Product) {
    const formData = new FormData()
    Object.entries(product).forEach(([name, value]) => formData.append(name, value))
    const response = await instance.post(`products/${product.id}`, formData)
    return response.data.data
  }

  static async deleteProduct(id: string) {
    const response = await instance.delete(`products/${id}`)
    return response.data.data
  }
}

import React, { useState } from 'react'
import { Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { Sidebar } from '../Sidebar/Sidebar'
import { useTypeSelector } from '../../hooks/useTypeSelector'
import { Content, style } from './DefaultLayout.style'
import { Outlet } from 'react-router-dom'
import { rolesRus } from '../../types/userTypes'

export const DefaultLayout: React.FC = () => {
  const { user, isAuth } = useTypeSelector((state) => state.user)
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(true)

  const toggleIsMenuOpen = () => setIsMenuOpen((prev) => !prev)

  return (
    <Grid sx={style.container} container flexWrap="nowrap">
      {isAuth && <Sidebar toggleIsOpen={toggleIsMenuOpen} isOpen={isMenuOpen} />}
      <Content isMenuOpen={isMenuOpen}>
        {isAuth && (
          <Box sx={style.userInfo}>
            <Typography variant="h5" sx={style.username}>
              {user?.lastname} {user.firstname}
            </Typography>
            <Typography sx={style.userRole} component="span">
              {rolesRus[user.role]}
            </Typography>
          </Box>
        )}
        <Outlet />
      </Content>
    </Grid>
  )
}

import React from 'react'
import {
  ArrowsIcon,
  BalanceIcon,
  BookingsIcon,
  CarIcon,
  CubIcon,
  LocationIcon,
  OrdersIcon,
  ReportIcon,
  UserIcon,
  UsersIcon
} from '../icons'
import { roles } from '../../types/userTypes'
import { Balances } from '../../pages/balances/Balances'
import { Bookings } from '../../pages/bookings/Bookings'
import { Operations } from '../../pages/operations/Operations'
import { Orders } from '../../pages/orders/Orders'
import { Products } from '../../pages/products/Products'
import { Profile } from '../../pages/profile/Profile'
import { Users } from '../../pages/users/Users'
import { Warehouses } from '../../pages/warehouses/Warehouses'
import { Supplier } from '../../pages/supplier/Supplier'
import { Reports } from '../../pages/report/Reports'
import { Manufacturers } from '../../pages/manufacturers/Manufacturers'
import { Sections } from '../../pages/sections/Sections'
import { CreateBooking } from '../../pages/bookings/CreateBooking'
import { CreateOrder } from '../../pages/orders/CreateOrder'
import { EditProduct } from '../../pages/products/EditProduct'
import { CreateProduct } from '../../pages/products/CreateProduct'
import { ChangePassword } from '../../pages/profile/ChangePassword'
import { Error } from '../../pages/error/Error'
import { CreateUser } from '../../pages/users/CreateUser'
import { EditUser } from '../../pages/users/EditUser'
import { Access } from '../../pages/users/Access'
import { AccessType } from '../../pages/users/AccessType'
import { CreateSection } from '../../pages/sections/CreateSection'
import { EditSection } from '../../pages/sections/EditSection'
import { CreateManufacturer } from '../../pages/manufacturers/CreateManufacturer'
import { EditManufacturer } from '../../pages/manufacturers/EditManufacturer'
import { CreateWarehouse } from '../../pages/warehouses/CreateWarehouse'
import { EditWarehouse } from '../../pages/warehouses/EditWarehouse'
import { Report } from '../../pages/report/Report'
import { CreateOperation } from '../../pages/operations/CreateOperation'
import { OrdersPosting } from '../../pages/operations/OrdersPosting'
import { EditOperation } from '../../pages/operations/EditOperation'
import { EditBooking } from '../../pages/bookings/EditBooking'
import { EditOrder } from '../../pages/orders/EditOrder'
import { BookingsDone } from '../../pages/operations/BookingsDone'

export interface Route {
  label: string
  path: string
  component: React.FC<any>
  icon?: React.FC<React.SVGProps<SVGSVGElement>>
  nestedLabel?: string
  isDisplay: boolean
  roles: roles[]
  childrens?: Route[]
}

export const routes: Route[] = [
  {
    label: 'Пользователи',
    nestedLabel: 'Все пользователи',
    path: '/users',
    component: Users,
    icon: UsersIcon,
    isDisplay: true,
    roles: [roles.SUPER_MANAGER],
    childrens: [{ label: 'Доступы', path: '/users/access', component: Access, isDisplay: false, roles: [roles.SUPER_MANAGER] }]
  },
  {
    label: 'Новый пользователь',
    path: '/users/create',
    component: CreateUser,
    isDisplay: false,
    roles: [roles.SUPER_MANAGER]
  },
  {
    label: 'Редактирование пользователя',
    path: '/users/:id',
    component: EditUser,
    isDisplay: false,
    roles: [roles.SUPER_MANAGER]
  },
  {
    label: 'Доступы',
    path: '/users/access',
    component: Access,
    isDisplay: false,
    roles: [roles.SUPER_MANAGER]
  },
  {
    label: 'Редактирование доступы',
    path: '/users/access/:id',
    component: AccessType,
    isDisplay: false,
    roles: [roles.SUPER_MANAGER]
  },
  {
    label: 'Товары',
    nestedLabel: 'Все товары',
    path: '/products',
    component: Products,
    icon: CubIcon,
    isDisplay: true,
    roles: Object.values(roles),
    childrens: [
      { label: 'Разделы', path: '/products/sections', component: Sections, isDisplay: false, roles: [roles.SUPER_MANAGER] },
      {
        label: 'Производители',
        path: '/products/manufacturers',
        component: Manufacturers,
        isDisplay: false,
        roles: [roles.SUPER_MANAGER]
      }
    ]
  },
  {
    label: 'Редактирование товара',
    path: '/products/:id',
    component: EditProduct,
    isDisplay: false,
    roles: [roles.SUPER_MANAGER, roles.ADMINISTRATOR, roles.MANAGER, roles.DEALER]
  },
  {
    label: 'Новый товар',
    path: '/products/create',
    component: CreateProduct,
    isDisplay: false,
    roles: [roles.SUPER_MANAGER, roles.ADMINISTRATOR, roles.MANAGER]
  },
  {
    label: 'Разделы',
    path: '/products/sections',
    component: Sections,
    isDisplay: false,
    roles: [roles.SUPER_MANAGER]
  },
  {
    label: 'Новый раздел',
    path: '/products/sections/create',
    component: CreateSection,
    isDisplay: false,
    roles: [roles.SUPER_MANAGER]
  },
  {
    label: 'Редактирование раздела',
    path: '/products/sections/:id',
    component: EditSection,
    isDisplay: false,
    roles: [roles.SUPER_MANAGER]
  },
  {
    label: 'Производители',
    path: '/products/manufacturers',
    component: Manufacturers,
    isDisplay: false,
    roles: [roles.SUPER_MANAGER]
  },
  {
    label: 'Новый производитель',
    path: '/products/manufacturers/create',
    component: CreateManufacturer,
    isDisplay: false,
    roles: [roles.SUPER_MANAGER]
  },
  {
    label: 'Редактирование производителя',
    path: '/products/manufacturers/:id',
    component: EditManufacturer,
    isDisplay: false,
    roles: [roles.SUPER_MANAGER]
  },
  {
    label: 'Склады',
    path: '/warehouses',
    component: Warehouses,
    icon: LocationIcon,
    isDisplay: true,
    roles: [roles.SUPER_MANAGER, roles.ADMINISTRATOR]
  },
  {
    label: 'Новый склад',
    path: '/warehouses/create',
    component: CreateWarehouse,
    isDisplay: false,
    roles: [roles.SUPER_MANAGER, roles.ADMINISTRATOR]
  },
  {
    label: 'Редактирование склада',
    path: '/warehouses/:id',
    component: EditWarehouse,
    isDisplay: false,
    roles: [roles.SUPER_MANAGER, roles.ADMINISTRATOR]
  },
  {
    label: 'Остатки',
    path: '/balances',
    component: Balances,
    icon: BalanceIcon,
    isDisplay: true,
    roles: [roles.SUPER_MANAGER, roles.ADMINISTRATOR]
  },
  {
    label: 'Операции',
    path: '/operations',
    component: Operations,
    icon: ArrowsIcon,
    isDisplay: true,
    roles: [roles.SUPER_MANAGER, roles.ADMINISTRATOR]
  },
  {
    label: 'Операции',
    path: '/operations/:id',
    component: EditOperation,
    isDisplay: false,
    roles: [roles.SUPER_MANAGER, roles.ADMINISTRATOR]
  },
  {
    label: 'Создание операции',
    path: '/operations/add/:type',
    component: CreateOperation,
    isDisplay: false,
    roles: [roles.SUPER_MANAGER, roles.ADMINISTRATOR]
  },
  {
    label: 'Бронирования для списания',
    path: '/operations/add/write_off/bookings_done',
    component: BookingsDone,
    isDisplay: false,
    roles: [roles.SUPER_MANAGER, roles.ADMINISTRATOR]
  },
  {
    label: 'Заявки для оприходования',
    path: '/operations/add/:type/orders_posting',
    component: OrdersPosting,
    isDisplay: false,
    roles: [roles.SUPER_MANAGER, roles.ADMINISTRATOR]
  },

  {
    label: 'Бронирования',
    path: '/bookings',
    component: Bookings,
    icon: BookingsIcon,
    isDisplay: true,
    roles: [roles.SUPER_MANAGER, roles.ADMINISTRATOR, roles.MANAGER, roles.DEALER]
  },
  {
    label: 'Новое бронирование',
    path: '/bookings/create',
    component: CreateBooking,
    isDisplay: false,
    roles: [roles.SUPER_MANAGER, roles.ADMINISTRATOR, roles.MANAGER, roles.DEALER]
  },
  {
    label: 'Бронирование',
    path: '/bookings/:id',
    component: EditBooking,
    isDisplay: false,
    roles: [roles.SUPER_MANAGER, roles.ADMINISTRATOR, roles.MANAGER, roles.DEALER]
  },
  {
    label: 'Заявки',
    path: '/orders',
    component: Orders,
    icon: OrdersIcon,
    isDisplay: true,
    roles: [roles.SUPER_MANAGER, roles.ADMINISTRATOR, roles.MANAGER, roles.DEALER]
  },
  {
    label: 'Заявки',
    path: '/orders/:id',
    component: EditOrder,
    isDisplay: false,
    roles: [roles.SUPER_MANAGER, roles.ADMINISTRATOR, roles.MANAGER, roles.DEALER]
  },
  {
    label: 'Новая заявка',
    path: '/orders/create',
    component: CreateOrder,
    isDisplay: false,
    roles: [roles.SUPER_MANAGER]
  },
  {
    label: 'Отчеты',
    path: '/reports',
    component: Reports,
    icon: ReportIcon,
    isDisplay: true,
    roles: [roles.SUPER_MANAGER, roles.ADMINISTRATOR]
  },
  {
    label: 'Отчет',
    path: '/reports/:id',
    component: Report,
    isDisplay: false,
    roles: [roles.SUPER_MANAGER, roles.ADMINISTRATOR]
  },
  {
    label: 'Поставщикам',
    path: '/supplier',
    component: Supplier,
    icon: CarIcon,
    isDisplay: true,
    roles: [roles.SUPER_MANAGER, roles.ADMINISTRATOR]
  },
  {
    label: 'Профиль',
    path: '/profile',
    component: Profile,
    icon: UserIcon,
    isDisplay: true,
    roles: Object.values(roles)
  },
  {
    label: 'Изменение пароля',
    path: '/profile/change_password',
    component: ChangePassword,
    isDisplay: false,
    roles: Object.values(roles)
  },
  {
    label: 'Ошибка',
    path: '/:status',
    component: Error,
    isDisplay: false,
    roles: Object.values(roles)
  },
  {
    label: 'Ошибка',
    path: '*',
    component: Error,
    isDisplay: false,
    roles: Object.values(roles)
  }
]

export const pathNames: { [key: string]: string } = routes.reduce((obj, { path, label }) => ({ ...obj, [path]: label }), {})

import * as Yup from 'yup'
import { roles } from '../../types/userTypes'

export const createUserSchema = Yup.object().shape({
  firstname: Yup.string().trim().required('Заполните поле имя'),
  lastname: Yup.string().trim().nullable(),
  email: Yup.string().email('Введите действительный email').required('Заполните поле почта'),
  phone: Yup.string().nullable(),
  password: Yup.string().required('Заполните поле пароль').min(6, 'Минимальная длина составляет 6 символов'),
  repeatPassword: Yup.string()
    .required('Повторите пароль')
    .oneOf([Yup.ref('password'), null], 'Пароли не совпадают'),
  role: Yup.string().required('Выберите роль').oneOf(Object.values(roles)),
  sections: Yup.array()
    .of(Yup.object({ id: Yup.string(), name: Yup.string() }))
    .notRequired(),
  warehouses: Yup.array()
    .of(Yup.object({ id: Yup.string(), name: Yup.string(), booking_warehouse: Yup.string().nullable() }))
    .notRequired()
})

export const editUserSchema = Yup.object().shape({
  firstname: Yup.string().trim().required('Заполните поле имя'),
  lastname: Yup.string().trim().nullable(),
  email: Yup.string().email('Введите действительный email').required('Заполните поле почта'),
  phone: Yup.string(),
  role: Yup.string().required('Выберите роль').oneOf(Object.values(roles)),
  sections: Yup.array()
    .of(Yup.object({ id: Yup.string(), name: Yup.string() }))
    .notRequired(),
  warehouses: Yup.array()
    .of(Yup.object({ id: Yup.string(), name: Yup.string(), booking_warehouse: Yup.string().nullable() }))
    .notRequired()
})

import React, { MouseEvent, useEffect } from 'react'
import { useActions } from '../../hooks/useActions'
import { useTypeSelector } from '../../hooks/useTypeSelector'
import { useBalance } from '../../hooks/useBalance'
import { Loader } from '../../components/Loader/Loader'
import { Breadcrumbs } from '../../components/Breadcrumbs/Breadcrumbs'
import { StyledHeader } from '../../infrasturcture/theme/styled'
import { Typography } from '@mui/material'
import { BalancesProduct } from '../../components/Balances/BalancesProduct'
import { DateInput } from '../../components/DateInput/DateInput'
import styles from '../../styles/Balances.module.css'
import clsx from 'clsx'

export const Balances: React.FC = () => {
  const { balances, isLoaded, filters } = useTypeSelector((state) => state.balances)
  const { warehouses } = useTypeSelector((state) => state.warehouses)
  const { setBalanceFilters } = useActions()
  const {
    sortWarehouses,
    addActiveClass,
    handleClick,
    dragStartHandler,
    dragLeaveHandler,
    dragEndHandler,
    dragOverHandler,
    dragDropHandler,
    CELL_WIDTH_STYLE,
    root,
    table,
    tableThead
  } = useBalance({
    isLoaded,
    warehouses,
    tableStyles: { vertical: styles.vertical, horizontal: styles.horizontal }
  })

  const { getBalancesThunkCreator, getWarehousesThunkCreator, setBalances } = useActions()

  const handleDateChange = (date: Date | null) => {
    date && setBalanceFilters({ date })
  }

  useEffect(() => {
    setBalances([])
    getWarehousesThunkCreator({})
  }, [])

  useEffect(() => {
    getBalancesThunkCreator(filters)
  }, [filters])

  return (
    <div className={styles.container}>
      <Breadcrumbs />
      <StyledHeader>
        <Typography variant="h1">Остатки</Typography>
        <DateInput onChange={handleDateChange} selected={filters.date} maxDate={new Date()} type="button" />
      </StyledHeader>
      <div ref={root} className={styles.tableContainer}>
        {!isLoaded && !!warehouses.length && !!balances.length ? (
          <table className={styles.table} ref={table}>
            <thead ref={tableThead} className={styles.tableThead}>
              <tr>
                <td className={styles.productCell} style={CELL_WIDTH_STYLE}>
                  Товар
                </td>
                <td className={styles.warehouseCell} style={{ ...CELL_WIDTH_STYLE, zIndex: 3, left: 211, color: '#0C4F3F' }}>
                  Остаток
                </td>
                {warehouses.sort(sortWarehouses).map((item, i) => (
                  <td
                    key={item.id}
                    className={clsx(styles.warehouseCell, addActiveClass(-1, i))}
                    style={{ ...CELL_WIDTH_STYLE, cursor: 'move' }}
                    draggable={true}
                    onDragStart={(e) => dragStartHandler(e, item)}
                    onDragLeave={(e) => dragLeaveHandler(e)}
                    onDragEnd={(e) => dragEndHandler(e, item)}
                    onDragOver={(e) => dragOverHandler(e)}
                    onDrop={(e) => dragDropHandler(e, item)}
                  >
                    {item.name}
                  </td>
                ))}
              </tr>
            </thead>
            <tbody className={styles.tableTbody}>
              {balances.map((item, row) => (
                <tr key={item.id}>
                  <td
                    className={clsx(styles.productCell, styles.tableWordBreak, addActiveClass(row, -1))}
                    style={CELL_WIDTH_STYLE}
                  >
                    <BalancesProduct photo={item.photo} name={item.name} code={item.sku} />
                  </td>
                  <td
                    className={clsx(styles.warehouseCell, addActiveClass(row, -1))}
                    style={{ ...CELL_WIDTH_STYLE, position: 'sticky', zIndex: 1, left: 211, color: '#0C4F3F' }}
                  >
                    {item.totalCount}
                  </td>
                  {warehouses.map(({ id }, col) => (
                    <td
                      key={id}
                      onClick={(e: MouseEvent<HTMLElement>) => handleClick(e, row, col)}
                      className={`${styles.warehouseCell} ${addActiveClass(row, col)}`}
                      style={CELL_WIDTH_STYLE}
                    >
                      {item.warehouses.find((warehouse) => warehouse.warehouse_id === id)?.count || 0}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <Loader className={styles.loader} />
        )}
      </div>
    </div>
  )
}

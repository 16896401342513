import { OrdersState, OrdersAction, OrdersActionTypes } from '../../types/ordersTypes'

const initialState: OrdersState = {
  orders: [],
  order: null,
  productsInOrder: [],
  originalProducts: [],
  ordersPosting: [],
  selectedOrdersPosting: [],
  currentProductId: null,
  limit: 50,
  count: 0,
  isLoaded: false,
  productsError: null,
  errors: {},
  filters: {
    status: null,
    managers: [],
    products: [],
    warehouses: [],
    startDate: null,
    endDate: null,
    sku: null
  }
}

export const ordersReducer = (state = initialState, action: OrdersAction): OrdersState => {
  switch (action.type) {
    case OrdersActionTypes.GET_ORDERS:
      return {
        ...state,
        isLoaded: false,
        orders: [...state.orders, ...action.payload]
      }
    case OrdersActionTypes.GET_ORDER:
      return {
        ...state,
        isLoaded: false,
        order: action.payload
      }
    case OrdersActionTypes.SET_ORDER:
      return {
        ...state,
        order: action.payload
      }
    case OrdersActionTypes.GET_ORDERS_POSTING:
      return {
        ...state,
        isLoaded: false,
        ordersPosting: action.payload
      }
    case OrdersActionTypes.SET_ORDERS:
      return {
        ...state,
        orders: action.payload
      }
    case OrdersActionTypes.SET_ORDER_COUNT:
      return {
        ...state,
        count: action.payload
      }
    case OrdersActionTypes.SET_ORDER_LOADED:
      return {
        ...state,
        isLoaded: action.payload
      }
    case OrdersActionTypes.ADD_ORDER_PRODUCT:
      return {
        ...state,
        productsInOrder: [...state.productsInOrder, action.payload]
      }
    case OrdersActionTypes.PLUS_ORDER_PRODUCT:
      return {
        ...state,
        productsInOrder: state.productsInOrder
          .map((item) =>
            item.id === action.payload
              ? {
                  ...item,
                  count: item.count + 1,
                  count_left: item.count + 1 - (item.prev_count || 0) + (item.prev_count_left || 0),
                  write_off_left: item.write_off_left + 1
                }
              : item
          )
          .filter((item) => item.count > 0)
      }
    case OrdersActionTypes.MINUS_ORDER_PRODUCT:
      return {
        ...state,
        productsInOrder: state.productsInOrder
          .map((item) =>
            item.id === action.payload
              ? {
                  ...item,
                  count: item.count - 1,
                  count_left: Math.max(item.count_left - 1, 0),
                  write_off_left: item.write_off_left - 1
                }
              : item
          )
          .filter((item) => item.count > 0)
      }
    case OrdersActionTypes.SET_ORDERS_POSTING:
      return {
        ...state,
        ordersPosting: action.payload
      }
    case OrdersActionTypes.SET_ORDERS_POSTING_PRODUCTS:
      return {
        ...state,
        selectedOrdersPosting: action.payload
      }
    case OrdersActionTypes.BACK_ORDER_POSTING:
      return {
        ...state,
        ordersPosting: [...state.ordersPosting, action.payload]
      }
    case OrdersActionTypes.DELETE_ORDERS_POSTING:
      return {
        ...state,
        ordersPosting: state.ordersPosting.filter((item) => item.id !== action.payload),
        selectedOrdersPosting: state.selectedOrdersPosting.filter((item) => item.id !== action.payload)
      }
    case OrdersActionTypes.PLUS_ORDERS_POSTING_PRODUCT:
      return {
        ...state,
        selectedOrdersPosting: state.selectedOrdersPosting
          .map((p) =>
            p.order_id === action.payload.orderId && p.id === action.payload.productId
              ? { ...p, count: p.count + 1, count_left: p.count_left - 1 }
              : p
          )
          .filter((p) => p.count > 0)
      }
    case OrdersActionTypes.MINUS_ORDERS_POSTING_PRODUCT:
      return {
        ...state,
        selectedOrdersPosting: state.selectedOrdersPosting
          .map((p) =>
            p.order_id === action.payload.orderId && p.id === action.payload.productId
              ? { ...p, count: p.count - 1, count_left: p.count_left + 1 }
              : p
          )
          .filter((p) => p.count > 0)
      }
    case OrdersActionTypes.UPDATE_ORDER_PRODUCT:
      return {
        ...state,
        productsInOrder: state.productsInOrder.map((item) =>
          item.id === action.payload.id ? { ...item, ...action.payload } : item
        )
      }
    case OrdersActionTypes.SET_ORDER_PRODUCTS:
      return {
        ...state,
        productsInOrder: action.payload,
        originalProducts: action.payload
      }
    case OrdersActionTypes.SET_ORDER_PRODUCT_ERROR:
      return {
        ...state,
        productsError: action.payload
      }
    case OrdersActionTypes.SET_ORDER_FILTERS:
      return {
        ...state,
        filters: { ...state.filters, ...action.payload }
      }
    case OrdersActionTypes.SET_ORDERS_ERRORS:
      return {
        ...state,
        errors: action.payload
      }
    default:
      return state
  }
}

import React, { ChangeEvent } from 'react'
import { SearchIcon } from '../../infrasturcture/icons'
import styles from './InputSearch.module.css'

interface InputSearchProps {
  value: string | number
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  onClick?: () => void
  onFocus?: () => void
  placeholder?: string
}

export const InputSearch: React.FC<InputSearchProps> = ({ value, onChange, onClick, onFocus, placeholder }) => {
  return (
    <div className={styles.search} onClick={onClick}>
      <SearchIcon />
      <input
        onChange={onChange}
        onFocus={onFocus}
        value={value}
        className={styles.searchInput}
        type="text"
        placeholder={placeholder}
      />
    </div>
  )
}

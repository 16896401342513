import React, { useState } from 'react'
import { Breadcrumbs } from '../../components/Breadcrumbs/Breadcrumbs'
import { StyledCell, StyledHeader } from '../../infrasturcture/theme/styled'
import { Box, Button, Typography } from '@mui/material'
import { OrderPosting, OrderPostingProduct } from '../../types/ordersTypes'
import { VgTable, VgTableColumnType } from '../../components/VgTable/VgTable'
import { Status } from '../../components/Status/Status'
import { formatDate } from '../../infrasturcture/utils/formatDate'
import { BitrixLink } from '../../components/BitrixLink/BitrixLink'
import { OperationComment } from '../../components/Operations/OperationComment'
import { useTypeSelector } from '../../hooks/useTypeSelector'
import { Checkbox } from '../../components/Checkbox/Checkbox'
import { useActions } from '../../hooks/useActions'
import { useDoubleClick } from '../../hooks/useDoubleClick'
import { OrderPostingRow } from './ui/OrderPostingRow'
import { useNavigate, useParams } from 'react-router-dom'
import groupBy from 'lodash.groupby'
import sumBy from 'lodash.sumby'
import { OperationProduct, operationTypes } from '../../types/operationsTypes'
import { SnacbarModal } from '../../components/MuiComponents/SnacbarModal/SnacbarModal'
import { Option } from '../../components/Options/Option'
import styles from '../../styles/Operations.module.css'
import { useOrdersPosting } from '../../hooks/useOrdersPosting'

const customPaths = {
  '/operations': 'Операции',
  '/operations/add/posting': 'Оприходование',
  '/operations/add/posting/orders_posting': 'Заявки для оприходования',
  '/operations/add/moving': 'Перемещение',
  '/operations/add/moving/orders_posting': 'Заявки для оприходования'
}

export const OrdersPosting: React.FC = () => {
  const navigate = useNavigate()
  const { type } = useParams()
  const TYPE = type?.toUpperCase()
  const { setOrdersPostingProducts, setOperationProducts, setOrdersErrors } = useActions()
  const { productsInOperation, products: stateProducts } = useTypeSelector((state) => state.operations)
  const { ordersPosting, selectedOrdersPosting, errors } = useTypeSelector((state) => state.orders)

  const [selectedId, setSelectedId] = useState<string | null>(null)
  const { onChange, isAvailableToSelect } = useOrdersPosting(TYPE)

  const handleAddSelected = () => {
    const availableProductsCount: Record<string, number> =
      TYPE === operationTypes.MOVING ? stateProducts.reduce((obj, item) => ({ ...obj, [item.id]: item.count }), {}) : {}

    const productsInOrdersPosting = ordersPosting
      .flatMap((o) => o.productsInOrder)
      .reduce<OrderPostingProduct[]>((arr, p) => {
        if (p.selected) {
          if (TYPE === operationTypes.MOVING) {
            const availableCount = availableProductsCount[p.id]
            const count = Math.min(p.count_left, availableCount)
            availableProductsCount[p.id] -= count
            return [...arr, { ...p, count, count_left: p.count_left - count }]
          }
          return [...arr, { ...p, count: p.count_left, count_left: 0 }]
        }
        return arr
      }, [])

    const groupedProducts = groupBy([...productsInOperation, ...productsInOrdersPosting], 'id')

    const unionProducts = Object.entries(groupedProducts).reduce<OperationProduct[]>((arr, [id, group]) => {
      const count = sumBy(group, 'count')
      const postingCount = productsInOrdersPosting.reduce((acc, item) => (item.id === id ? acc + item.count : acc), 0)
      const product = stateProducts.find((p) => p.id === id)

      if (TYPE === operationTypes.MOVING) {
        return product ? [...arr, { ...product, count: postingCount || count }] : arr
      }

      const prevPostingCount = selectedOrdersPosting.reduce((acc, item) => (item.id === id ? acc + item.count : acc), 0)

      return product ? [...arr, { ...product, count: count - prevPostingCount }] : arr
    }, [])

    setOperationProducts(unionProducts)
    setOrdersPostingProducts(productsInOrdersPosting)
    navigate(-1)
  }

  const handleClick = useDoubleClick(
    (id: string) => setSelectedId((prev) => (prev === id ? null : id)),
    () => undefined
  )

  const ordersColumns: VgTableColumnType<OrderPosting>[] = [
    {
      key: 'id',
      title: '',
      width: '5%',
      style: { paddingLeft: 22 },
      render: (_, { id, productsInOrder }) => {
        return (
          <Box sx={{ m: '-10px 0' }}>
            <Checkbox
              onChange={() => onChange(id, productsInOrder)}
              value={id}
              checked={productsInOrder.every((p) => p.selected)}
              shakeOnClick={!isAvailableToSelect(productsInOrder)}
              error={!isAvailableToSelect(productsInOrder)}
            />
          </Box>
        )
      }
    },
    {
      key: 'sku',
      title: 'Номер',
      width: '10%'
    },
    {
      key: 'status',
      title: 'Статус',
      render: (_, { status }) => <Status type={status} />,
      width: '15%'
    },
    {
      key: 'user_firstname',
      title: 'Пользователь',
      width: '15%',
      render: (_, item) => (
        <Option
          text={`${item.user_lastname || ''} ${item.user_firstname}`.trim()}
          subText={item.user_company}
          fontWeight={500}
          variant="table"
        />
      )
    },
    {
      key: 'created_at',
      title: 'Дата создания',
      render: (_, item) => <StyledCell>{formatDate(item.created_at)}</StyledCell>,
      width: '13%'
    },
    {
      key: 'warehouse_name',
      title: 'Склад',
      width: '15%'
    },
    {
      key: 'bitrix_id',
      title: 'Id B24',
      render: (_, item) => <BitrixLink bitrixId={item.bitrix_id} />,
      width: '10%'
    },
    {
      key: 'comment',
      title: 'Комментарий',
      render: (_, { comment }) => <OperationComment>{comment}</OperationComment>,
      width: '17%'
    }
  ]

  return (
    <div className={styles.container}>
      <Breadcrumbs customPaths={customPaths} />
      <StyledHeader sx={{ mb: '40px' }}>
        <Typography variant="h1">Заявки для оприходования</Typography>
        {!!ordersPosting.length && (
          <Button onClick={handleAddSelected} color="primary">
            Добавить
          </Button>
        )}
      </StyledHeader>
      {ordersPosting.length > 0 ? (
        <VgTable
          onRowClick={(order) => handleClick(order.id)}
          columns={ordersColumns}
          data={ordersPosting}
          renderCollapsibleRow={(order) =>
            order.id === selectedId ? <OrderPostingRow order={ordersPosting.find((o) => o.id === selectedId)!} /> : undefined
          }
        />
      ) : (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 'calc(100vh - 400px)' }}>
          <Typography variant="h2" color="text.primary" sx={{ fontSize: 18, fontWeight: 500, lineHeight: '24px' }}>
            Заявки для оприходования отсутствуют
          </Typography>
        </Box>
      )}
      <SnacbarModal
        isError
        isActions={false}
        onCancel={() => setOrdersErrors({})}
        open={!!errors?.ordersPosting}
        message={errors?.ordersPosting}
      />
    </div>
  )
}

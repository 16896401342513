import { ErrorMessages } from './errorTypes'

/* eslint-disable no-unused-vars */
export interface Section {
  id: string
  name: string
  subsections: string[]
  created_at?: Date
  updated_at?: Date
  users?: string[]
}

export interface SectionsState {
  sections: Section[]
  section: Section | null
  isLoaded: boolean
  errors: ErrorMessages
}

export enum SectionsActionTypes {
  GET_SECTIONS = 'GET_SECTIONS',
  GET_SECTION = 'GET_SECTION',
  SET_SECTION = 'SET_SECTION',
  SET_SECTIONS_LOADED = 'SET_SECTIONS_LOADED',
  UPDATE_SECTION = 'UPDATE_SECTION',
  SET_SECTIONS_ERRORS = 'SET_SECTIONS_ERRORS'
}

export interface GetSectionsThunkCreator {
  type: SectionsActionTypes.GET_SECTIONS
  payload: Section[]
}

export interface GetSectionThunkCreator {
  type: SectionsActionTypes.GET_SECTION
  payload: Section
}

export interface SetSectionAction {
  type: SectionsActionTypes.SET_SECTION
  payload: Section | null
}

export interface SetSectionsLoaded {
  type: SectionsActionTypes.SET_SECTIONS_LOADED
  payload: boolean
}

export interface UpdateSectionThunkCreator {
  type: SectionsActionTypes.UPDATE_SECTION
  payload: Section
}

export interface SetSectionsErrorsAction {
  type: SectionsActionTypes.SET_SECTIONS_ERRORS
  payload: ErrorMessages
}

export type SectionsAction =
  | GetSectionsThunkCreator
  | GetSectionThunkCreator
  | SetSectionAction
  | SetSectionsLoaded
  | SetSectionsErrorsAction
  | UpdateSectionThunkCreator

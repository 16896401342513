import React from 'react'
import { useParams } from 'react-router-dom'
import styles from './Error.module.css'
import { Breadcrumbs } from '../../components/Breadcrumbs/Breadcrumbs'
import { Typography } from '@mui/material'

const errros: { [key: number]: { title: string; text: string; code: string } } = {
  403: {
    code: '403',
    title: 'Ошибка 403 😪',
    text: `Кажется что-то пошло не так!
    Эта страница существует, но к сожалению у вас не достаточно доступов для ее просмотра.
    Свяжитесь с Администратором.`
  },
  404: {
    code: '404',
    title: 'Ошибка 404 😢',
    text: `Кажется что-то пошло не так!
    Страница, которую вы запрашиваете, не существует. 
    Возможно она устарела, была удалена, или был
    введен неверный адрес в адресной строке.`
  },
  503: {
    code: '503',
    title: 'Ошибка 503 😓',
    text: `Кажется что-то пошло не так!
    Произошла ошибка на стороне сервера. Свяжитесь с администратором.`
  }
}

export const Error: React.FC = () => {
  const { status } = useParams()
  const { title, text, code } = errros[Number(status)] || errros[404]

  return (
    <div className={styles.container}>
      <Breadcrumbs customText={code} />
      <div className={styles.wrapper}>
        <Typography variant="h1" color="text.primary">
          {title}
        </Typography>
        <div className={styles.text}>
          {text.split('\n').map((item, i) => (
            <p key={i}>{item.trim()}</p>
          ))}
        </div>
      </div>
    </div>
  )
}

import React, { useEffect, useState } from 'react'
import { ArrowRightIcon } from '../../infrasturcture/icons'
import styles from './Sidebar.module.css'
import { StyledTooltip } from '../../infrasturcture/theme/styled'

interface CollapseButtonProps {
  isOpen: boolean
  onClick: () => void
}

export const CollapseButton: React.FC<CollapseButtonProps> = ({ isOpen, onClick }) => {
  const [isMenuCollapsed, setIsMenuCollapsed] = useState(false)

  useEffect(() => {
    let timer: any
    !isOpen ? (timer = setTimeout(() => setIsMenuCollapsed(true), 300)) : setIsMenuCollapsed(false)
    return () => clearTimeout(timer)
  }, [isOpen])

  return (
    <StyledTooltip title={isMenuCollapsed ? 'Развернуть' : ''} placement="right" arrow>
      <li className={styles.item} onClick={onClick}>
        <div className={styles.link}>
          <ArrowRightIcon className={styles.collapseIcon} />
          <span className={styles.linkText}>Свернуть</span>
        </div>
      </li>
    </StyledTooltip>
  )
}

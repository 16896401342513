import { applyMiddleware, combineReducers, legacy_createStore as createStore } from 'redux'
import thunk from 'redux-thunk'
import { userReducer } from './reducers/userReducer'
import { composeWithDevTools } from 'redux-devtools-extension'
import { usersReducer } from './reducers/usersReducer'
import { warehousesReducer } from './reducers/warehousesReducer'
import { productsReducer } from './reducers/productsReducer'
import { operationsReducer } from './reducers/operationsReducer'
import { balancesReducer } from './reducers/balancesReducer'
import { ordersReducer } from './reducers/ordersReducer'
import { sectionsReducer } from './reducers/sectionsReducer'
import { bookingsReducer } from './reducers/bookingsReducer'
import { manufacturersReducer } from './reducers/manufacturersReducer'

const rootReducer = combineReducers({
  user: userReducer,
  users: usersReducer,
  warehouses: warehousesReducer,
  products: productsReducer,
  operations: operationsReducer,
  balances: balancesReducer,
  orders: ordersReducer,
  sections: sectionsReducer,
  bookings: bookingsReducer,
  manufacturers: manufacturersReducer
})

export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)))

export type RootState = ReturnType<typeof rootReducer>

import { AxiosResponse } from 'axios'
import { ErrorMessages, ErrorResponse, ErrorsTranslations } from '../../types/errorTypes'

const errorsTranslations: ErrorsTranslations = {
  'Email does not exist': { value: 'Такой почты не существует', name: 'email' },
  'Incorrect password': { value: 'Неверный пароль', name: 'password' },
  'Email is not unique': { value: 'Такая почта уже существует' },
  'Invalid phone for BY-RU region': { value: 'Неверный формат телефона для региона' },
  'Incorrect old password': { value: 'Неверный пароль' },
  'Section name is not unique': { value: 'Такой раздел уже существует' },
  'Products already exist for the selected section': { value: 'Для выбранного раздела уже существуют товары' },
  'Manufacturer name is not unique': { value: 'Такой производитель уже существует' },
  'Manufacturer cannot be removed which is indicated in the goods': {
    value: 'Невозможно удалить производителя, который указан в товарах.'
  },
  'Name is not unique': { value: 'Такой склад уже существует' },
  'Region is not unique': { value: 'Такой регион уже существует' },
  'There is a reservation in this warehouse': { value: 'Нельзя удалить склад, на котором есть забронированный товар' },
  'You cannot remove a region from a warehouse if it contains reservation': {
    value: 'На складе есть забронированный товар'
  },
  'Product name is not unique': { value: 'Товар с таким именем уже существует' },
  'Product code is not unique': { value: 'Код уже существует' },
  'Unable to delete an item that is in warehouse': { value: 'Выбранный товар, есть на складах' },
  'The minimum number of products must be one': { value: 'Добавьте хотя бы один товар' }
}

export const errorsHandling = (response: AxiosResponse<ErrorResponse>): ErrorMessages => {
  const { messages, message } = response.data

  const errorObject: ErrorMessages =
    messages?.reduce((obj, item) => ({ ...obj, [item.param]: errorsTranslations[item.msg].value || item.msg }), {}) || {}

  if (message) {
    const { value, name } = errorsTranslations[message]
    errorObject[name || 'internal'] = value || message
  }

  return errorObject
}

import { ThunkAction } from 'redux-thunk'
import { RootState } from '..'
import BalancesApi from '../../api/BalancesApi'
import {
  Balance,
  BalanceFilters,
  BalancesAction,
  BalancesActionsTypes,
  SetBalanceFiltersAction,
  SetBalancesAction,
  SetBalancesLoaded,
  SetSupplierBalancesAction
} from '../../types/balancesTypes'
import { SetAuthErrorAction } from '../../types/userTypes'
import { setAuthError } from './userActions'

type ThunkType = ThunkAction<void, RootState, unknown, BalancesAction | SetAuthErrorAction>

const setBalancesLoaded = (payload: boolean): SetBalancesLoaded => ({
  type: BalancesActionsTypes.SET_BALANCES_LOADED,
  payload
})

export const setBalances = (payload: Balance[]): SetBalancesAction => ({
  type: BalancesActionsTypes.SET_BALANCES,
  payload
})

export const setSupplierBalances = (payload: Balance[]): SetSupplierBalancesAction => ({
  type: BalancesActionsTypes.SET_SUPPLIER_BALANCES,
  payload
})

export const setBalanceFilters = (payload: Partial<BalanceFilters>): SetBalanceFiltersAction => ({
  type: BalancesActionsTypes.SET_BALANCE_FILTERS,
  payload
})

export const getBalancesThunkCreator = (filters: BalanceFilters): ThunkType => {
  return async (dispatch) => {
    try {
      dispatch(setBalancesLoaded(true))
      const data = await BalancesApi.getBalances(filters)
      dispatch({ type: BalancesActionsTypes.GET_BALANCES, payload: data })
    } catch (e: any) {
      dispatch(setAuthError(e.response.data.messages))
    }
  }
}

export const getSupplierBalancesThunkCreator = (): ThunkType => {
  return async (dispatch) => {
    try {
      dispatch(setBalancesLoaded(true))
      const data = await BalancesApi.getSupplierBalances()
      dispatch({
        type: BalancesActionsTypes.GET_SUPPLIER_BALANCES,
        payload: data
      })
    } catch (e: any) {
      dispatch(setAuthError(e.response.data.messages))
    }
  }
}

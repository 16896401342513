import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTypeSelector } from '../../hooks/useTypeSelector'
import { useActions } from '../../hooks/useActions'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Breadcrumbs } from '../../components/Breadcrumbs/Breadcrumbs'
import { StyledHeader } from '../../infrasturcture/theme/styled'
import { Button, IconButton, Typography } from '@mui/material'
import { PenIcon, SaveIcon } from '../../infrasturcture/icons'
import { Status } from '../../components/Status/Status'
import { format } from 'date-fns'
import { statusTypes } from '../../types/bookingsTypes'
import { SnacbarModal } from '../../components/MuiComponents/SnacbarModal/SnacbarModal'
import { OrderForm } from './ui/OrderForm'
import { OrderProducts } from './ui/OrderProducts'
import styles from '../../styles/Operations.module.css'
import { createOrderSchema } from '../../infrasturcture/yupSchemes/createOrderSchema'
import { Order } from '../../types/ordersTypes'

export const EditOrder: React.FC = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { order, isLoaded, productsInOrder, errors } = useTypeSelector((state) => state.orders)
  const { filterWarehouses } = useTypeSelector((state) => state.warehouses)
  const {
    getOrderThunkCreator,
    getFilterWarehousesThunkCreator,
    editOrderThunkCreator,
    setOrderProducts,
    setOrdersErrors,
    setOrder,
    getProductsInWarehouseThunkCreator
  } = useActions()

  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [isWarning, setIsWarning] = useState<boolean>(false)
  const [warningMessage, setWarningMessage] = useState<string>('')

  const isDisabledProducts = !isEdit || order?.status !== statusTypes.CONFIRMATION

  const methods = useForm({ resolver: yupResolver(createOrderSchema), mode: 'onChange' })

  const onSubmit = (data: any) => {
    const stateOrder: Order = { ...data, productsInOrder: [], created_at: order?.created_at }

    const editOrder = {
      ...data,
      productsInOrder: productsInOrder.map((p) => {
        stateOrder.productsInOrder?.push({
          ...p,
          count: p.count,
          checkbox: p.checkbox,
          count_left: p.count_left,
          prev_count_left: p.count_left,
          write_off_left: p.write_off_left,
          prev_count: p.count
        })
        return {
          product_id: p.id,
          products_audit_id: p.products_audit_id,
          count: p.count,
          checkbox: p.checkbox,
          count_left: p.count_left,
          write_off_left: p.write_off_left
        }
      })
    }

    return editOrderThunkCreator(
      editOrder,
      () => {
        setOrder(stateOrder)
        setIsWarning(false)
        setIsEdit(false)
      },
      navigate
    )
  }

  const handleSave = () => {
    if (methods.getValues('status') === statusTypes.CANCELED) {
      setWarningMessage('Вы действительно хотите отменить заявку? Вместе с ним отменится и соответствующее бронирование.')
      return setIsWarning(true)
    }

    if (methods.getValues('status') === statusTypes.SHIPPED_CLOSED) {
      setWarningMessage('Вы действительно хотите закрыть заявку? Закрытую заявку уже нельзя будет изменить.')
      return setIsWarning(true)
    }
    methods.handleSubmit(onSubmit)()
  }

  useEffect(() => {
    getOrderThunkCreator(id!, navigate)
    !filterWarehouses.length && getFilterWarehousesThunkCreator()
  }, [])

  useEffect(() => {
    if (order) {
      methods.reset({
        ...order,
        full_name: `${order.user_lastname || ''} ${order.user_firstname}`.trim(),
        created_at: format(new Date(order.created_at), 'dd.MM.yyyy  HH:mm')
      })

      setOrderProducts(order.productsInOrder || [])
      getProductsInWarehouseThunkCreator(null)
    }
  }, [order])

  return (
    <div className={styles.container}>
      {!isLoaded && order && (
        <>
          <Breadcrumbs customText={`Заявка №${order.sku}`} />
          <StyledHeader>
            <Typography variant="h1">Заявка №{order.sku}</Typography>
            {![statusTypes.CANCELED, statusTypes.SHIPPED_CLOSED].includes(order.status as statusTypes) && (
              <>
                {isEdit ? (
                  <Button onClick={handleSave} color="primary" startIcon={<SaveIcon />}>
                    Сохранить
                  </Button>
                ) : (
                  <>
                    <Status type={order.status} />
                    <IconButton color="inherit" onClick={() => setIsEdit(true)}>
                      <PenIcon />
                    </IconButton>
                  </>
                )}
              </>
            )}
          </StyledHeader>
          <FormProvider {...methods}>
            <OrderForm type="edit" disabled={!isEdit} onSubmit={onSubmit} />
          </FormProvider>
          <OrderProducts type="edit" disabled={isDisabledProducts} />
        </>
      )}
      <SnacbarModal
        onAccept={methods.handleSubmit(onSubmit)}
        onCancel={() => setIsWarning(false)}
        open={isWarning}
        message={warningMessage}
      />
      <SnacbarModal
        isError
        isActions={false}
        onCancel={() => setOrdersErrors({})}
        open={!!errors?.productsInBooking}
        message={errors?.productsInBooking}
      />
    </div>
  )
}

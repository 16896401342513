import { Box, SxProps, Typography } from '@mui/material'
import React, { PropsWithChildren } from 'react'
import { InfoIcon } from '../../infrasturcture/icons'
import { MuiTooltip } from '../MuiComponents/CustomTooltip/MuiTooltip'

const defaultText = `Количество товаров, указанное в товарах в операции, указано с учетом товаров под заявки/бронирования 
для данной операции.`

interface OperationTitleProps extends PropsWithChildren<unknown> {
  isShowTooltip?: boolean
  variant?: 'small' | 'large'
  tooltipText?: string
}

export const OperationTitle: React.FC<OperationTitleProps> = ({
  children,
  isShowTooltip = false,
  variant = 'small',
  tooltipText = defaultText
}) => {
  const sx: SxProps = {
    fontSize: variant === 'small' ? 15 : 18,
    fontWeight: variant === 'small' ? 700 : 500,
    lineHeight: variant === 'small' ? '21px' : '24px'
  }

  return (
    <Box sx={{ position: 'relative', display: 'inline-flex', mb: '20px' }}>
      <Typography variant="h3" color="text.primary" sx={sx}>
        {children}
      </Typography>
      {isShowTooltip && (
        <MuiTooltip
          title={tooltipText}
          leaveDelay={500}
          placement="right-start"
          PopperProps={{
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [14, -15]
                }
              }
            ]
          }}
        >
          <InfoIcon
            style={{
              position: 'absolute',
              right: -18,
              top: variant === 'small' ? -10 : -8,
              width: 16,
              height: 16,
              fill: '#0C4F3F'
            }}
          />
        </MuiTooltip>
      )}
    </Box>
  )
}

import React from 'react'
import styles from './Filter.module.css'
import { IconButton, Typography } from '@mui/material'
import { CloseMediumIcon } from '../../infrasturcture/icons'

interface FilterHeaderProps {
  onClose: () => void
}

export const FilterHeader: React.FC<FilterHeaderProps> = ({ onClose }) => {
  return (
    <div className={styles.header}>
      <Typography variant="h1">Фильтры</Typography>
      <IconButton onClick={onClose} sx={{ mr: '-8px' }}>
        <CloseMediumIcon width={24} height={24} />
      </IconButton>
    </div>
  )
}

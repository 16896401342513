import React from 'react'
import { StyledLink } from '../../infrasturcture/theme/styled'

interface BitrixLinkProps {
  bitrixId: string | null
}

export const BitrixLink: React.FC<BitrixLinkProps> = ({ bitrixId }) => {
  return bitrixId ? (
    <StyledLink
      target="_blank"
      rel="noreferrer"
      href={`https://vithouse2.bitrix24.ru/crm/deal/details/${bitrixId}/`.replace(/\s/g, '')}
      onClick={(e) => e.stopPropagation()}
    >
      {bitrixId}
    </StyledLink>
  ) : null
}

import React, { MouseEvent, MutableRefObject, useEffect, useMemo, useRef, useState } from 'react'
import { Warehouse } from '../types/warehousesTypes'
import { useActions } from './useActions'
import { useOnClickOutside } from './useOnClickOutside'
import { useInView } from './useInView'
import { useMediaQuery } from '@mui/material'
import { useForm } from 'react-hook-form'

interface IUseBalance {
  isLoaded: boolean
  warehouses: Warehouse[]
  tableStyles: {
    vertical: string
    horizontal: string
  }
  rootMarginLeft?: [moblie: number, desktop: number]
}

export const useBalance = ({ isLoaded, warehouses, tableStyles, rootMarginLeft = [134, 211] }: IUseBalance) => {
  const { setWarehouses, updateWarehouseOrderThunkCreator } = useActions()

  const [page, setPage] = useState<number>(1)
  const [currentWarehouse, setCurrentWarehouse] = useState<Warehouse | null>(null)
  const [selectedCell, setSelectedCell] = useState<{
    row: number | null
    col: number | null
  }>({ row: null, col: null })

  const matches = useMediaQuery('(max-width: 900px)')

  const lastElement = useRef<HTMLDivElement | null>(null)
  const root = useRef<HTMLDivElement | null>(null)
  const table = useRef() as MutableRefObject<HTMLTableElement>
  const tableThead = useRef<HTMLTableSectionElement | null>(null)
  const lastCell = useRef<HTMLElement | null>(null)
  const CELL_WIDTH_STYLE: React.CSSProperties = warehouses.length <= 3 ? { maxWidth: 107, minWidth: 107 } : {}
  const isFiltered = useRef<boolean>(false)

  const methods = useForm({ defaultValues: { date: new Date() } })

  const addActiveClass = (row: number, col: number) => {
    const vertical = row <= selectedCell.row! && col === selectedCell.col
    const horizontal = row === selectedCell.row && col <= selectedCell.col!
    const target = row === selectedCell.row && col === selectedCell.col
    if (target) return `${tableStyles.vertical} ${tableStyles.horizontal}`
    if (vertical) return tableStyles.vertical
    if (horizontal) return tableStyles.horizontal
    return ''
  }

  const handleClick = (e: MouseEvent<HTMLElement>, row: number, col: number) => {
    if (row === selectedCell.row && col === selectedCell.col) {
      setSelectedCell({ row: null, col: null })
      lastCell.current = null
    } else {
      setSelectedCell({ row, col })
      lastCell.current = e.target as HTMLElement
    }
  }

  const dragStartHandler = (e: any, th: Warehouse) => {
    setCurrentWarehouse(th)
  }

  const dragLeaveHandler = (e: any) => {
    e.target.style.boxShadow = 'none'
  }

  const dragEndHandler = (e: any, th: Warehouse) => {
    e.target.style.boxShadow = 'none'
    updateWarehouseOrderThunkCreator({
      id: th.id,
      old: currentWarehouse!.warehouse_order,
      new: th.warehouse_order
    })
  }

  const dragOverHandler = (e: any) => {
    e.preventDefault()
    e.target.style.boxShadow = '3px 0px 3px rgba(0, 0, 0, 0.5)'
  }

  const dragDropHandler = (e: any, th: Warehouse) => {
    e.preventDefault()

    const newWarehouses =
      currentWarehouse!.warehouse_order > th.warehouse_order
        ? warehouses.map((item) => {
            if (item.id === currentWarehouse?.id) {
              return { ...item, warehouse_order: th.warehouse_order }
            }
            if (
              item.id !== currentWarehouse?.id &&
              item.warehouse_order < currentWarehouse!.warehouse_order &&
              item.warehouse_order >= th.warehouse_order
            ) {
              return { ...item, warehouse_order: item.warehouse_order + 1 }
            }
            return item
          })
        : warehouses.map((item) => {
            if (item.id === currentWarehouse?.id) {
              return { ...item, warehouse_order: th.warehouse_order }
            }
            if (
              item.id !== currentWarehouse?.id &&
              item.warehouse_order > currentWarehouse!.warehouse_order &&
              item.warehouse_order <= th.warehouse_order
            ) {
              return { ...item, warehouse_order: item.warehouse_order - 1 }
            }
            return item
          })

    setWarehouses(newWarehouses)

    e.target.style.boxShadow = 'none'
  }

  const sortWarehouses = (a: Warehouse, b: Warehouse) => (a.warehouse_order > b.warehouse_order ? 1 : -1)

  useOnClickOutside(table, () => {
    setSelectedCell({ row: null, col: null })
    lastCell.current = null
  })

  const entry = useInView(lastCell, {
    root: root.current,
    rootMargin: `-${tableThead.current?.offsetHeight}px -${matches ? 86 : 107}px -${lastCell.current?.offsetHeight}px -${
      matches ? rootMarginLeft[0] : rootMarginLeft[1]
    }px`
  })

  useEffect(() => {
    if (!entry?.isIntersecting) {
      setSelectedCell({ row: null, col: null })
      lastCell.current = null
    }
  }, [entry])

  const value = useMemo(
    () => ({
      matches,
      sortWarehouses,
      addActiveClass,
      handleClick,
      dragStartHandler,
      dragLeaveHandler,
      dragEndHandler,
      dragOverHandler,
      dragDropHandler,
      CELL_WIDTH_STYLE,
      page,
      setPage,
      lastElement,
      root,
      table,
      tableThead,
      methods,
      isFiltered
    }),
    [matches, CELL_WIDTH_STYLE, page, lastElement, root, table, tableThead, methods, isFiltered]
  )

  return value
}

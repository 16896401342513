/* eslint-disable no-unused-vars */

import { ErrorMessages } from './errorTypes'

export interface Warehouse {
  id: string
  sku: bigint
  name: string
  region: string | null
  address: string | null
  warehouse_order: number
  created_at?: Date
  updated_at?: Date
  booking_warehouse_id?: string | null
  isBooking: boolean
  users?: string[]
}

export interface WarehousesState {
  warehouses: Warehouse[]
  warehouse: Warehouse | null
  filterWarehouses: Warehouse[]
  isLoaded: boolean
  errors: ErrorMessages
}

export enum WarehousesActionTypes {
  GET_WAREHOUSES = 'GET_WAREHOUSES',
  GET_WAREHOUSE = 'GET_WAREHOUSE',
  SET_WAREHOUSES_LOADED = 'SET_WAREHOUSES_LOADED',
  SET_WAREHOUSE = 'SET_WAREHOUSE',
  GET_FILTER_WAREHOUSES = 'GET_FILTER_WAREHOUSES',
  UPDATE_WAREHOUSE = 'UPDATE_WAREHOUSE',
  SET_WAREHOUSES = 'SET_WAREHOUSES',
  SET_FILTER_WAREHOUSES = 'SET_FILTER_WAREHOUSES',
  UPDATE_WAREHOUSE_ORDER = 'UPDATE_WAREHOUSE_ORDER',
  SET_WAREHOUSES_ERRORS = 'SET_WAREHOUSES_ERRORS'
}

export interface GetWarehousesThunkCreator {
  type: WarehousesActionTypes.GET_WAREHOUSES
  payload: Warehouse[]
}

export interface GetWarehouseThunkCreator {
  type: WarehousesActionTypes.GET_WAREHOUSE
  payload: Warehouse | null
}

export interface GetFilterWarehousesThunkCreator {
  type: WarehousesActionTypes.GET_FILTER_WAREHOUSES
  payload: Warehouse[]
}

export interface SetWarehousesLoaded {
  type: WarehousesActionTypes.SET_WAREHOUSES_LOADED
  payload: boolean
}

export interface SetWarehouseAction {
  type: WarehousesActionTypes.SET_WAREHOUSE
  payload: Warehouse | null
}

export interface UpdateWarehouseThunkCreator {
  type: WarehousesActionTypes.UPDATE_WAREHOUSE
  payload: Warehouse
}

export interface SetWarehousesAction {
  type: WarehousesActionTypes.SET_WAREHOUSES
  payload: Warehouse[]
}

export interface SetFilterWarehousesAction {
  type: WarehousesActionTypes.SET_FILTER_WAREHOUSES
  payload: Warehouse[]
}

export interface UpdateWarehouseOrderThunkCreator {
  type: WarehousesActionTypes.UPDATE_WAREHOUSE_ORDER
  payload: Warehouse
}

export interface SetWarehousesErrorsAction {
  type: WarehousesActionTypes.SET_WAREHOUSES_ERRORS
  payload: ErrorMessages
}

export type WarehousesAction =
  | GetWarehousesThunkCreator
  | GetWarehouseThunkCreator
  | SetWarehouseAction
  | UpdateWarehouseThunkCreator
  | SetWarehousesAction
  | SetFilterWarehousesAction
  | UpdateWarehouseOrderThunkCreator
  | GetFilterWarehousesThunkCreator
  | SetWarehousesLoaded
  | SetWarehousesErrorsAction

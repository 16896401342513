import { ThunkAction } from 'redux-thunk'
import { RootState } from '..'
import OperationsApi from '../../api/OperationsApi'
import ProductsApi from '../../api/ProductsApi'
import {
  AddProduct,
  AddProductOperation,
  CleanOperationSearchProducts,
  MinusProduct,
  Operation,
  OperationFilters,
  OperationFormValues,
  OperationProduct,
  OperationsAction,
  OperationsActionTypes,
  PlusProduct,
  SetOperation,
  SetOperationFilters,
  SetOperationFormValues,
  SetOperationLoaded,
  SetOperationProductsAction,
  SetOperationsAction,
  SetOperationsErrorsAction
} from '../../types/operationsTypes'
import { SetAuthErrorAction } from '../../types/userTypes'
import { setAuthError } from './userActions'
import { NavigateFunction } from 'react-router-dom'
import { ErrorMessages } from '../../types/errorTypes'
import { errorsHandling } from '../../infrasturcture/utils/errorsHandling'

type ThunkType = ThunkAction<void, RootState, unknown, OperationsAction | SetAuthErrorAction>

export const addProduct = (payload: OperationProduct): AddProduct => ({
  type: OperationsActionTypes.ADD_PRODUCT,
  payload
})

export const addProductOperation = (payload: OperationProduct[]): AddProductOperation => ({
  type: OperationsActionTypes.ADD_PRODUCTS_OPERATION,
  payload
})

export const plusProduct = (id: string, count = 1): PlusProduct => ({
  type: OperationsActionTypes.PLUS_PRODUCT,
  payload: { id, count }
})

export const minusProduct = (payload: string): MinusProduct => ({
  type: OperationsActionTypes.MINUS_PRODUCT,
  payload
})

export const setOperations = (payload: Operation[]): SetOperationsAction => ({
  type: OperationsActionTypes.SET_OPERATIONS,
  payload
})

export const cleanOperationSearchProducts = (payload: OperationProduct[]): CleanOperationSearchProducts => ({
  type: OperationsActionTypes.CLEAN_OPERATION_SEARCH_PRODUCTS,
  payload
})

const setOperationLoaded = (payload: boolean): SetOperationLoaded => ({
  type: OperationsActionTypes.SET_OPERATION_LOADED,
  payload
})

export const setOperation = (payload: Operation | null): SetOperation => ({
  type: OperationsActionTypes.SET_OPERATION,
  payload
})

export const setOperationProducts = (payload: OperationProduct[]): SetOperationProductsAction => ({
  type: OperationsActionTypes.SET_OPERATION_PRODUCTS,
  payload
})

export const setOperationFilters = (payload: Partial<OperationFilters>): SetOperationFilters => ({
  type: OperationsActionTypes.SET_OPERATION_FILTERS,
  payload
})

export const setOperationFormValues = (payload: Partial<OperationFormValues>): SetOperationFormValues => ({
  type: OperationsActionTypes.SET_OPERATION_FORM_VALUES,
  payload
})

export const setOperationsErrors = (payload: ErrorMessages): SetOperationsErrorsAction => ({
  type: OperationsActionTypes.SET_OPERATIONS_ERRORS,
  payload
})

export const getOperationsThunkCreator = (limit = 50, page = 1, filters: OperationFilters): ThunkType => {
  return async (dispatch) => {
    try {
      dispatch(setOperationLoaded(true))
      const data = await OperationsApi.getOperations(limit, page, filters)
      dispatch({ type: OperationsActionTypes.GET_OPERATIONS, payload: data })
    } catch (e: any) {
      dispatch(setAuthError(e.response.data.messages))
    }
  }
}

export const getOperationThunkCreator = (id: string, navigate: NavigateFunction): ThunkType => {
  return async (dispatch) => {
    try {
      dispatch(setOperationLoaded(true))
      const data = await OperationsApi.getOperation(id)
      dispatch({ type: OperationsActionTypes.GET_OPERATION, payload: data })
    } catch (e: any) {
      if ([403, 404, 503].includes(e.response.status)) {
        return navigate(`/${e.response.status}`)
      }
      dispatch(setAuthError(e.response.data.messages))
    }
  }
}

export const getManagersThunkCreator = (): ThunkType => {
  return async (dispatch) => {
    try {
      const data = await OperationsApi.getManagers()
      dispatch({ type: OperationsActionTypes.GET_MANAGERS, payload: data })
    } catch (e: any) {
      dispatch(setAuthError(e.response.data.messages))
    }
  }
}

export const getProductsInWarehouseThunkCreator = (id: string | null): ThunkType => {
  return async (dispatch) => {
    try {
      const data = id ? await OperationsApi.getProducts(id) : await ProductsApi.getProducts(null, null)
      const products = id ? data : data.rows
      dispatch({ type: OperationsActionTypes.GET_OPERATION_PRODUCTS, payload: products })
    } catch (e: any) {
      dispatch(setAuthError(e.response.data.messages))
    }
  }
}

export const createOperationThunkCreator = (operation: any, cb: () => void, navigate: NavigateFunction): ThunkType => {
  return async (dispatch) => {
    try {
      await OperationsApi.createOperation(operation)
      cb()
    } catch (e: any) {
      if (e.response.status === 422) return dispatch(setOperationsErrors(errorsHandling(e.response)))
      if ([403, 404, 503].includes(e.response.status)) return navigate(`/${e.response.status}`)
      dispatch(setAuthError(e.response.data.messages))
    }
  }
}

export const editOperationThunkCreator = (operation: Operation, cb: () => void, navigate: NavigateFunction): ThunkType => {
  return async (dispatch) => {
    try {
      await OperationsApi.editOperation(operation)
      cb()
    } catch (e: any) {
      if (e.response.status === 422) return dispatch(setOperationsErrors(errorsHandling(e.response)))
      if ([403, 404, 503].includes(e.response.status)) return navigate(`/${e.response.status}`)
      dispatch(setAuthError(e.response.data.messages))
    }
  }
}

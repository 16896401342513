/* eslint-disable no-unused-vars */
export interface Balance {
  id: string
  name: string
  sku: string
  photo: string | null
  totalCount: number
  warehouses: Array<{ warehouse_id: string; count: number }>
}

export interface BalanceFilters {
  date: Date
}

export interface BalancesState {
  balances: Balance[]
  supplierBalances: Balance[]
  isLoaded: boolean
  filters: BalanceFilters
}

export enum BalancesActionsTypes {
  GET_BALANCES = 'GET_BALANCES',
  GET_SUPPLIER_BALANCES = 'GET_SUPPLIER_BALANCES',
  SET_BALANCES_LOADED = 'SET_BALANCES_LOADED',
  SET_BALANCES = 'SET_BALANCES',
  SET_SUPPLIER_BALANCES = 'SET_SUPPLIER_BALANCES',
  SET_BALANCE_FILTERS = 'SET_BALANCE_FILTERS'
}

export interface GetBalancesThunkCreator {
  type: BalancesActionsTypes.GET_BALANCES
  payload: Balance[]
}

export interface GetSupplierBalancesThunkCreator {
  type: BalancesActionsTypes.GET_SUPPLIER_BALANCES
  payload: Balance[]
}

export interface SetBalancesLoaded {
  type: BalancesActionsTypes.SET_BALANCES_LOADED
  payload: boolean
}

export interface SetBalancesAction {
  type: BalancesActionsTypes.SET_BALANCES
  payload: Balance[]
}

export interface SetSupplierBalancesAction {
  type: BalancesActionsTypes.SET_SUPPLIER_BALANCES
  payload: Balance[]
}

export interface SetBalanceFiltersAction {
  type: BalancesActionsTypes.SET_BALANCE_FILTERS
  payload: Partial<BalanceFilters>
}

export type BalancesAction =
  | GetBalancesThunkCreator
  | GetSupplierBalancesThunkCreator
  | SetBalancesLoaded
  | SetBalancesAction
  | SetSupplierBalancesAction
  | SetBalanceFiltersAction

import { Box, keyframes, styled } from '@mui/material'
import React from 'react'
import { MinusIcon, PlusIcon } from '../../infrasturcture/icons'

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 4
}))

const StyledSpan = styled('span')(({ theme }) => ({
  display: 'inline-block',
  padding: '2px 8px',
  borderRadius: 8,
  fontWeight: 500,
  fontFamily: 'Roboto, sans-serif',
  fontSize: 15,
  lineHeight: '21px',
  margin: '-2px 0',
  color: theme.palette.primary.main,
  backgroundColor: '#D3DFDC'
}))

const shakeAnimation = keyframes`
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-12px);
  }
  100% {
    transform: translateY(0px);
  }
`

const StyledButton = styled('button', { shouldForwardProp: (prop) => prop !== 'shake' })<{ shake: boolean }>(
  ({ theme, shake }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 15,
    height: 15,
    background: 'transparent',
    border: 'none',
    appearance: 'none',
    color: shake ? theme.palette.secondary.main : theme.palette.text.primary,
    fill: 'currentcolor',
    cursor: 'pointer',
    animation: shake ? `${shakeAnimation} 0.2s linear` : 'none'
  })
)

interface CountProps {
  children: React.ReactNode
  onPlus?: () => void
  onMinus?: () => void
  disabledPlus?: boolean
  disabledMinus?: boolean
}

export const Count: React.FC<CountProps> = ({ children, onPlus, onMinus, disabledMinus = false, disabledPlus = false }) => {
  return onPlus || onMinus ? (
    <StyledBox>
      <StyledButton onClick={onMinus} disabled={disabledMinus} shake={disabledMinus}>
        <MinusIcon width={10} />
      </StyledButton>
      <StyledSpan>{children}</StyledSpan>
      <StyledButton onClick={onPlus} disabled={disabledPlus} shake={disabledPlus}>
        <PlusIcon width={10} />
      </StyledButton>
    </StyledBox>
  ) : (
    <StyledSpan>{children}</StyledSpan>
  )
}

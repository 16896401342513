import { Box, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper, TextField, Typography } from '@mui/material'
import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react'

export interface Option {
  label: string
  value: string
}

interface GroupedOptions {
  [key: string]: Option[]
}

interface SearchInputProps {
  options: Option[]
  value: string | null
  onChange: (value: string | null) => void
  renderOption?: (value: Option) => React.ReactNode
  onFocus?: () => void
  label?: React.ReactNode
  placeholder?: string
  disabled?: boolean
  error?: boolean
}

export const SearchInput: React.FC<SearchInputProps> = ({
  options,
  value,
  onChange,
  renderOption,
  onFocus,
  disabled = false,
  label,
  placeholder,
  error = false
}) => {
  const [open, setOpen] = useState(false)
  const [inputValue, setInputValue] = useState<string>('')
  const anchorRef = useRef<HTMLButtonElement>(null)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }

  const handleSelect = (selected: Option) => () => {
    if (selected.value !== value) {
      onChange(selected.value)
      setInputValue(selected.label)
    }
    setOpen(false)
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length === 0) onChange(null)
    setInputValue(e.target.value)
  }

  const prevOpen = React.useRef(open)

  const searchOptions = useMemo(() => {
    return options
      ?.filter((item) => item.label?.toLowerCase().includes(inputValue.toLowerCase()))
      ?.sort((a, b) => a.label.localeCompare(b.label))
      .reduce<GroupedOptions>((grouped, option) => {
        const key = option.label.charAt(0).toUpperCase()
        return { ...grouped, [key]: [...(grouped[key] || []), option] }
      }, {})
  }, [inputValue, options])

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus()
    }

    prevOpen.current = open
  }, [open])

  useEffect(() => {
    if (value !== undefined) {
      const option = options.find((o) => o.value === value)
      setInputValue(option?.label || '')
    }
  }, [value, options])

  return (
    <Box
      ref={anchorRef}
      id="composition-button"
      aria-controls={open ? 'composition-menu' : undefined}
      aria-expanded={open ? 'true' : undefined}
      aria-haspopup="true"
      sx={{ position: 'relative', width: '100%' }}
    >
      <TextField
        onClick={handleOpen}
        onChange={handleChange}
        onFocus={onFocus}
        value={inputValue}
        label={label}
        placeholder={placeholder}
        error={error}
        InputLabelProps={error ? { shrink: true } : {}}
        fullWidth
        autoComplete="off"
        disabled={disabled}
      />
      {!disabled && (
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
          style={{ zIndex: 100, width: 'inherit' }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom-start' ? 'center top' : 'center bottom'
              }}
            >
              <Paper sx={{ maxHeight: 343, overflowY: 'auto' }}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="composition-menu" aria-labelledby="composition-button">
                    {Object.entries(searchOptions).map(([key, options]) => (
                      <MenuList key={key}>
                        <Typography variant="h3" sx={{ fontWeight: 700, fontSize: '15px', lineHeight: '21px', p: '10px 28px' }}>
                          {key}
                        </Typography>
                        {options.map((option, i) => (
                          <MenuItem key={i} onClick={handleSelect(option)} sx={{ whiteSpace: 'normal' }}>
                            {renderOption ? renderOption(option) : option.label}
                          </MenuItem>
                        ))}
                      </MenuList>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      )}
    </Box>
  )
}

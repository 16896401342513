/* eslint-disable no-unused-vars */

import { ErrorMessages } from './errorTypes'
import { OperationFilters } from './operationsTypes'
import { Product } from './productsTypes'

export interface ProductInOrder extends Omit<Product, 'description' | 'name_1c'> {
  count: number
  prev_count?: number
  count_left: number
  prev_count_left?: number
  write_off_left: number
  checkbox?: boolean
  products_audit_id?: string | null
}

export interface OrderPostingProduct extends ProductInOrder {
  order_id: string
  order_sku: string
  selected: boolean
  count_left: number
}

export interface Order {
  id: string
  sku: string
  status: string
  type: string
  user_id: string
  user_firstname: string
  user_lastname: string | null
  user_company: string | null
  warehouse_id: string
  warehouse_name: string
  bitrix_id: string | null
  comment: string | null
  created_at: Date
  updated_at: Date
  productsInOrder?: ProductInOrder[]
}

export interface OrderPosting extends Omit<Order, 'productsInOrder'> {
  productsInOrder: OrderPostingProduct[]
}

export interface OrderFilters extends Omit<OperationFilters, 'type'> {
  status: string | null
}

export interface OrdersState {
  orders: Order[]
  order: Order | null
  productsInOrder: ProductInOrder[]
  originalProducts: ProductInOrder[]
  ordersPosting: OrderPosting[]
  selectedOrdersPosting: OrderPostingProduct[]
  currentProductId: string | null
  limit: number
  count: number
  isLoaded: boolean
  productsError: string | null
  filters: OrderFilters
  errors: ErrorMessages
}

export enum OrdersActionTypes {
  GET_ORDERS = 'GET_ORDERS',
  GET_ORDER = 'GET_ORDER',
  SET_ORDER = 'SET_ORDER',
  GET_ORDERS_POSTING = 'GET_ORDERS_POSTING',
  SET_ORDERS_POSTING = 'SET_ORDERS_POSTING',
  SET_ORDERS_POSTING_PRODUCTS = 'SET_ORDERS_POSTING_PRODUCTS',
  BACK_ORDER_POSTING = 'BACK_ORDER_POSTING',
  DELETE_ORDERS_POSTING = 'DELETE_ORDERS_POSTING',
  SET_ORDERS = 'SET_ORDERS',
  SET_ORDER_COUNT = 'SET_ORDER_COUNT',
  SET_ORDER_LOADED = 'SET_ORDER_LOADED',
  ADD_ORDER_PRODUCT = 'ADD_ORDER_PRODUCT',
  PLUS_ORDER_PRODUCT = 'PLUS_ORDER_PRODUCT',
  MINUS_ORDER_PRODUCT = 'MINUS_ORDER_PRODUCT',
  PLUS_ORDERS_POSTING_PRODUCT = 'PLUS_ORDERS_POSTING_PRODUCT',
  MINUS_ORDERS_POSTING_PRODUCT = 'MINUS_ORDERS_POSTING_PRODUCT',
  SET_ORDER_PRODUCTS = 'SET_ORDER_PRODUCTS',
  SET_ORDER_PRODUCT_ERROR = 'SET_ORDER_PRODUCT_ERROR',
  UPDATE_ORDER_PRODUCT = 'UPDATE_ORDER_PRODUCT',
  SET_ORDER_FILTERS = 'SET_ORDER_FILTERS',
  SET_ORDERS_ERRORS = 'SET_ORDERS_ERRORS'
}

export interface GetOrdersThunkCreator {
  type: OrdersActionTypes.GET_ORDERS
  payload: Order[]
}

export interface GetOrderThunkCreator {
  type: OrdersActionTypes.GET_ORDER
  payload: Order
}

export interface SetOrder {
  type: OrdersActionTypes.SET_ORDER
  payload: Order | null
}

export interface GetOrdersPostingThunkCreator {
  type: OrdersActionTypes.GET_ORDERS_POSTING
  payload: OrderPosting[]
}

export interface SetOrdersPostingAction {
  type: OrdersActionTypes.SET_ORDERS_POSTING
  payload: OrderPosting[]
}

export interface SetOrdersPostingProductsAction {
  type: OrdersActionTypes.SET_ORDERS_POSTING_PRODUCTS
  payload: OrderPostingProduct[]
}

export interface BackOrderPosting {
  type: OrdersActionTypes.BACK_ORDER_POSTING
  payload: OrderPosting
}

export interface DeleteOrdersPosting {
  type: OrdersActionTypes.DELETE_ORDERS_POSTING
  payload: string
}

export interface SetOrders {
  type: OrdersActionTypes.SET_ORDERS
  payload: Order[]
}

export interface SetOrderCount {
  type: OrdersActionTypes.SET_ORDER_COUNT
  payload: number
}

export interface SetOrederLoaded {
  type: OrdersActionTypes.SET_ORDER_LOADED
  payload: boolean
}

export interface AddOrderProduct {
  type: OrdersActionTypes.ADD_ORDER_PRODUCT
  payload: ProductInOrder
}

export interface SetOrderProducts {
  type: OrdersActionTypes.SET_ORDER_PRODUCTS
  payload: ProductInOrder[]
}

export interface PlusOrderProduct {
  type: OrdersActionTypes.PLUS_ORDER_PRODUCT
  payload: string
}

export interface MinusOrderProduct {
  type: OrdersActionTypes.MINUS_ORDER_PRODUCT
  payload: string
}

export interface PlusOrdersPostingProduct {
  type: OrdersActionTypes.PLUS_ORDERS_POSTING_PRODUCT
  payload: { orderId: string; productId: string }
}

export interface MinusOrdersPostingProduct {
  type: OrdersActionTypes.MINUS_ORDERS_POSTING_PRODUCT
  payload: { orderId: string; productId: string }
}

export interface SetOrderProductError {
  type: OrdersActionTypes.SET_ORDER_PRODUCT_ERROR
  payload: string | null
}

export interface UpdateOrderProduct {
  type: OrdersActionTypes.UPDATE_ORDER_PRODUCT
  payload: ProductInOrder
}

export interface SetOrderFilters {
  type: OrdersActionTypes.SET_ORDER_FILTERS
  payload: Partial<OrderFilters>
}

export interface SetOrdersErrorsAction {
  type: OrdersActionTypes.SET_ORDERS_ERRORS
  payload: ErrorMessages
}

export type OrdersAction =
  | GetOrdersThunkCreator
  | GetOrderThunkCreator
  | SetOrder
  | GetOrdersPostingThunkCreator
  | SetOrders
  | SetOrderCount
  | SetOrederLoaded
  | AddOrderProduct
  | SetOrderProducts
  | PlusOrderProduct
  | MinusOrderProduct
  | SetOrdersPostingProductsAction
  | DeleteOrdersPosting
  | PlusOrdersPostingProduct
  | MinusOrdersPostingProduct
  | SetOrderProductError
  | BackOrderPosting
  | UpdateOrderProduct
  | SetOrderFilters
  | SetOrdersPostingAction
  | SetOrdersErrorsAction

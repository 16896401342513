/* eslint-disable camelcase */
import React, { useEffect } from 'react'
import { Breadcrumbs } from '../../components/Breadcrumbs/Breadcrumbs'
import { StyledHeader } from '../../infrasturcture/theme/styled'
import { Button, CircularProgress, Grid, Typography } from '@mui/material'
import { DownloadIcon } from '../../infrasturcture/icons'
import { useNavigate, useParams } from 'react-router-dom'
import { reports } from '../../infrasturcture/reports/reports'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { reportSchema } from '../../infrasturcture/yupSchemes/reportSchema'
import { createDownloadLink } from '../../infrasturcture/utils/createDownloadLink'
import ReportsApi from '../../api/ReportsApi'
import { format } from 'date-fns'
import styles from '../../styles/Form.module.css'
import { TempSelect } from '../../components/TempSelect/TempSelect'
import { useActions } from '../../hooks/useActions'
import { useTypeSelector } from '../../hooks/useTypeSelector'
import { DateInput } from '../../components/DateInput/DateInput'

export const Report: React.FC = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { warehouses } = useTypeSelector((state) => state.warehouses)
  const { getWarehousesThunkCreator } = useActions()

  const report = reports.find((r) => r.id === id)

  const {
    handleSubmit,

    reset,
    control,
    formState: { isSubmitting, errors }
  } = useForm({ resolver: yupResolver(reportSchema), mode: 'onChange' })

  const onSubmit = ({ warehouse_id, dateStart, dateEnd }: any) => {
    const warehouse = warehouses.find((w) => w.id === warehouse_id)

    const requestData = {
      warehouse_id,
      date: `${format(new Date(dateStart), 'yyyy-MM-dd')},${format(new Date(dateEnd), 'yyyy-MM-dd')}`
    }

    const filename = `Выписка. ${warehouse?.name} (${format(new Date(dateStart), 'dd.MM.yyyy')}~${format(
      new Date(dateEnd),
      'dd.MM.yyyy'
    )}).xlsx`

    return new Promise((resolve) =>
      setTimeout(async () => {
        try {
          const blob = await ReportsApi.getWarehouseReport(requestData)
          createDownloadLink(blob, filename)
          reset()
          resolve(true)
        } catch (e: any) {
          navigate(`/${503}`)
        }
      }, 2000)
    )
  }

  useEffect(() => {
    getWarehousesThunkCreator({})
  }, [])

  return (
    <div className={styles.container}>
      {report && (
        <>
          <Breadcrumbs customText={report.title} />
          <StyledHeader>
            <Typography variant="h1">{report.title}</Typography>

            {isSubmitting ? (
              <CircularProgress sx={{ width: '20px !important', height: '20px !important' }} color="primary" />
            ) : (
              <Button onClick={handleSubmit(onSubmit)} color="primary" startIcon={<DownloadIcon />}>
                Загрузить
              </Button>
            )}
          </StyledHeader>
          <div className={styles.wrapperSmall}>
            <Grid className={styles.fields} container spacing={3} component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
              <Grid item xs={12}>
                <TempSelect
                  control={control}
                  options={[
                    { value: '', label: '<Не выбрано>' },
                    ...warehouses.map((item) => ({ value: item.id, label: item.name }))
                  ]}
                  label={errors?.warehouse_id?.message ?? 'Название склада'}
                  name="warehouse_id"
                  error={!!errors?.warehouse_id?.message}
                />
              </Grid>
              <Grid item xs={6}>
                <DateInput
                  control={control}
                  name="dateStart"
                  label={errors?.dateStart?.message ?? 'От'}
                  error={!!errors?.dateStart?.message}
                  maxDate={new Date()}
                />
              </Grid>
              <Grid item xs={6}>
                <DateInput
                  control={control}
                  name="dateEnd"
                  label={errors?.dateEnd?.message ?? 'До'}
                  error={!!errors?.dateEnd?.message}
                  maxDate={new Date()}
                  popperPlacement="bottom-end"
                />
              </Grid>
            </Grid>
          </div>
        </>
      )}
    </div>
  )
}

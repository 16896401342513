import { Box } from '@mui/material'
import React from 'react'
import { OperationTitle } from '../../../components/Operations/OperationTitle'
import { VgTable, VgTableColumnType } from '../../../components/VgTable/VgTable'
import { useTypeSelector } from '../../../hooks/useTypeSelector'
import { OperationProduct, operationTypes, ProductInOperation } from '../../../types/operationsTypes'
import { ProductInfo } from '../../../components/Products/ProductInfo'
import { StyledCell, StyledLinkRouter } from '../../../infrasturcture/theme/styled'
import { Count } from '../../../components/Operations/Count'
import { money } from '../../../types/productsTypes'
import { OrderPostingProduct } from '../../../types/ordersTypes'
import { useActions } from '../../../hooks/useActions'
import { BookingDoneProduct } from '../../../types/bookingsTypes'
import { useParams } from 'react-router-dom'

interface OperationProductsProps {
  type: 'create' | 'edit'
}

const subInfo: Record<string, { title: string; rowTitle: string; to: string }> = {
  POSTING: { title: 'Товары под заявки', rowTitle: 'Номер заявки', to: 'orders' },
  WRITE_OFF: { title: 'Товары под бронирования', rowTitle: 'Номер бронирования', to: 'bookings' },
  MOVING: { title: 'Товары под заявки', rowTitle: 'Номер заявки', to: 'orders' }
}

export const OperationProducts: React.FC<OperationProductsProps> = ({ type }) => {
  const { type: operationType } = useParams()
  const { productsInOperation, products, operation } = useTypeSelector((state) => state.operations)
  const { selectedOrdersPosting, ordersPosting } = useTypeSelector((state) => state.orders)
  const { selectedBookingsDone, bookingsDone } = useTypeSelector((state) => state.bookings)
  const {
    plusProduct,
    minusProduct,
    plusOrdersPostingProduct,
    minusOrdersPostingProduct,
    setOrdersPosting,
    minusBookingsDoneProduct,
    plusBookingsDoneProduct,
    setBookingsDone
  } = useActions()

  const TYPE = operationType?.toUpperCase() || operation?.type || operationTypes.POSTING
  const info = subInfo[operation?.type || TYPE]

  const bookingsProducts = selectedBookingsDone.reduce(
    (obj, item) => {
      const key = item.isBooking ? 'bookings' : 'orders'
      return { ...obj, [key]: [...obj[key], item] }
    },
    { bookings: [], orders: [] }
  )

  const handleMinus = (productId: string, count: number) => {
    minusProduct(productId)
    if (selectedOrdersPosting.length > 0) {
      const ordersCount = selectedOrdersPosting.reduce((acc, item) => (item.id === productId ? acc + item.count : acc + 0), 0)
      const targetProduct = selectedOrdersPosting.find((p) => p.id === productId)!
      if (targetProduct && count === ordersCount) {
        minusOrdersPostingProduct(targetProduct.order_id, targetProduct.id)
        if (targetProduct.count === 1) {
          setOrdersPosting(
            ordersPosting.map((o) =>
              o.id === targetProduct.order_id
                ? {
                    ...o,
                    productsInOrder: o.productsInOrder.map((p) => (p.id === targetProduct.id ? { ...p, selected: false } : p))
                  }
                : o
            )
          )
        }
      }
    }

    if (selectedBookingsDone.length > 0) {
      const bookingsCount = selectedBookingsDone.reduce((acc, item) => (item.id === productId ? acc + item.count : acc + 0), 0)
      if (count === bookingsCount) {
        const targetProduct = selectedBookingsDone.find((p) => p.id === productId)!
        minusBookingsDoneProduct(targetProduct.order_id, targetProduct.id, targetProduct.isBooking)
        if (targetProduct.count === 1) {
          setBookingsDone(
            bookingsDone.map((b) =>
              b.id === targetProduct.order_id
                ? {
                    ...b,
                    productsInBooking: b.productsInBooking.map((p) =>
                      p.id === targetProduct.id ? { ...p, selected: false } : p
                    ),
                    productsInOrder: b.productsInOrder.map((p) => (p.id === targetProduct.id ? { ...p, selected: false } : p))
                  }
                : b
            )
          )
        }
      }
    }
  }

  const handleSubPlus = (product: OrderPostingProduct | BookingDoneProduct) => {
    TYPE === operationTypes.WRITE_OFF
      ? plusBookingsDoneProduct(product.order_id, product.id, 'isBooking' in product && product.isBooking)
      : plusOrdersPostingProduct(product.order_id, product.id)
    plusProduct(product.id)
  }

  const handleSubMinus = (product: OrderPostingProduct | BookingDoneProduct) => {
    minusProduct(product.id)

    if (TYPE === operationTypes.WRITE_OFF) {
      minusBookingsDoneProduct(product.order_id, product.id, 'isBooking' in product && product.isBooking)
      if (product.count === 1) {
        setBookingsDone(
          bookingsDone.map((b) =>
            b.id === product.order_id
              ? {
                  ...b,
                  productsInBooking: b.productsInBooking.map((p) => (p.id === product.id ? { ...p, selected: false } : p)),
                  productsInOrder: b.productsInOrder.map((p) => (p.id === product.id ? { ...p, selected: false } : p))
                }
              : b
          )
        )
      }
    } else {
      minusOrdersPostingProduct(product.order_id, product.id)
      if (product.count === 1) {
        setOrdersPosting(
          ordersPosting.map((o) =>
            o.id === product.order_id
              ? { ...o, productsInOrder: o.productsInOrder.map((p) => (p.id === product.id ? { ...p, selected: false } : p)) }
              : o
          )
        )
      }
    }
  }

  const checkOperationProductsDisabled = (id: string, count: number): boolean => {
    if (TYPE === operationTypes.MOVING) return (products.find((p) => p.id === id)?.count ?? count) === count
    if (TYPE === operationTypes.WRITE_OFF) {
      const productCount = products.find((p) => p.id === id)?.count || 0
      const bookingDoneProductCount = selectedBookingsDone.find((p) => p.id === id)?.count || 0
      return productCount + bookingDoneProductCount === count
    }
    return false
  }

  const operationProductsColumns: VgTableColumnType<OperationProduct | ProductInOperation>[] = [
    {
      key: 'name',
      title: 'Товар',
      render: (_, product) => <ProductInfo product={product} isShowTooltip={false} />,
      width: '30%'
    },
    {
      key: 'color',
      title: 'Цвет',
      render: (_, { color }) => <StyledCell>{color}</StyledCell>,
      width: '15%'
    },
    {
      key: 'purchase_price',
      title: 'Розничная цена',
      render: (_, item) => (item.purchase_price ? `${item.purchase_price} ${money.RUB}` : ''),
      width: '20%'
    },
    {
      key: 'manufacturer_name',
      title: 'Производитель',
      render: (_, item) => <StyledCell>{item.manufacturer_name}</StyledCell>,
      width: '20%'
    },
    {
      key: 'count',
      title: 'Количество',
      render: (_, { id, count }) => {
        return (
          <Count
            {...(type === 'create'
              ? {
                  onPlus: () => plusProduct(id),
                  onMinus: () => handleMinus(id, count),
                  disabledPlus: checkOperationProductsDisabled(id, count)
                }
              : {})}
          >
            {count}
          </Count>
        )
      },
      textAlign: 'center',
      width: '15%'
    }
  ]

  const subProductsColumns: VgTableColumnType<BookingDoneProduct | ProductInOperation>[] = [
    {
      key: 'name',
      title: 'Товар',
      render: (_, product) => <ProductInfo product={product} isShowTooltip={false} />,
      width: '28%'
    },
    {
      key: 'color',
      title: 'Цвет',
      render: (_, { color }) => <StyledCell>{color}</StyledCell>,
      width: '17%'
    },
    {
      key: 'order_sku',
      title: info.rowTitle,
      render: (_, item) => (
        <StyledLinkRouter target="_blank" to={`/${info.to}/${item.order_sku}`}>
          {item.order_sku}
        </StyledLinkRouter>
      ),
      width: '20%'
    },
    {
      key: 'count_left',
      title: TYPE === operationTypes.WRITE_OFF ? 'Осталось списать' : 'Осталось оприходовать',
      render: (_, item) => <Count>{TYPE === operationTypes.WRITE_OFF ? item.write_off_left : item.count_left}</Count>,
      textAlign: 'center',
      width: '20%'
    },
    {
      key: 'count',
      title: 'Количество',
      render: (_, item) => {
        return (
          <Count
            {...(type === 'create'
              ? {
                  onPlus: () => handleSubPlus(item as OrderPostingProduct | BookingDoneProduct),
                  onMinus: () => handleSubMinus(item as OrderPostingProduct | BookingDoneProduct),
                  disabledPlus: item.write_off_left === 0
                }
              : {})}
          >
            {item.count}
          </Count>
        )
      },
      textAlign: 'center',
      width: '15%'
    }
  ]

  const ordersPostingProductsColumns: VgTableColumnType<OrderPostingProduct>[] = [
    {
      key: 'name',
      title: 'Товар',
      render: (_, product) => <ProductInfo product={product} isShowTooltip={false} />,
      width: '28%'
    },
    {
      key: 'color',
      title: 'Цвет',
      render: (_, { color }) => <StyledCell>{color}</StyledCell>,
      width: '17%'
    },
    {
      key: 'order_sku',
      title: 'Номер заявки',
      render: (_, item) => (
        <StyledLinkRouter target="_blank" to={`/${info.to}/${item.order_sku}`}>
          {item.order_sku}
        </StyledLinkRouter>
      ),
      width: '20%'
    },
    {
      key: 'count_left',
      title: TYPE === operationTypes.WRITE_OFF ? 'Осталось списать' : 'Осталось оприходовать',
      render: (_, item) => <Count>{TYPE === operationTypes.WRITE_OFF ? item.write_off_left : item.count_left}</Count>,
      textAlign: 'center',
      width: '20%'
    },
    {
      key: 'count',
      title: 'Количество',
      render: (_, item) => {
        return (
          <Count
            {...(type === 'create'
              ? {
                  onPlus: () => handleSubPlus(item as OrderPostingProduct | BookingDoneProduct),
                  onMinus: () => handleSubMinus(item as OrderPostingProduct | BookingDoneProduct),
                  disabledPlus:
                    products.find((p) => p.id === item.id)?.count ===
                      selectedOrdersPosting.reduce((acc, p) => (p.id === item.id ? acc + item.count : acc), 0) ||
                    (TYPE === operationTypes.WRITE_OFF ? item.write_off_left === 0 : item.count_left === 0)
                }
              : {})}
          >
            {item.count}
          </Count>
        )
      },
      textAlign: 'center',
      width: '15%'
    }
  ]

  return (
    <Box>
      {type === 'create' ? (
        <>
          {!!productsInOperation.length && (
            <Box>
              <OperationTitle variant="large" isShowTooltip>
                Товары в операции
              </OperationTitle>
              <VgTable data={productsInOperation} columns={operationProductsColumns} />
            </Box>
          )}
          {!!selectedOrdersPosting.length && (
            <Box sx={{ mt: '40px' }}>
              <OperationTitle variant="large">Товары под заявки</OperationTitle>
              <VgTable
                data={selectedOrdersPosting}
                columns={ordersPostingProductsColumns}
                strokeRow={(item) => item.count_left === 0}
              />
            </Box>
          )}
          {!!bookingsProducts.bookings.length && (
            <Box sx={{ mt: '40px' }}>
              <OperationTitle variant="large">Товары под бронирования</OperationTitle>
              <VgTable
                data={bookingsProducts.bookings}
                columns={subProductsColumns}
                strokeRow={(item) => item.write_off_left === 0}
              />
            </Box>
          )}
          {!!bookingsProducts.orders.length && (
            <Box sx={{ mt: '40px' }}>
              <OperationTitle variant="large">Товары под заявки</OperationTitle>
              <VgTable
                data={bookingsProducts.orders}
                columns={ordersPostingProductsColumns}
                strokeRow={(item) => item.write_off_left === 0}
              />
            </Box>
          )}
        </>
      ) : (
        <>
          {!!operation?.productsInOperation?.length && (
            <Box>
              <OperationTitle variant="large" isShowTooltip>
                Товары в операции
              </OperationTitle>
              <VgTable data={operation.productsInOperation} columns={operationProductsColumns} />
            </Box>
          )}
          {!!operation?.productsInOrdersPosting?.length && (
            <Box sx={{ mt: '40px' }}>
              <OperationTitle variant="large">{info.title}</OperationTitle>
              <VgTable
                data={operation?.productsInOrdersPosting}
                columns={subProductsColumns}
                strokeRow={(item) => (TYPE === operationTypes.WRITE_OFF ? item.write_off_left === 0 : item.count_left === 0)}
              />
            </Box>
          )}
        </>
      )}
    </Box>
  )
}

import { format } from '@react-input/mask'

interface Code {
  [key: number]: string
}

const codes: Code = {
  7: '+_ (___) ___ - __ - __',
  375: '+___ (__) ___ - __ - __'
}

const getCountryCode = (input: string): number => {
  for (const code of Object.keys(codes)) {
    if (input.startsWith(code)) return +code
  }

  return 375
}

export const formatPhone = (input: string, clean = false): string => {
  const cleanedInput = input.replace(/[^\d]/g, '')
  if (clean) return cleanedInput

  const mask = codes[getCountryCode(cleanedInput)]
  return format(input, { mask, replacement: '_' })
}

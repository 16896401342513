/* eslint-disable no-unused-vars */
import React from 'react'

import { VgTable, VgTableColumnType } from '../../../components/VgTable/VgTable'
import { BookingDone, BookingDoneProduct } from '../../../types/bookingsTypes'
import { Checkbox } from '../../../components/Checkbox/Checkbox'
import { ProductInfo } from '../../../components/Products/ProductInfo'
import { StyledCell } from '../../../infrasturcture/theme/styled'
import { money } from '../../../types/productsTypes'
import { Count } from '../../../components/Operations/Count'
import styles from '../../../styles/OperationRow.module.css'
import { OperationTitle } from '../../../components/Operations/OperationTitle'
import { useActions } from '../../../hooks/useActions'
import { useTypeSelector } from '../../../hooks/useTypeSelector'
import { Box } from '@mui/material'

interface BookingDoneRowProps {
  booking: BookingDone
}

export const BookingDoneRow: React.FC<BookingDoneRowProps> = ({ booking }) => {
  const { setBookingsDone, setBookingsErrors } = useActions()
  const { bookingsDone, products } = useTypeSelector((state) => state.bookings)
  const { productsInOperation } = useTypeSelector((state) => state.operations)

  const bookingProductsColumns: VgTableColumnType<BookingDoneProduct>[] = [
    {
      key: 'id',
      title: '',
      render: (_, product) => {
        /* const availableCount = products.find((item) => item.id === product.id)?.count || 0
        const bookingProductCount = productsInOperation.find((item) => item.id === product.id)?.count || 0
        const isExist = availableCount - (1 + bookingProductCount) >= 0 */

        return (
          <Box sx={{ m: '-10px 0' }}>
            <Checkbox
              /*  onClick={() => !isExist && setBookingsErrors({ bookingsDone: 'Необходимые на складе товары отсутствуют' })} */
              onChange={() =>
                setBookingsDone(
                  bookingsDone.map((b) =>
                    b.id === booking.id
                      ? {
                          ...b,
                          productsInBooking: b.productsInBooking.map((p) =>
                            p.id === product.id ? { ...p, selected: !product.selected } : p
                          )
                        }
                      : b
                  )
                )
              }
              checked={product.selected}
              /*   shakeOnClick={!isExist}
              disabled={!isExist || product.count_left === 0}
              error={!isExist} */
            />
          </Box>
        )
      },
      width: '5%',
      style: { paddingLeft: 22 }
    },
    {
      key: 'name',
      title: 'Товар',
      render: (_, product) => <ProductInfo product={product} />,
      width: '25%'
    },
    {
      key: 'color',
      title: 'Цвет',
      render: (_, { color }) => <StyledCell>{color}</StyledCell>,
      width: '15%'
    },
    {
      key: 'purchase_price',
      title: 'Розничная цена',
      render: (_, item) => (item.purchase_price ? `${item.purchase_price} ${money.RUB}` : ''),
      width: '15%'
    },
    {
      key: 'delivery_time',
      title: 'Время доставки',
      render: (_, item) => `${item.delivery_time} дн`,
      width: '10%',
      textAlign: 'center'
    },
    {
      key: 'write_off_left',
      title: 'Осталось списать',
      render: (_, item) => <Count>{item.write_off_left}</Count>,
      textAlign: 'center',
      width: '15%'
    },
    {
      key: 'count',
      title: 'Количество',
      render: (_, { count }) => <Count>{count}</Count>,
      textAlign: 'center',
      width: '15%'
    }
  ]

  const orderProductsColumns: VgTableColumnType<BookingDoneProduct>[] = [
    {
      key: 'id',
      title: '',
      render: (_, product) => {
        /*   const availableCount = products.find((item) => item.id === product.id)?.count || 0
        const orderProductCount = productsInOperation.find((item) => item.id === product.id)?.count || 0
        const isExist = availableCount - (1 + orderProductCount) >= 0 */

        return (
          <Checkbox
            /* onClick={() => !isExist && setBookingsErrors({ bookingsDone: 'Необходимые на складе товары отсутствуют' })} */
            onChange={() =>
              setBookingsDone(
                bookingsDone.map((b) =>
                  b.id === booking.id
                    ? {
                        ...b,
                        productsInOrder: b.productsInOrder.map((p) =>
                          p.id === product.id ? { ...p, selected: !product.selected } : p
                        )
                      }
                    : b
                )
              )
            }
            checked={product.selected}
            /*  shakeOnClick={!isExist}
            disabled={!isExist}
            error={!isExist} */
          />
        )
      },
      width: '5%',
      style: { paddingLeft: 22 }
    },
    {
      key: 'name',
      title: 'Товар',
      render: (_, product) => <ProductInfo product={product} />,
      width: '25%'
    },
    {
      key: 'color',
      title: 'Цвет',
      render: (_, { color }) => <StyledCell>{color}</StyledCell>,
      width: '15%'
    },
    {
      key: 'purchase_price',
      title: 'Розничная цена',
      render: (_, item) => (item.purchase_price ? `${item.purchase_price} ${money.RUB}` : ''),
      width: '15%'
    },
    {
      key: 'delivery_time',
      title: 'Время доставки',
      render: (_, item) => `${item.delivery_time} дн`,
      width: '10%',
      textAlign: 'center'
    },
    {
      key: 'write_off_left',
      title: 'Осталось списать',
      render: (_, item) => <Count>{item.write_off_left}</Count>,
      textAlign: 'center',
      width: '15%'
    },
    {
      key: 'count',
      title: 'Количество',
      render: (_, { count }) => <Count>{count}</Count>,
      textAlign: 'center',
      width: '15%'
    }
  ]

  return (
    <tr className={styles.row}>
      <td className={styles.cell} colSpan={9}>
        {!!booking.productsInBooking.length && (
          <div className={styles.wrapper}>
            <OperationTitle>Товары в бронировании</OperationTitle>
            <VgTable
              className={styles.tableContainer}
              classNameRow={styles.tableRow}
              data={booking.productsInBooking}
              columns={bookingProductsColumns}
            />
          </div>
        )}
        {!!booking.productsInOrder.length && (
          <div className={styles.wrapper}>
            <OperationTitle>Товары в заявке</OperationTitle>
            <VgTable
              className={styles.tableContainer}
              classNameRow={styles.tableRow}
              data={booking.productsInOrder}
              columns={orderProductsColumns}
            />
          </div>
        )}
      </td>
    </tr>
  )
}

import { instance } from '../infrasturcture/http'
import { CreateUserFields, EditUserFields } from '../types/usersTypes'
import { User } from '../types/userTypes'

export default class UserApi {
  static async getMe() {
    const response = await instance.get('users/me')
    return response.data.data
  }

  static async getOne(id: string) {
    const response = await instance.get(`users/${id}`)
    return response.data.data
  }

  static async editMe(me: User) {
    const response = await instance.post('users/me', me)
    return response.data.data
  }

  static async changePassword(oldPassword: string, newPassword: string) {
    const response = await instance.post('/users/me/change-password', { oldPassword, newPassword })
    return response.data.data
  }

  static async getUsers() {
    const response = await instance.get('users')
    return response.data.data
  }

  static async createUser(user: CreateUserFields) {
    const response = await instance.post('users', user)
    return response.data.data
  }

  static async deleteUser(id: string) {
    const response = await instance.delete(`users/${id}`)
    return response.data.data
  }

  static async editUser(id: string, user: EditUserFields) {
    const response = await instance.post(`users/${id}`, user)
    return response.data.data
  }
}

import { SectionsAction, SectionsActionTypes, SectionsState } from '../../types/sectionsTypes'

const initialState: SectionsState = {
  sections: [],
  section: null,
  isLoaded: false,
  errors: {}
}

export const sectionsReducer = (state = initialState, action: SectionsAction): SectionsState => {
  switch (action.type) {
    case SectionsActionTypes.GET_SECTIONS:
      return {
        ...state,
        isLoaded: false,
        sections: action.payload,
        section: null
      }
    case SectionsActionTypes.GET_SECTION:
      return {
        ...state,
        isLoaded: false,
        section: action.payload
      }
    case SectionsActionTypes.UPDATE_SECTION:
      return {
        ...state,
        errors: {},
        section: action.payload
      }
    case SectionsActionTypes.SET_SECTION:
      return {
        ...state,
        section: action.payload
      }
    case SectionsActionTypes.SET_SECTIONS_LOADED:
      return {
        ...state,
        isLoaded: action.payload
      }
    case SectionsActionTypes.SET_SECTIONS_ERRORS:
      return {
        ...state,
        errors: action.payload
      }
    default:
      return state
  }
}

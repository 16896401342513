import * as Yup from 'yup'

export const createOperactionSchema = Yup.object().shape({
  warehouse_id_from: Yup.string().required('Укажите склад').nullable(),
  bitrix_id: Yup.string().nullable()
})

export const createMovingOperactionSchema = Yup.object().shape({
  warehouse_id_from: Yup.string().required('Укажите склад').nullable(),
  warehouse_id_to: Yup.string().required('Укажите второй склад').nullable(),
  bitrix_id: Yup.string().nullable()
})

import { MutableRefObject, useEffect } from 'react'

export const useObserver = (
  ref: MutableRefObject<HTMLElement | null>,
  rootMargin: string,
  isLoading: boolean,
  canLoad: boolean,
  cb: () => void
) => {
  useEffect(() => {
    if (isLoading) return
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && canLoad) {
          cb()
        }
      },
      { rootMargin }
    )
    if (ref.current) {
      observer.observe(ref.current)
    }
    return () => {
      if (ref.current) {
        observer.unobserve(ref.current)
      }
    }
  }, [isLoading])
}

import React, { useEffect } from 'react'
import { Breadcrumbs } from '../../components/Breadcrumbs/Breadcrumbs'
import { StyledHeader, StyledCell } from '../../infrasturcture/theme/styled'
import { Box, Button, Typography } from '@mui/material'
import { PlusIcon } from '../../infrasturcture/icons'
import { VgTable, VgTableColumnType } from '../../components/VgTable/VgTable'
import { Warehouse } from '../../types/warehousesTypes'
import { useNavigate } from 'react-router-dom'
import { useTypeSelector } from '../../hooks/useTypeSelector'
import { useActions } from '../../hooks/useActions'
import styles from './Warehouses.module.css'

const BookingLabel: React.FC<{ text: string }> = ({ text }) => {
  return (
    <Box className={styles.label}>
      <Typography className={styles.labelMain} component="span">
        {text}
      </Typography>
      <Typography className={styles.labelSub} component="span">
        Бронь
      </Typography>
    </Box>
  )
}

const warehousesColumns: VgTableColumnType<Warehouse>[] = [
  {
    key: 'name',
    title: 'Название',
    width: '30%',
    render: (_, { name, isBooking }) => (isBooking ? <BookingLabel text={name} /> : name)
  },
  {
    key: 'region',
    title: 'Регион',
    width: '25%',
    render: (_, { region }) => region
  },
  {
    key: 'address',
    title: 'Почтовый адрес',
    width: '45%',
    render: (_, item) => <StyledCell>{item.address}</StyledCell>
  }
]

export const Warehouses: React.FC = () => {
  const navigate = useNavigate()
  const { warehouses } = useTypeSelector((state) => state.warehouses)
  const { getWarehousesThunkCreator } = useActions()

  useEffect(() => {
    getWarehousesThunkCreator({})
  }, [])

  return (
    <div className={styles.container}>
      <Breadcrumbs />
      <StyledHeader>
        <Typography variant="h1">Склады</Typography>
        <Button href="/warehouses/create" color="primary" startIcon={<PlusIcon />}>
          Добавить склад
        </Button>
      </StyledHeader>
      <VgTable
        className={styles.table}
        onRowClick={(item) => navigate(`/warehouses/${item.id}`)}
        data={warehouses}
        columns={warehousesColumns}
      />
    </div>
  )
}

import { ThunkAction } from 'redux-thunk'
import { RootState } from '..'
import {
  Manufacturer,
  ManufacturersAction,
  ManufacturersActionTypes,
  SetManufacturerAction,
  SetManufacturersErrorsAction,
  SetManufacturersLoaded
} from '../../types/manufacturersTypes'
import { SetAuthErrorAction } from '../../types/userTypes'
import ManufacturersApi from '../../api/ManufacturersApi'
import { logoutThunkCreator } from './userActions'
import { ErrorMessages } from '../../types/errorTypes'
import { errorsHandling } from '../../infrasturcture/utils/errorsHandling'
import { NavigateFunction } from 'react-router-dom'

type ThunkType = ThunkAction<void, RootState, unknown, ManufacturersAction | SetAuthErrorAction>

export const setManufacturersLoaded = (payload: boolean): SetManufacturersLoaded => ({
  type: ManufacturersActionTypes.SET_MANUFACTURERS_LOADED,
  payload
})

export const setManufacturersErrors = (payload: ErrorMessages): SetManufacturersErrorsAction => ({
  type: ManufacturersActionTypes.SET_MANUFACTURERS_ERRORS,
  payload
})

export const setManufacturer = (payload: Manufacturer | null): SetManufacturerAction => ({
  type: ManufacturersActionTypes.SET_MANUFACTURER,
  payload
})

export const getManufacturersThunkCreator = (): ThunkType => {
  return async (dispatch) => {
    try {
      dispatch(setManufacturersLoaded(true))
      const data = await ManufacturersApi.getManufacturers()
      dispatch({ type: ManufacturersActionTypes.GET_MANUFACTURERS, payload: data })
    } catch (e: any) {
      dispatch(logoutThunkCreator())
    }
  }
}

export const getManufacturerThunkCreator = (id: string, navigate: NavigateFunction): ThunkType => {
  return async (dispatch) => {
    try {
      dispatch(setManufacturersLoaded(true))
      const data = await ManufacturersApi.getOne(id)
      dispatch({ type: ManufacturersActionTypes.GET_MANUFACTURER, payload: data })
    } catch (e: any) {
      if ([403, 404, 503].includes(e.response.status)) {
        return navigate(`/${e.response.status}`)
      }
      dispatch(logoutThunkCreator())
    }
  }
}

export const createManufacturerThunkCreator = (
  manufacturer: Manufacturer,
  cb: () => void,
  navigate: NavigateFunction
): ThunkType => {
  return async (dispatch) => {
    try {
      await ManufacturersApi.createManufacturer(manufacturer)
      cb()
    } catch (e: any) {
      switch (e.response.status) {
        case 422:
          dispatch(setManufacturersErrors(errorsHandling(e.response)))
          break
        case 503:
          navigate('/503')
          break
        default:
          dispatch(logoutThunkCreator())
      }
    }
  }
}

export const editManufacturerThunkCreator = (
  manufacturer: Manufacturer,
  cb: () => void,
  navigate: NavigateFunction
): ThunkType => {
  return async (dispatch) => {
    try {
      await ManufacturersApi.editManufacturer(manufacturer)
      dispatch({ type: ManufacturersActionTypes.UPDATE_MANUFACTURER, payload: manufacturer })
      cb()
    } catch (e: any) {
      if (e.response.status === 422) return dispatch(setManufacturersErrors(errorsHandling(e.response)))
      if ([403, 404, 503].includes(e.response.status)) return navigate(`/${e.response.status}`)
      dispatch(logoutThunkCreator())
    }
  }
}

export const deleteManufacturerThunkCreator = (id: string, cb: () => void, navigate: NavigateFunction): ThunkType => {
  return async (dispatch) => {
    try {
      await ManufacturersApi.deleteManufacturer(id)
      cb()
    } catch (e: any) {
      if (e.response.status === 422) return dispatch(setManufacturersErrors(errorsHandling(e.response)))
      if ([403, 404, 503].includes(e.response.status)) return navigate(`/${e.response.status}`)
      dispatch(logoutThunkCreator())
    }
  }
}

import { Button, Typography } from '@mui/material'
import React from 'react'
import styles from './Filter.module.css'

interface FilterFooterProps {
  onApply: () => void
  onReset: () => void
}

export const FilterFooter: React.FC<FilterFooterProps> = ({ onApply, onReset }) => {
  return (
    <div className={styles.footer}>
      <Button onClick={onApply}>Применить</Button>
      <Typography
        onClick={onReset}
        component="span"
        color="secondary.main"
        sx={{
          mt: '16px',
          textAlign: 'center',
          fontSize: '12px',
          lineHeight: '16px',
          textDecoration: 'underline',
          cursor: 'pointer'
        }}
      >
        Сбросить фильтры
      </Typography>
    </div>
  )
}

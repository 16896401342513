import React from 'react'
import { SubmitHandler, useFormContext } from 'react-hook-form'
import { Grid, Typography } from '@mui/material'
import { TempInput } from '../../../components/Input/TempInput'
import { useTypeSelector } from '../../../hooks/useTypeSelector'
import { useActions } from '../../../hooks/useActions'
import styles from '../../../styles/Form.module.css'
import { Textarea } from '../../../components/Input/Textarea'

interface FormProps {
  onSubmit: SubmitHandler<any>
  type: 'create' | 'edit'
  disabled?: boolean
}

export const Form: React.FC<FormProps> = ({ onSubmit, type, disabled }) => {
  const { setWarehousesErrors } = useActions()
  const { errors: stateErrors } = useTypeSelector((state) => state.warehouses)
  const isDisabled = type === 'edit' && disabled

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useFormContext()

  const clearError = (name: 'name' | 'region') => {
    const { [name]: _, ...errors } = stateErrors
    setWarehousesErrors(errors)
  }

  return (
    <div className={styles.wrapperSmall}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.formItem}>
          <Typography variant="h2" color="text.primary">
            Общая информация
          </Typography>
          <Grid className={styles.fields} container spacing={3}>
            <Grid item xs={12}>
              <TempInput
                onChange={() => clearError('name')}
                control={control}
                label={(stateErrors?.name || errors?.name?.message) ?? 'Название склада'}
                placeholder="Название склада"
                name="name"
                error={!!stateErrors?.name || !!errors?.name?.message}
                disabled={isDisabled}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TempInput
                onChange={() => clearError('region')}
                control={control}
                label={(stateErrors?.region || errors?.region?.message) ?? 'Регион'}
                placeholder="Регион"
                name="region"
                error={!!stateErrors?.region || !!errors?.region?.message}
                disabled={isDisabled}
              />
            </Grid>
            <Grid item xs={12}>
              <Textarea
                control={control}
                label={(stateErrors?.address || errors?.address?.message) ?? 'Адрес'}
                placeholder="Адрес"
                name="address"
                error={!!stateErrors?.address || !!errors?.address?.message}
                rows={3}
                disabled={isDisabled}
              />
            </Grid>
            <Grid item xs={12} sx={{ mt: '-8px' }}>
              <Typography sx={{ fontWeight: 400, fontSize: 12, lineHeight: '16px' }} component="span" color="secondary.main">
                * Оформить бронирование можно только для склада с указаным регионом.
              </Typography>
            </Grid>
          </Grid>
        </div>
      </form>
    </div>
  )
}

export interface Country {
  code: string
  lang_ru: string
  mask: string
  icon: string
}

type CountryKeys = 'ru' | 'by'

export const countries: Record<CountryKeys, Country> = {
  ru: {
    code: '+7',
    lang_ru: 'Россия',
    mask: '+9 (999) 999 - 99 - 99',
    icon: 'RU'
  },
  by: {
    code: '+375',
    lang_ru: 'Беларусь',
    mask: '+999 (99) 999 - 99 - 99',
    icon: 'BY'
  }
}

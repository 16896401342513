import { Box, Typography } from '@mui/material'
import React from 'react'

interface OptionProps {
  text: string
  subText: string | null | undefined
  fontWeight?: number
  variant?: 'default' | 'table'
}

export const Option: React.FC<OptionProps> = ({ text, subText, fontWeight = 400, variant = 'default' }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', ...(variant === 'table' && { margin: '-10px 0' }) }}>
      <Typography component="span" color="text.primary" sx={{ fontSize: 15, lineHeight: '21px', fontWeight }}>
        {text}
      </Typography>
      {subText && (
        <Typography component="span" color="primary" sx={{ fontSize: 10, fontWeight: 700, lineHeight: '14px' }}>
          {subText}
        </Typography>
      )}
    </Box>
  )
}

/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { Breadcrumbs } from '../../components/Breadcrumbs/Breadcrumbs'
import styles from '../../styles/Operations.module.css'
import { StyledCell, StyledHeader } from '../../infrasturcture/theme/styled'
import { Box, Button, Typography } from '@mui/material'
import { VgTable, VgTableColumnType } from '../../components/VgTable/VgTable'
import { SnacbarModal } from '../../components/MuiComponents/SnacbarModal/SnacbarModal'
import { useTypeSelector } from '../../hooks/useTypeSelector'
import { BookingDone, BookingDoneProduct } from '../../types/bookingsTypes'
import { Checkbox } from '../../components/Checkbox/Checkbox'
import { Status } from '../../components/Status/Status'
import { Option } from '../../components/Options/Option'
import { formatDate } from '../../infrasturcture/utils/formatDate'
import { BitrixLink } from '../../components/BitrixLink/BitrixLink'
import { OperationComment } from '../../components/Operations/OperationComment'
import { useDoubleClick } from '../../hooks/useDoubleClick'
import { BookingDoneRow } from './ui/BookingDoneRow'
import { useActions } from '../../hooks/useActions'
import { useNavigate } from 'react-router-dom'
import groupBy from 'lodash.groupby'
import { OperationProduct } from '../../types/operationsTypes'
import sumBy from 'lodash.sumby'

const customPaths = {
  '/operations': 'Операции',
  '/operations/add/write_off': 'Списание',
  '/operations/add/write_off/bookings_done': 'Бронирования для списания'
}

export const BookingsDone: React.FC = () => {
  const navigate = useNavigate()
  const { setBookingsErrors, setBookingsDone, setOperationProducts, setBookingsDoneProducts } = useActions()
  const { bookingsDone, errors, products } = useTypeSelector((state) => state.bookings)
  const { productsInOperation, products: stateProducts } = useTypeSelector((state) => state.operations)

  const [selectedId, setSelectedId] = useState<string | null>(null)

  const handleAddSelected = () => {
    const productsInBookingsDone = bookingsDone
      .flatMap((b) => b.productsInBooking.concat(b.productsInOrder))
      .reduce<BookingDoneProduct[]>(
        (arr, p) => (p.selected ? [...arr, { ...p, count: p.write_off_left, write_off_left: 0 }] : arr),
        []
      )

    const groupedProducts = groupBy([...productsInOperation, ...productsInBookingsDone], 'id')

    const unionProducts = Object.entries(groupedProducts).reduce<OperationProduct[]>((arr, [id, group]) => {
      const count = sumBy(group, 'count')
      const bookingDoneCount = productsInBookingsDone.reduce((acc, item) => (item.id === id ? acc + item.count : acc + 0), 0)
      const product = products.find((p) => p.id === id)
      /*       console.log(product?.name, count, operationCount, count ? count - operationCount : 1) */
      return product ? [...arr, { ...product, count: bookingDoneCount || count }] : arr
    }, [])

    setOperationProducts(unionProducts)
    setBookingsDoneProducts(productsInBookingsDone)
    navigate(-1)
  }

  const handleClick = useDoubleClick(
    (id: string) => setSelectedId((prev) => (prev === id ? null : id)),
    () => undefined
  )

  const bookingDoneColumns: VgTableColumnType<BookingDone>[] = [
    {
      key: 'id',
      title: '',
      width: '4%',
      style: { paddingLeft: 22 },
      render: (_, { productsInOrder, productsInBooking, id }) => {
        const checked = productsInBooking.concat(productsInOrder).every((p) => p.selected)

        /*   const isExist = productsInOrder.every((p) => {
          const availableCount = stateProducts.find((item) => item.id === p.id)?.count || 0
          const operationProductCount = productsInOperation.find((item) => item.id === p.id)?.count || 0
          return p.count + operationProductCount <= availableCount
        }) */

        return (
          <Box sx={{ m: '-10px 0' }}>
            <Checkbox
              /* onClick={() => !isExist && setOrdersErrors({ ordersPosting: 'Необходимые на складе товары отсутствуют' })} */
              onChange={() =>
                setBookingsDone(
                  bookingsDone.map((o) =>
                    o.id === id
                      ? {
                          ...o,
                          productsInBooking: o.productsInBooking.map((p) => ({ ...p, selected: !checked })),
                          productsInOrder: o.productsInOrder.map((p) => ({ ...p, selected: !checked }))
                        }
                      : o
                  )
                )
              }
              value={id}
              checked={checked}
              /* shakeOnClick={!isExist}
              disabled={!isExist}
              error={!isExist} */
            />
          </Box>
        )
      }
    },
    {
      key: 'sku',
      title: 'Номер',
      width: '10%'
    },
    {
      key: 'status',
      title: 'Статус',
      render: (_, { status }) => <Status type={status} wordBreak />,
      width: '12%'
    },
    {
      key: 'user_firstname',
      title: 'Пользователь',
      width: '15%',
      render: (_, item) => (
        <Option
          text={`${item.user_lastname || ''} ${item.user_firstname}`.trim()}
          subText={item.user_company}
          fontWeight={500}
          variant="table"
        />
      )
    },
    {
      key: 'created_at',
      title: 'Дата создания',
      render: (_, item) => <StyledCell>{formatDate(item.created_at)}</StyledCell>,
      width: '12%'
    },
    {
      key: 'warehouse_name',
      title: 'Склад',
      width: '10%'
    },
    {
      key: 'bitrix_id',
      title: 'Id B24',
      render: (_, item) => <BitrixLink bitrixId={item.bitrix_id} />,
      width: '7%'
    },
    {
      key: 'updated_at',
      title: 'Дата готовности',
      width: '13%',
      render: (_, item) => <StyledCell variant="green"></StyledCell>
    },
    {
      key: 'comment',
      title: 'Комментарий',
      render: (_, { comment }) => <OperationComment>{comment}</OperationComment>,
      width: '17%'
    }
  ]

  return (
    <div className={styles.container}>
      <Breadcrumbs customPaths={customPaths} />
      <StyledHeader sx={{ mb: '40px' }}>
        <Typography variant="h1">Бронирования для списания</Typography>
        {!!bookingsDone.length && (
          <Button onClick={handleAddSelected} color="primary">
            Добавить
          </Button>
        )}
      </StyledHeader>
      {bookingsDone.length > 0 ? (
        <VgTable
          onRowClick={(booking) => handleClick(booking.id)}
          columns={bookingDoneColumns}
          data={bookingsDone}
          renderCollapsibleRow={(booking) => (booking.id === selectedId ? <BookingDoneRow booking={booking} /> : undefined)}
        />
      ) : (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 'calc(100vh - 400px)' }}>
          <Typography variant="h2" color="text.primary" sx={{ fontSize: 18, fontWeight: 500, lineHeight: '24px' }}>
            Бронирования для списания отсутствуют
          </Typography>
        </Box>
      )}
      <SnacbarModal
        isError
        isActions={false}
        onCancel={() => setBookingsErrors({})}
        open={!!errors?.bookingsDone}
        message={errors?.bookingsDone}
      />
    </div>
  )
}

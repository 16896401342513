import * as Yup from 'yup'

export const sectionSchema = Yup.object().shape({
  name: Yup.string().trim().required('Введите название раздела'),
  subsections: Yup.array().of(Yup.string())
})

export const sectionAccessSchema = Yup.object().shape({
  name: Yup.string().trim().required('Введите название раздела'),
  subsections: Yup.array().of(Yup.string()),
  users: Yup.array().of(Yup.object({ id: Yup.string(), firstname: Yup.string(), lastname: Yup.string().nullable() }))
})

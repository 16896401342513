import React from 'react'
import styles from './OperationsTab.module.css'
import clsx from 'clsx'

interface OperationsTabProps {
  children: React.ReactNode
  variant?: 'default' | 'orange'
  count: number
}

export const OperationsTab: React.FC<OperationsTabProps> = ({ children, count, variant = 'default' }) => {
  return (
    <div className={styles.tab}>
      <span className={styles.title}>{children}</span>
      <span className={clsx(styles.info, styles[variant])}>{count}</span>
    </div>
  )
}

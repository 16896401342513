import { ThunkAction } from 'redux-thunk'
import { RootState } from '..'
import AuthApi from '../../api/AuthApi'
import UserApi from '../../api/UserApi'
import {
  User,
  UserAction,
  UserActionTypes,
  SetAuthErrorAction,
  SetLogoutErrorAction,
  SetUserErrorsAction
} from '../../types/userTypes'
import { NavigateFunction } from 'react-router-dom'
import { ErrorMessages } from '../../types/errorTypes'
import { errorsHandling } from '../../infrasturcture/utils/errorsHandling'

type ThunkType = ThunkAction<void, RootState, unknown, UserAction>

export const setAuthError = (payload: string | null): SetAuthErrorAction => ({
  type: UserActionTypes.SET_AUTH_ERROR,
  payload
})

const setLogoutError = (payload: string | null): SetLogoutErrorAction => ({
  type: UserActionTypes.SET_LOGOUT_ERROR,
  payload
})

export const setUserErrors = (payload: ErrorMessages): SetUserErrorsAction => ({
  type: UserActionTypes.SET_USER_ERRORS,
  payload
})

export const authThunkCreator = (): ThunkType => {
  return async (dispatch) => {
    try {
      const data = await UserApi.getMe()
      dispatch({ type: UserActionTypes.AUTH, payload: data })
    } catch (e: any) {
      dispatch(setAuthError(e.response.data.messages))
    }
  }
}

export const loginThunkCreator = (email: string, password: string, navigate: NavigateFunction): ThunkType => {
  return async (dispatch) => {
    try {
      const data = await AuthApi.login(email, password)
      dispatch({ type: UserActionTypes.LOGIN, payload: data })
    } catch (e: any) {
      if (e.response.status === 503) return navigate('/503')
      dispatch(setUserErrors(errorsHandling(e.response)))
    }
  }
}

export const logoutThunkCreator = (): ThunkType => {
  return async (dispatch) => {
    try {
      await AuthApi.logout()
      dispatch({ type: UserActionTypes.LOGOUT })
    } catch (e: any) {
      dispatch(setLogoutError(e.response.data.message))
    }
  }
}

export const updateThunkCreator = (profile: User, cb: () => void, navigate: NavigateFunction): ThunkType => {
  return async (dispatch) => {
    try {
      const data = await UserApi.editMe(profile)
      dispatch({ type: UserActionTypes.UPDATE_PROFILE, payload: data })
      cb()
    } catch (e: any) {
      switch (e.response.status) {
        case 422:
          dispatch(setUserErrors(errorsHandling(e.response)))
          break
        case 503:
          navigate('/503')
          break
        case 401:
        case 403:
        default:
          dispatch(logoutThunkCreator())
          break
      }
    }
  }
}

export const changePasswordThunkCreator = (
  oldPassword: string,
  newPassword: string,
  cb: () => void,
  navigate: NavigateFunction
): ThunkType => {
  return async (dispatch) => {
    try {
      await UserApi.changePassword(oldPassword, newPassword)
      dispatch(setUserErrors({}))
      cb()
    } catch (e: any) {
      switch (e.response.status) {
        case 422:
          dispatch(setUserErrors(errorsHandling(e.response)))
          break
        case 503:
          navigate('/503')
          break
        case 401:
        case 403:
        default:
          dispatch(logoutThunkCreator())
          break
      }
    }
  }
}

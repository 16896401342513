import { Box, Button } from '@mui/material'
import React from 'react'
import { PlusIcon } from '../../../infrasturcture/icons'

export const options = [
  { label: 'Оприходовать', to: '/operations/add/posting' },
  { label: 'Списать', to: '/operations/add/write_off' },
  { label: 'Переместить', to: '/operations/add/moving' }
]

export const OperationsButton: React.FC = () => {
  return (
    <Box sx={{ display: 'flex', gap: '16px' }}>
      {options.map(({ label, to }) => (
        <Button key={to} href={to} color="primary" startIcon={<PlusIcon />}>
          {label}
        </Button>
      ))}
    </Box>
  )
}

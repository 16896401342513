import * as Yup from 'yup'

export const warehousesSchema = Yup.object().shape({
  name: Yup.string().trim().required('Заполните название склада').max(255, 'Превышен лимит символов'),
  region: Yup.string().trim().max(255, 'Превышен лимит символов').nullable(),
  address: Yup.string().max(255, 'Превышен лимит символов').nullable()
})

export const warehouseAccessSchema = Yup.object().shape({
  users: Yup.array().of(Yup.object({ id: Yup.string(), firstname: Yup.string(), lastname: Yup.string().nullable() }))
})

import { UserState, UserAction, UserActionTypes } from '../../types/userTypes'

const initialState: UserState = {
  user: null,
  isAuth: false,
  isLoaded: true,
  logoutError: null,
  authError: null,
  changePasswordError: null,
  errors: {}
}

export const userReducer = (state = initialState, action: UserAction): UserState => {
  switch (action.type) {
    case UserActionTypes.LOGIN:
      return {
        ...state,
        isLoaded: false,
        isAuth: true,
        errors: {},
        user: action.payload
      }
    case UserActionTypes.LOGOUT:
      return {
        ...state,
        isAuth: false,
        user: null
      }
    case UserActionTypes.AUTH:
      return {
        ...state,
        user: action.payload,
        isAuth: true,
        isLoaded: false,
        authError: null
      }
    case UserActionTypes.UPDATE_PROFILE:
      return {
        ...state,
        user: action.payload,
        isAuth: true,
        isLoaded: false,
        errors: {}
      }
    case UserActionTypes.SET_AUTH_ERROR:
      return {
        ...state,
        isLoaded: false,
        isAuth: false,
        user: null,
        authError: action.payload
      }
    case UserActionTypes.SET_LOGOUT_ERROR:
      return {
        ...state,
        isLoaded: false,
        isAuth: false,
        logoutError: action.payload
      }
    case UserActionTypes.SET_USER_ERRORS:
      return {
        ...state,
        errors: action.payload
      }
    default:
      return state
  }
}

import { instance } from '../infrasturcture/http'

export default class ReportsApi {
  static async getWarehouseReport(data: { warehouse_id: string; date: string }) {
    const response = await instance.get('reports/warehouse-report', {
      params: { warehouse_id: data.warehouse_id, date: data.date },
      responseType: 'blob'
    })
    return response.data
  }
}

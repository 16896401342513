import React from 'react'
import { useActions } from '../../hooks/useActions'
import { LogoutIcon } from '../../infrasturcture/icons'
import styles from './Sidebar.module.css'
import { StyledTooltip } from '../../infrasturcture/theme/styled'

interface LogoutButtonProps {
  isOpen: boolean
}

export const LogoutButton: React.FC<LogoutButtonProps> = ({ isOpen }) => {
  const { logoutThunkCreator } = useActions()

  return (
    <StyledTooltip title={!isOpen ? 'Выйти' : ''} placement="right" arrow>
      <li className={styles.item} onClick={logoutThunkCreator}>
        <div className={styles.link}>
          <LogoutIcon className={styles.linkIcon} />
          <span className={styles.linkText}>Выйти</span>
        </div>
      </li>
    </StyledTooltip>
  )
}

import { Box, Typography, styled } from '@mui/material'
import React from 'react'
import { CloseSmallIcon } from '../../infrasturcture/icons'

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  backgroundColor: '#FCFCFC',
  border: '1px solid #ABABAB52',
  borderRadius: 8,
  boxShadow: '-1px 2px 3px 0px #00000008',
  height: 46,
  lineHeight: 0,
  fill: '#4B4B4B'
}))

interface OptionInfoProps {
  text: string
  subText: string
  onClick: () => void
  disabled?: boolean
}

export const OptionInfo: React.FC<OptionInfoProps> = ({ text, subText, onClick, disabled = false }) => {
  return (
    <StyledBox sx={{ overflow: 'hidden' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', p: '8px 10px' }}>
        <Typography component="span" sx={{ fontSize: 12, fontWeight: 400, color: '#4B4B4B', lineHeight: '16px' }}>
          {text}
        </Typography>
        <Typography component="span" sx={{ fontSize: 10, fontWeight: 700, color: '#0C4F3F', lineHeight: '14px' }}>
          {subText}
        </Typography>
      </Box>
      {!disabled && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: 46,
            width: 32,
            borderLeft: '1px solid #ABABAB52',
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)'
            }
          }}
          onClick={onClick}
        >
          <CloseSmallIcon />
        </Box>
      )}
    </StyledBox>
  )
}

import React from 'react'
import { SubmitHandler, useFormContext } from 'react-hook-form'
import { Grid, Typography } from '@mui/material'
import { TempInput } from '../../../components/Input/TempInput'
import { useTypeSelector } from '../../../hooks/useTypeSelector'
import { useActions } from '../../../hooks/useActions'
import styles from '../../../styles/Form.module.css'

interface FormProps {
  onSubmit: SubmitHandler<any>
  type: 'create' | 'edit'
  disabled?: boolean
}

export const Form: React.FC<FormProps> = ({ onSubmit, disabled = false, type }) => {
  const { setManufacturersErrors } = useActions()
  const { errors: stateErrors } = useTypeSelector((state) => state.manufacturers)
  const isDisabled = type === 'edit' && disabled

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useFormContext()

  return (
    <div className={styles.wrapper}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.formItem}>
          <Typography variant="h2" color="text.primary">
            Общая информация
          </Typography>
          <Grid className={styles.fields} container spacing={3}>
            <Grid item xs={6}>
              <TempInput
                onChange={() => setManufacturersErrors({})}
                control={control}
                label={(stateErrors?.name || errors?.name?.message) ?? 'Название производителя'}
                placeholder="Название производителя"
                name="name"
                error={!!stateErrors?.name || !!errors?.name?.message}
                disabled={isDisabled}
                required
              />
            </Grid>
          </Grid>
        </div>
      </form>
    </div>
  )
}

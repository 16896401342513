/* eslint-disable no-unused-vars */
import { ErrorMessages } from './errorTypes'
import { Product } from './productsTypes'
import { Warehouse } from './warehousesTypes'

export enum operationTypes {
  POSTING = 'POSTING',
  WRITE_OFF = 'WRITE_OFF',
  MOVING = 'MOVING'
}

export enum purposeTypes {
  SALE = 'SALE',
  GIFT = 'GIFT',
  MARRIAGE = 'MARRIAGE',
  OTHER = 'OTHER'
}

export const operationTypesRus: { [key: string]: string } = {
  POSTING: 'Оприходование',
  WRITE_OFF: 'Списание',
  MOVING: 'Перемещение'
}

export const purposeTypesRus: { [key: string]: string } = {
  SALE: 'Продажа',
  GIFT: 'Подарок',
  MARRIAGE: 'Брак',
  OTHER: 'Другое'
}

export interface ProductInOperation extends Omit<Product, 'description' | 'name_1c'> {
  order_id: string | null
  order_sku: number | null
  count: number
  count_left: number | null
  write_off_left: number | null
}

export interface Operation {
  id: string
  sku: number
  type: operationTypes
  user_id: string
  user_firstname: string
  user_lastname: string | null
  user_company: string | null
  warehouse_id_from: string
  warehouse_id_to: string | null
  warehouse_name_from: string
  warehouse_name_to: string | null
  bitrix_id: string | null
  purpose: string | null
  comment: string | null
  created_at: Date
  updated_at: Date
  order_id?: string | null
  productsInOperation?: ProductInOperation[]
  productsInOrdersPosting?: ProductInOperation[]
}

export interface Manager {
  id: string
  firstname: string
  lastname: string | null
  role: string
  company: string | null
  full_name: string
}

export interface OperationProduct extends Product {
  count: number
}

export interface OperationFormValues {
  bitrix_id: string
  purpose: string
  comment: string
  warehouse_id_from: string
  warehouse_id_to: string
}

export interface OperationFilters {
  type: string | null
  managers: Manager[]
  warehouses: Warehouse[]
  products: Product[]
  startDate: Date | null
  endDate: Date | null
  sku: string | null
}

export interface OperationsState {
  operations: Operation[]
  operation: Operation | null
  formValues: OperationFormValues
  managers: Manager[]
  products: OperationProduct[]
  productsInOperation: OperationProduct[]
  limit: number
  count: number
  isLoaded: boolean
  filters: OperationFilters
  errors: ErrorMessages
}

export enum OperationsActionTypes {
  GET_OPERATIONS = 'GET_OPERATIONS',
  GET_OPERATION = 'GET_OPERATION',
  SET_OPERATION = 'SET_OPERATION',
  GET_MANAGERS = 'GET_MANAGERS',
  GET_OPERATION_PRODUCTS = 'GET_OPERATION_PRODUCTS',
  SET_OPERATION_PRODUCTS = 'SET_OPERATION_PRODUCTS',
  CLEAN_OPERATION_SEARCH_PRODUCTS = 'CLEAN_OPERATION_SEARCH_PRODUCTS',
  SET_OPERATIONS = 'SET_OPERATIONS',
  ADD_PRODUCT = 'ADD_PRODUCT',
  ADD_PRODUCTS_OPERATION = 'ADD_PRODUCTS_OPERATION',
  PLUS_PRODUCT = 'PLUS_PRODUCT',
  MINUS_PRODUCT = 'MINUS_PRODUCT',
  SET_OPERATION_LOADED = 'SET_OPERATION_LOADED',
  SET_OPERATION_FILTERS = 'SET_OPERATION_FILTERS',
  SET_OPERATION_FORM_VALUES = 'SET_OPERATION_FORM_VALUES',
  SET_OPERATIONS_ERRORS = 'SET_OPERATIONS_ERRORS'
}

export interface GetOperationsThunkCreator {
  type: OperationsActionTypes.GET_OPERATIONS
  payload: { count: number; rows: Operation[] }
}

export interface GetOperationThunkCreator {
  type: OperationsActionTypes.GET_OPERATION
  payload: Operation
}

export interface GetManagersThunkCreator {
  type: OperationsActionTypes.GET_MANAGERS
  payload: Manager[]
}

export interface GetProductsInWarehouseThunkCreator {
  type: OperationsActionTypes.GET_OPERATION_PRODUCTS
  payload: OperationProduct[]
}

export interface AddProduct {
  type: OperationsActionTypes.ADD_PRODUCT
  payload: OperationProduct
}

export interface AddProductOperation {
  type: OperationsActionTypes.ADD_PRODUCTS_OPERATION
  payload: OperationProduct[]
}

export interface SetOperationProductsAction {
  type: OperationsActionTypes.SET_OPERATION_PRODUCTS
  payload: OperationProduct[]
}

export interface PlusProduct {
  type: OperationsActionTypes.PLUS_PRODUCT
  payload: { id: string; count: number }
}

export interface MinusProduct {
  type: OperationsActionTypes.MINUS_PRODUCT
  payload: string
}

export interface CleanOperationSearchProducts {
  type: OperationsActionTypes.CLEAN_OPERATION_SEARCH_PRODUCTS
  payload: OperationProduct[]
}

export interface SetOperationsAction {
  type: OperationsActionTypes.SET_OPERATIONS
  payload: Operation[]
}

export interface SetOperation {
  type: OperationsActionTypes.SET_OPERATION
  payload: Operation | null
}

export interface SetOperationLoaded {
  type: OperationsActionTypes.SET_OPERATION_LOADED
  payload: boolean
}

export interface SetOperationFilters {
  type: OperationsActionTypes.SET_OPERATION_FILTERS
  payload: Partial<OperationFilters>
}

export interface SetOperationFormValues {
  type: OperationsActionTypes.SET_OPERATION_FORM_VALUES
  payload: Partial<OperationFormValues>
}

export interface SetOperationsErrorsAction {
  type: OperationsActionTypes.SET_OPERATIONS_ERRORS
  payload: ErrorMessages
}

export type OperationsAction =
  | GetOperationsThunkCreator
  | GetOperationThunkCreator
  | GetManagersThunkCreator
  | GetProductsInWarehouseThunkCreator
  | AddProduct
  | AddProductOperation
  | SetOperationProductsAction
  | PlusProduct
  | MinusProduct
  | CleanOperationSearchProducts
  | SetOperationsAction
  | SetOperationLoaded
  | SetOperation
  | SetOperationFilters
  | SetOperationFormValues
  | SetOperationsErrorsAction

import React from 'react'
import { useTypeSelector } from '../../../hooks/useTypeSelector'
import { OperationTitle } from '../../../components/Operations/OperationTitle'
import { VgTable } from '../../../components/VgTable/VgTable'
import styles from '../../../styles/OperationRow.module.css'
import { getBookingTableConfig } from '../../bookings/helpers/getBookingTableConfig'

const orderTooltipText = `
Автоматически созданная заявка для данного 
бронирования. Статус заявки совпадает со 
статусом бронирования
`

export const OrderRow: React.FC = () => {
  const { order } = useTypeSelector((state) => state.orders)
  const { orderProductsColumns } = getBookingTableConfig({ type: 'edit', operationType: 'order', disabled: true })

  return (
    <>
      {order && (
        <tr className={styles.row}>
          <td className={styles.cell} colSpan={8}>
            {!!order?.productsInOrder?.length && (
              <div className={styles.wrapper}>
                <OperationTitle tooltipText={orderTooltipText}>Товары в заявке</OperationTitle>
                <VgTable
                  classNameRow={styles.tableRow}
                  data={order.productsInOrder}
                  columns={orderProductsColumns}
                  strokeRow={(item) => item.count_left === 0}
                />
              </div>
            )}
          </td>
        </tr>
      )}
    </>
  )
}

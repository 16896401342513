import React, { ChangeEvent, useState } from 'react'
import { ProductFilters } from '../../../types/productsTypes'
import { useTypeSelector } from '../../../hooks/useTypeSelector'
import { useActions } from '../../../hooks/useActions'
import { Section } from '../../../types/sectionsTypes'
import { Filter } from '../../../components/Filter/Filter'
import { FilterSection } from '../../../components/Filter/FilterSection'
import { Checkbox } from '../../../components/Checkbox/Checkbox'
import { FilterList } from '../../../components/Filter/FilterList'
import { LabelSelected } from '../../../components/Label/LabelSelected'

const initialFilters: ProductFilters = {
  section: null,
  subsections: [],
  custom: false
}

export const ProductsFilter: React.FC = () => {
  const { setProductFilters } = useActions()
  const stateFilters = useTypeSelector((state) => state.products.filters)
  const { sections } = useTypeSelector((state) => state.sections)
  const [filters, setFilters] = useState<ProductFilters>(initialFilters)

  const handleSectionChange = (section: Section) => (e: ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked
    setFilters((prev) => ({ ...prev, section: checked ? section : null }))
  }

  const handleSubsectionChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = e.target
    setFilters((prev) => ({
      ...prev,
      subsections: checked ? [...prev.subsections, value] : prev.subsections.filter((v) => v !== value)
    }))
  }

  const handleDeleteSection = () => {
    setProductFilters({ section: null, subsections: [] })
    setFilters((prev) => ({ ...prev, section: null, subsections: [] }))
  }

  const handleDeleteSubsection = (subsection: string) => {
    const subsections = filters.subsections.filter((s) => s !== subsection)
    setProductFilters({ subsections })
    setFilters((prev) => ({ ...prev, subsections }))
  }

  const handleDeleteCustom = () => {
    setProductFilters({ custom: false })
    setFilters((prev) => ({ ...prev, custom: false }))
  }

  const handleToggle = () => setFilters(stateFilters)

  const handleApply = () => {
    filters && setProductFilters(filters)
  }

  const handleReset = () => {
    setProductFilters(initialFilters)
    setFilters(initialFilters)
  }

  return (
    <Filter
      onApply={handleApply}
      onReset={handleReset}
      onToggle={handleToggle}
      selectedComponent={
        <>
          {stateFilters.section && (
            <>
              <LabelSelected text={stateFilters.section.name} onClick={handleDeleteSection} />
              {stateFilters.subsections.map((s, i) => (
                <LabelSelected key={i} text={s} onClick={() => handleDeleteSubsection(s)} />
              ))}
            </>
          )}
          {stateFilters.custom && <LabelSelected text="Заказной" onClick={handleDeleteCustom} />}
        </>
      }
    >
      <FilterSection title="Раздел">
        {sections?.slice(0, 5)?.map((section) => (
          <Checkbox
            onChange={handleSectionChange(section)}
            key={section.id}
            label={section.name}
            value={section.id}
            checked={section.id === filters.section?.id}
            labelStyle={{ marginBottom: -4 }}
          />
        ))}
        {sections?.length > 5 && (
          <FilterList
            values={sections}
            searchKey="name"
            render={(section) => (
              <Checkbox
                onChange={handleSectionChange(section)}
                label={section.name}
                value={section.name}
                checked={section.id === filters.section?.id}
                labelStyle={{ marginBottom: -4 }}
              />
            )}
          />
        )}
      </FilterSection>
      {!!filters?.section?.subsections?.length && (
        <FilterSection title="Подраздел">
          {filters.section.subsections.slice(0, 5)?.map((subsection, i) => (
            <Checkbox
              onChange={handleSubsectionChange}
              key={i}
              label={subsection}
              value={subsection}
              checked={filters.subsections.includes(subsection)}
              labelStyle={{ marginBottom: -4 }}
            />
          ))}
          {filters.section.subsections.length > 5 && (
            <FilterList
              values={filters.section.subsections}
              render={(subsection) => (
                <Checkbox
                  onChange={handleSubsectionChange}
                  label={subsection}
                  value={subsection}
                  checked={filters.subsections.includes(subsection)}
                  labelStyle={{ marginBottom: -4 }}
                />
              )}
            />
          )}
        </FilterSection>
      )}
      <FilterSection title="Дополнительно">
        <Checkbox
          label="Заказной"
          onChange={() => setFilters((prev) => ({ ...prev, custom: !prev.custom }))}
          checked={filters.custom}
        />
      </FilterSection>
    </Filter>
  )
}

import React, { PropsWithChildren } from 'react'
import { LabelRow } from '../Label/LabelRow'
import { Button } from '@mui/material'
import { PlusIcon } from '../../infrasturcture/icons'

interface FilterSelectedProps extends PropsWithChildren<unknown> {
  onOpen: () => void
}

export const FilterSelected: React.FC<FilterSelectedProps> = ({ onOpen, children }) => {
  return (
    <LabelRow style={{ marginTop: 12 }}>
      {children}
      <Button
        onClick={onOpen}
        color="primary"
        startIcon={<PlusIcon />}
        sx={{
          height: '32px',
          p: '8px 10px',
          fontWeight: 400,
          '& .MuiButton-startIcon': { width: '8px', mr: '8px' }
        }}
      >
        Добавить фильтр
      </Button>
    </LabelRow>
  )
}

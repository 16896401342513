/* eslint-disable no-unused-vars */

import { ErrorMessages } from './errorTypes'

export enum roles {
  ADMINISTRATOR = 'ADMINISTRATOR',
  SUPER_MANAGER = 'SUPER_MANAGER',
  DEALER = 'DEALER',
  MANAGER = 'MANAGER',
  DRIVER = 'DRIVER'
}

export interface User {
  email: string
  firstname: string
  lastname: string | null
  phone: string | null
  address: string | null
  site: string | null
  company: string | null
  company_details: string | null
  role: roles
  created_at?: Date
  updated_at?: Date
}

export const rolesRus: { [key: string]: string } = {
  ADMINISTRATOR: 'Администратор',
  SUPER_MANAGER: 'Супер менеджер',
  DEALER: 'Дилер',
  MANAGER: 'Менеджер',
  DRIVER: 'Водитель'
}

export interface UserState {
  user: User | any
  isAuth: boolean
  logoutError: string | null
  authError: string | null
  changePasswordError: string | null
  errors: ErrorMessages
  isLoaded: boolean
}

export enum UserActionTypes {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  AUTH = 'AUTH',
  UPDATE_PROFILE = 'UPDATE_PROFILE',
  SET_USER_ERRORS = 'SET_USER_ERRORS',
  SET_LOGOUT_ERROR = 'SET_LOGOUT_ERROR',
  SET_AUTH_ERROR = 'SET_AUTH_ERROR',
  SET_LOADED = 'SET_LOADED'
}

export interface LoginUserThunkCreator {
  type: UserActionTypes.LOGIN
  payload: User
}

export interface LogoutUserThunkCreator {
  type: UserActionTypes.LOGOUT
}

export interface AuthUserThunkCreator {
  type: UserActionTypes.AUTH
  payload: User
}

export interface UpdateProfileThunkCreator {
  type: UserActionTypes.UPDATE_PROFILE
  payload: User
}

export interface SetUserErrorsAction {
  type: UserActionTypes.SET_USER_ERRORS
  payload: ErrorMessages
}

export interface SetAuthErrorAction {
  type: UserActionTypes.SET_AUTH_ERROR
  payload: string | null
}

export interface SetLogoutErrorAction {
  type: UserActionTypes.SET_LOGOUT_ERROR
  payload: string | null
}

export type UserAction =
  | LoginUserThunkCreator
  | AuthUserThunkCreator
  | LogoutUserThunkCreator
  | UpdateProfileThunkCreator
  | SetLogoutErrorAction
  | SetAuthErrorAction
  | SetUserErrorsAction

import React, { PropsWithChildren } from 'react'
import styles from './Label.module.css'

interface LabelRowProps extends PropsWithChildren<unknown> {
  style?: React.CSSProperties
}

export const LabelRow: React.FC<LabelRowProps> = ({ children, style }) => {
  return (
    <div className={styles.row} style={style}>
      {children}
    </div>
  )
}

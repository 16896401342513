import { Box, TextField, styled } from '@mui/material'
import React from 'react'
import { Control, Controller } from 'react-hook-form'

const Counter = styled(Box, { shouldForwardProp: (prop) => !['disabled', 'error'].includes(prop as string) })<{
  disabled: boolean
  error: boolean
}>(({ theme, disabled, error }) => ({
  position: 'absolute',
  display: disabled ? 'none' : 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: 30,
  top: 6,
  right: 12,
  color: theme.palette[error ? 'error' : 'secondary'].main,
  fontWeight: 400,
  fontSize: 8,
  lineHeight: '12px',
  pointerEvents: 'none'
}))

interface TextareaProps {
  control: Control<any>
  name: string
  label: string
  placeholder?: string
  fullWidth?: boolean
  error?: boolean
  rows?: number
  maxRows?: number
  disabled?: boolean
  required?: boolean
}

export const Textarea: React.FC<TextareaProps> = ({
  control,
  name,
  label,
  placeholder,
  fullWidth = true,
  error = false,
  maxRows,
  rows,
  disabled = false,
  required = false
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field }) => (
        <TextField
          {...field}
          value={field.value ?? ''}
          label={label}
          placeholder={placeholder}
          variant="outlined"
          fullWidth={fullWidth}
          error={error}
          multiline
          rows={rows}
          maxRows={maxRows}
          disabled={disabled}
          required={required}
          InputProps={{
            endAdornment: (
              <Counter disabled={disabled} error={error}>
                <span>{field.value?.length || 0}</span>
                <span>/</span>
                <span>255</span>
              </Counter>
            )
          }}
        />
      )}
    />
  )
}

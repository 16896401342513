import React, { useEffect } from 'react'
import { Button, Typography } from '@mui/material'
import { Breadcrumbs } from '../../components/Breadcrumbs/Breadcrumbs'
import { SaveIcon } from '../../infrasturcture/icons'
import { StyledHeader } from '../../infrasturcture/theme/styled'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useActions } from '../../hooks/useActions'
import { BookingForm } from './ui/BookingForm'
import { BookingProducts } from './ui/BookingProducts'
import styles from '../../styles/Operations.module.css'
import { SnacbarModal } from '../../components/MuiComponents/SnacbarModal/SnacbarModal'
import { useTypeSelector } from '../../hooks/useTypeSelector'
import { statusTypes } from '../../types/bookingsTypes'
import { useNavigate } from 'react-router-dom'
import { createOrderSchema } from '../../infrasturcture/yupSchemes/createOrderSchema'

export const CreateBooking: React.FC = () => {
  const navigate = useNavigate()
  const { countErrors, productsInBooking, products, errors } = useTypeSelector((state) => state.bookings)
  const { productsInOrder } = useTypeSelector((state) => state.orders)
  const { filterWarehouses } = useTypeSelector((state) => state.warehouses)
  const {
    getFilterWarehousesThunkCreator,
    createBookingThunkCreator,
    setBookingsCountErrors,
    setBookingProducts,
    setBookingSearchProducts,
    addOrderProduct,
    setOrderProducts,
    setBookingsErrors
  } = useActions()

  const methods = useForm({ resolver: yupResolver(createOrderSchema), mode: 'onChange' })

  const onSubmit = (data: any) => {
    const warehouse = filterWarehouses.find((w) => w.id === data.warehouse_id)

    const booking = {
      status: statusTypes.CONFIRMATION,
      type: 'BOOKING',
      warehouse_id: data.warehouse_id,
      booking_warehouse_id: warehouse?.booking_warehouse_id,
      warehouse_name: warehouse?.name,
      bitrix_id: data.bitrix_id || null,
      comment: data.comment || null,
      productsInBooking: productsInBooking.map((p) => ({ product_id: p.id, count: p.count, write_off_left: p.write_off_left })),
      productsInOrder: productsInOrder.map((p) => ({
        product_id: p.id,
        count: p.count,
        count_left: p.count_left,
        write_off_left: p.write_off_left
      }))
    }

    return createBookingThunkCreator(
      booking,
      () => {
        setBookingProducts([])
        setBookingSearchProducts([])
        setBookingsErrors({})
        setBookingsCountErrors(null)
        setOrderProducts([])
        navigate('/bookings')
      },
      navigate
    )
  }

  const handleAddOrderProduct = () => {
    const isExist = productsInOrder.find((p) => p.id === countErrors?.id)
    if (!isExist) {
      const product = products.find((p) => p.id === countErrors?.id)!
      addOrderProduct({ ...product, count: 1, count_left: 1, write_off_left: 1 })
    }
    setBookingsCountErrors(null)
  }

  useEffect(() => {
    setBookingProducts([])
    setOrderProducts([])
    getFilterWarehousesThunkCreator()
  }, [])

  return (
    <div className={styles.container}>
      <Breadcrumbs />
      <StyledHeader>
        <Typography variant="h1">Новое бронирование</Typography>
        <Button
          onClick={methods.handleSubmit(onSubmit)}
          disabled={methods.formState.isSubmitting}
          color="primary"
          startIcon={<SaveIcon />}
        >
          Сохранить
        </Button>
      </StyledHeader>
      <FormProvider {...methods}>
        <BookingForm type="create" onSubmit={onSubmit} />
      </FormProvider>
      <BookingProducts type="create" />
      <SnacbarModal
        onAccept={handleAddOrderProduct}
        onCancel={() => setBookingsCountErrors(null)}
        open={!!countErrors}
        message={countErrors?.message}
      />
      <SnacbarModal
        isError
        isActions={false}
        onCancel={() => setBookingsErrors({})}
        open={!!errors?.productsInBooking}
        message={errors?.productsInBooking}
      />
    </div>
  )
}

import React from 'react'
import { Box } from '@mui/material'
import { OperationTitle } from '../../../components/Operations/OperationTitle'
import { VgTable } from '../../../components/VgTable/VgTable'
import { useTypeSelector } from '../../../hooks/useTypeSelector'
import { StyledLinkRouter } from '../../../infrasturcture/theme/styled'
import { getBookingTableConfig } from '../helpers/getBookingTableConfig'

const orderTooltipText = `
Автоматически созданная заявка для данного 
бронирования. Статус заявки совпадает со 
статусом бронирования
`

interface BookingProductsProps {
  type: 'create' | 'edit'
  disabled?: boolean
}

export const BookingProducts: React.FC<BookingProductsProps> = ({ type, disabled = false }) => {
  const { productsInBooking, booking } = useTypeSelector((state) => state.bookings)
  const { productsInOrder } = useTypeSelector((state) => state.orders)
  const { bookingProductsColumns, orderProductsColumns } = getBookingTableConfig({ type, operationType: 'booking', disabled })

  return (
    <Box>
      {!!productsInBooking.length && (
        <Box>
          <OperationTitle variant="large">Товары в бронировании</OperationTitle>
          <VgTable data={productsInBooking} columns={bookingProductsColumns} strokeRow={(item) => item.write_off_left === 0} />
        </Box>
      )}
      {!!productsInOrder.length && (
        <Box sx={{ mt: '40px' }}>
          {type === 'create' ? (
            <OperationTitle variant="large">Товары в заявке</OperationTitle>
          ) : (
            <OperationTitle variant="large" tooltipText={orderTooltipText} isShowTooltip>
              Товары в заявке&nbsp;
              <StyledLinkRouter target="_blank" to={`/orders/${booking?.sku}`} sx={{ fontSize: '18px' }}>
                №{booking?.sku}
              </StyledLinkRouter>
            </OperationTitle>
          )}
          <VgTable data={productsInOrder} columns={orderProductsColumns} strokeRow={(item) => item.write_off_left === 0} />
        </Box>
      )}
    </Box>
  )
}

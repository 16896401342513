import { Grid, SxProps, styled } from '@mui/material'

export const style: { [key: string]: SxProps } = {
  container: {
    '@media screen and (max-width: 900px)': {
      position: 'relative',
      overflowX: 'hidden'
    }
  },
  userInfo: { position: 'absolute', textAlign: 'right', right: 40 },
  username: {
    fontWeight: 500,
    fontSize: 14,
    color: '#4B4B4B',
    lineHeight: '18px'
  },
  userRole: {
    fontWeight: 400,
    fontSize: 12,
    color: '#ABABAB',
    lineHeight: '16px'
  }
}

export const Content = styled(Grid, { shouldForwardProp: (prop) => prop !== 'isMenuOpen' })<{ isMenuOpen: boolean }>(
  ({ theme, isMenuOpen }) => ({
    position: 'relative',
    flex: 1,
    height: '100vh',
    marginLeft: isMenuOpen ? 244 : 88,
    padding: '48px 40px',
    overflowX: 'auto',
    transition: 'margin 225ms ease-in-out',
    '@media screen and (max-width: 900px)': {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100dvh',
      margin: 0,
      transition: ' all 0.3s'
    }
  })
)

/* eslint-disable no-unused-vars */

import { ErrorMessages } from './errorTypes'
import { roles } from './userTypes'

export interface Users {
  id: string
  email: string
  firstname: string
  lastname: string | null
  company: string | null
  phone: string | null
  role: roles
  sections: string[]
  warehouses: string[]
}

export type CreateUserFields = Omit<Users, 'id'>

export type EditUserFields = Users

export interface UsersState {
  users: Users[]
  user: Users | null
  isLoaded: boolean
  errors: ErrorMessages
}

export enum UsersActionTypes {
  GET_USERS = 'GET_USERS',
  GET_USER = 'GET_USER',
  SET_LOADED = 'SET_LOADED',
  SET_USER = 'SET_USER',
  SET_USERS_ERRORS = 'SET_USERS_ERRORS',
  UPDATE_USER = 'UPDATE_USER'
}

export interface GetUsersThunkCreator {
  type: UsersActionTypes.GET_USERS
  payload: Users[]
}

export interface GetUserThunkCreator {
  type: UsersActionTypes.GET_USER
  payload: Users | null
}

export interface SetUserAction {
  type: UsersActionTypes.SET_USER
  payload: Users | null
}

export interface UpdateUserThunkCreator {
  type: UsersActionTypes.UPDATE_USER
  payload: Users
}

export interface SetLoaded {
  type: UsersActionTypes.SET_LOADED
  payload: boolean
}

export interface SetUsersErrorsAction {
  type: UsersActionTypes.SET_USERS_ERRORS
  payload: ErrorMessages
}

export type UsersAction =
  | GetUsersThunkCreator
  | GetUserThunkCreator
  | SetLoaded
  | SetUserAction
  | SetUsersErrorsAction
  | UpdateUserThunkCreator

import { ThunkAction } from 'redux-thunk'
import { RootState } from '..'
import WarehousesApi from '../../api/WarehousesApi'
import { SetAuthErrorAction } from '../../types/userTypes'
import {
  SetFilterWarehousesAction,
  SetWarehouseAction,
  SetWarehousesAction,
  SetWarehousesErrorsAction,
  SetWarehousesLoaded,
  Warehouse,
  WarehousesAction,
  WarehousesActionTypes
} from '../../types/warehousesTypes'
import { logoutThunkCreator, setAuthError } from './userActions'
import { NavigateFunction } from 'react-router-dom'
import { ErrorMessages } from '../../types/errorTypes'
import { errorsHandling } from '../../infrasturcture/utils/errorsHandling'

type ThunkType = ThunkAction<void, RootState, unknown, WarehousesAction | SetAuthErrorAction>

const setWarehousesLoaded = (payload: boolean): SetWarehousesLoaded => ({
  type: WarehousesActionTypes.SET_WAREHOUSES_LOADED,
  payload
})

export const setWarehouses = (payload: Warehouse[]): SetWarehousesAction => ({
  type: WarehousesActionTypes.SET_WAREHOUSES,
  payload
})

export const setWarehouse = (payload: Warehouse | null): SetWarehouseAction => ({
  type: WarehousesActionTypes.SET_WAREHOUSE,
  payload
})

export const setFilterWarehousesAction = (payload: Warehouse[]): SetFilterWarehousesAction => ({
  type: WarehousesActionTypes.SET_FILTER_WAREHOUSES,
  payload
})

export const setWarehousesErrors = (payload: ErrorMessages): SetWarehousesErrorsAction => ({
  type: WarehousesActionTypes.SET_WAREHOUSES_ERRORS,
  payload
})

export const getWarehousesThunkCreator = ({
  isBooking = null,
  isRegion = null
}: {
  isBooking?: boolean | null
  isRegion?: boolean | null
}): ThunkType => {
  return async (dispatch) => {
    try {
      dispatch(setWarehousesLoaded(true))
      const data = await WarehousesApi.getWarehouses({ isBooking, isRegion })
      dispatch({
        type: WarehousesActionTypes.GET_WAREHOUSES,
        payload: data
      })
    } catch (e: any) {
      dispatch(logoutThunkCreator())
    }
  }
}

export const getWarehouseThunkCreator = (id: string, navigate: NavigateFunction): ThunkType => {
  return async (dispatch) => {
    try {
      dispatch(setWarehousesLoaded(true))
      const data = await WarehousesApi.getOne(id)
      dispatch({ type: WarehousesActionTypes.GET_WAREHOUSE, payload: data })
    } catch (e: any) {
      if ([403, 404, 503].includes(e.response.status)) {
        return navigate(`/${e.response.status}`)
      }
      dispatch(logoutThunkCreator())
    }
  }
}

export const getFilterWarehousesThunkCreator = (): ThunkType => {
  return async (dispatch) => {
    try {
      const data = await WarehousesApi.getWarehouses({ isBooking: false, isRegion: true })
      dispatch({
        type: WarehousesActionTypes.GET_FILTER_WAREHOUSES,
        payload: data
      })
    } catch (e: any) {
      dispatch(setAuthError(e.response.data.messages))
    }
  }
}

export const createWarehouseThunkCreator = (warehouse: Warehouse, cb: () => void, navigate: NavigateFunction): ThunkType => {
  return async (dispatch) => {
    try {
      await WarehousesApi.createWarehouse(warehouse)
      cb()
    } catch (e: any) {
      if (e.response.status === 422) return dispatch(setWarehousesErrors(errorsHandling(e.response)))
      if ([403, 404, 503].includes(e.response.status)) return navigate(`/${e.response.status}`)
      dispatch(setAuthError(e.response.data.messages))
    }
  }
}

export const updateWarehouseAccessThunkCreator = (
  access: { warehouseId: string; customers: string[] },
  cb: () => void
): ThunkType => {
  return async (dispatch) => {
    try {
      await WarehousesApi.updateAccess(access.warehouseId, access.customers)
      cb()
    } catch (e: any) {
      dispatch(logoutThunkCreator())
    }
  }
}

export const deleteWarehouseThunkCreator = (id: string, cb: () => void, navigate: NavigateFunction): ThunkType => {
  return async (dispatch) => {
    try {
      await WarehousesApi.deleteWarehouse(id)
      cb()
    } catch (e: any) {
      if (e.response.status === 422) return dispatch(setWarehousesErrors(errorsHandling(e.response)))
      if ([403, 404, 503].includes(e.response.status)) return navigate(`/${e.response.status}`)
      dispatch(logoutThunkCreator())
    }
  }
}

export const updateWarehouseOrderThunkCreator = (warehouse: { id: string; old: number; new: number }): ThunkType => {
  return async (dispatch) => {
    try {
      await WarehousesApi.updateWarehousesOrder(warehouse)
    } catch (e: any) {
      dispatch(setAuthError(e.response.data.messages))
    }
  }
}

export const editWarehouseThunkCreator = (warehouse: Warehouse, cb: () => void, navigate: NavigateFunction): ThunkType => {
  return async (dispatch) => {
    try {
      await WarehousesApi.editWarehouse(warehouse)
      dispatch({ type: WarehousesActionTypes.UPDATE_WAREHOUSE, payload: warehouse })
      cb()
    } catch (e: any) {
      if (e.response.status === 422) return dispatch(setWarehousesErrors(errorsHandling(e.response)))
      if ([403, 404, 503].includes(e.response.status)) return navigate(`/${e.response.status}`)
      dispatch(logoutThunkCreator())
    }
  }
}

import { formatISO } from 'date-fns'
import { instance } from '../infrasturcture/http'
import { getQueryString } from '../infrasturcture/utils/getQueryString'
import { Booking } from '../types/bookingsTypes'
import { OrderFilters } from '../types/ordersTypes'

export default class BookingsApi {
  static async getBookings(
    limit = 50,
    page = 1,
    { status, managers, products, warehouses, startDate, endDate, sku }: OrderFilters
  ) {
    const response = await instance.get('bookings', {
      params: {
        limit,
        page,
        status,
        managers: getQueryString(managers, 'id') || null,
        products: getQueryString(products, 'id') || null,
        warehouses: getQueryString(warehouses, 'id') || null,
        date: startDate ? `${formatISO(startDate)},${endDate ? formatISO(endDate) : formatISO(startDate)}` : null,
        sku: sku || null
      }
    })
    return response.data.data
  }

  static async getBooking(id: string) {
    const response = await instance.get(`bookings/${id}`)
    return response.data.data
  }

  static async getBookingDone(warehouseId: string) {
    const response = await instance.get('bookings-done', { params: { warehouseId } })
    return response.data.data
  }

  static async createBooking(booking: any) {
    const response = await instance.post('bookings', booking)
    return response.data.data
  }

  static async editBooking(booking: Booking) {
    const response = await instance.post(`bookings/${booking.id}`, booking)
    return response.data.data
  }

  static async getProducts(id: string) {
    const response = await instance.get(`operations-booking/products/${id}`)
    return response.data.data
  }
}

import React, { useState } from 'react'
import { useTypeSelector } from '../../hooks/useTypeSelector'
import { Route, routes } from '../../infrasturcture/routes/routes'
import { ReactComponent as BasaltText } from '../../assets/images/new/basalt-text.svg'
import { SidebarLink } from './SidebarLink'
import { CollapseButton } from './CollapseButton'
import { LogoutButton } from './LogoutButton'
import styles from './Sidebar.module.css'
import clsx from 'clsx'
import { useLocation, NavLink } from 'react-router-dom'
import { NestedLink } from './NestedLink'

interface SidebarProps {
  isOpen: boolean
  toggleIsOpen: () => void
}

export const Sidebar = React.memo<SidebarProps>(({ isOpen, toggleIsOpen }) => {
  const { pathname } = useLocation()
  const { user } = useTypeSelector((state) => state.user)
  const [nested, setNested] = useState<Route | null>(null)

  const handleClick = (e: React.MouseEvent, route: Route) => {
    if (Array.isArray(route.childrens) && (pathname === route.path || pathname.startsWith(route.path))) {
      e.preventDefault()
      return setNested(route.path !== nested?.path ? route : null)
    }
    setNested(null)
  }

  return (
    <div className={clsx(styles.container, { [styles.collapse]: !isOpen, [styles.nested]: !!nested })}>
      <div className={styles.header}>
        <BasaltText />
      </div>
      <nav className={styles.nav}>
        <ul className={styles.links}>
          {routes.map(
            (item, i) =>
              item.isDisplay &&
              item.roles.includes(user?.role) && <SidebarLink key={i} route={item} isOpen={isOpen} onClick={handleClick} />
          )}
          <LogoutButton isOpen={isOpen} />
          <CollapseButton isOpen={isOpen} onClick={toggleIsOpen} />
        </ul>
      </nav>
      <div className={clsx(styles.nestedMenu, { [styles.open]: !!nested })}>
        <div className={styles.nestedMenuWrapper}>
          {!!nested && (
            <>
              <div className={styles.nestedMenuHeader}>
                {nested?.icon && <nested.icon className={styles.linkIcon} />}
                <span className={styles.nestedLinkText}>{nested?.label}</span>
              </div>
              <ul className={styles.links}>
                <li className={styles.item}>
                  <NavLink
                    onClick={() => setNested(null)}
                    className={clsx(styles.nestedLink, { [styles.active]: nested.path === pathname })}
                    to={nested.path}
                  >
                    <span className={styles.nestedLinkText}>{nested.nestedLabel}</span>
                  </NavLink>
                </li>
                {nested.childrens?.map(
                  (item, i) =>
                    item.roles.includes(user?.role) && <NestedLink key={i} onClick={() => setNested(null)} route={item} />
                )}
              </ul>
            </>
          )}
        </div>
      </div>
    </div>
  )
})

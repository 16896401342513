import React, { useId } from 'react'
import { Control, Controller } from 'react-hook-form'
import { StyledBoxIcon, StyledFormControl } from '../../infrasturcture/theme/styled'
import { InputLabel, OutlinedInput, TextField } from '@mui/material'

type TempInputProps = {
  control: Control<any>
  name: string
  label: string
  placeholder?: string
  size?: 'small' | 'medium'
  icon?: React.ReactNode
  symbol?: string
  type?: React.HTMLInputTypeAttribute
  fullWidth?: boolean
  error?: boolean
  className?: string
  disabled?: boolean
  required?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  autoComplete?: string
}

export const TempInput: React.FC<TempInputProps> = ({
  control,
  name,
  label,
  placeholder,
  size = 'small',
  icon,
  symbol,
  type = 'text',
  fullWidth = true,
  error = false,
  className,
  disabled = false,
  required = false,
  onChange,
  autoComplete
}) => {
  const id = useId()

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field }) =>
        size === 'medium' ? (
          <StyledFormControl className={className} variant="outlined" fullWidth={fullWidth} error={error}>
            <StyledBoxIcon error={error}>{icon}</StyledBoxIcon>
            <InputLabel
              htmlFor={id}
              sx={{
                ...(size === 'medium' && {
                  transform: 'translate(70px, 24px) scale(1)',
                  '&.MuiInputLabel-shrink': { transform: 'translate(30px, -9px) scale(0.8)' }
                })
              }}
              {...(error ? { shrink: true } : {})}
            >
              {label}
            </InputLabel>
            <OutlinedInput
              id={id}
              type={type}
              label={label}
              placeholder={placeholder}
              sx={{
                ...(size === 'medium' && {
                  height: 68,
                  '& .MuiOutlinedInput-notchedOutline': {
                    padding: '0 24px'
                  }
                }),
                '& .MuiOutlinedInput-input': { paddingLeft: '70px', paddingRight: '70px' }
              }}
              {...field}
              onChange={(e) => {
                field.onChange(e)
                onChange?.(e)
              }}
              value={field.value ?? ''}
              error={error}
              {...(error ? { notched: true } : {})}
              disabled={disabled}
              required={required}
              autoComplete={autoComplete}
            />
          </StyledFormControl>
        ) : (
          <TextField
            {...field}
            onChange={(e) => {
              field.onChange(e)
              onChange?.(e)
            }}
            value={field.value ?? ''}
            type={type}
            label={label}
            placeholder={placeholder}
            variant="outlined"
            fullWidth={fullWidth}
            error={error}
            InputProps={{
              endAdornment: <span style={{ fontWeight: 500 }}>{symbol}</span>
            }}
            InputLabelProps={error ? { shrink: true } : {}}
            disabled={disabled}
            required={required}
            autoComplete={autoComplete}
          />
        )
      }
    />
  )
}

import React from 'react'
import { Button, Typography } from '@mui/material'
import { Breadcrumbs } from '../../components/Breadcrumbs/Breadcrumbs'
import { StyledHeader } from '../../infrasturcture/theme/styled'
import { PlusIcon } from '../../infrasturcture/icons'
import { BookingsContent } from './ui/BookingsContent'
import styles from '../../styles/Operations.module.css'

export const Bookings: React.FC = () => {
  return (
    <div className={styles.container}>
      <Breadcrumbs />
      <StyledHeader sx={{ mb: '40px' }}>
        <Typography variant="h1">Бронирования</Typography>
        <Button href="/bookings/create" color="primary" startIcon={<PlusIcon />}>
          Добавить бронирование
        </Button>
      </StyledHeader>
      <BookingsContent />
    </div>
  )
}

import React, { useEffect, useState } from 'react'
import { User } from '../../types/userTypes'
import { useTypeSelector } from '../../hooks/useTypeSelector'
import { SubmitHandler, useForm } from 'react-hook-form'
import { PhoneInput } from '../../components/PhoneInput/PhoneInput'
import { useActions } from '../../hooks/useActions'
import { yupResolver } from '@hookform/resolvers/yup'
import { profileSchema } from '../../infrasturcture/yupSchemes/profileSchema'
import { useNavigate } from 'react-router-dom'
import { Breadcrumbs } from '../../components/Breadcrumbs/Breadcrumbs'
import { TempInput } from '../../components/Input/TempInput'
import { Button, Grid, IconButton, Typography } from '@mui/material'
import { PasswordIcon, PenIcon, SaveIcon } from '../../infrasturcture/icons'
import { Textarea } from '../../components/Input/Textarea'
import { StyledHeader } from '../../infrasturcture/theme/styled'
import styles from '../../styles/Form.module.css'

export const Profile: React.FC = () => {
  const navigate = useNavigate()
  const { user, errors: stateErrors } = useTypeSelector((state) => state.user)
  const { updateThunkCreator, authThunkCreator } = useActions()
  const [isEdit, setIsEdit] = useState<boolean>(false)

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<User>({
    resolver: yupResolver(profileSchema),
    defaultValues: user || {},
    mode: 'onChange'
  })

  const onSubmit: SubmitHandler<User> = (data) => {
    return updateThunkCreator({ ...data, phone: data?.phone?.replace(/[^\d]/g, '') || null }, () => setIsEdit(false), navigate)
  }

  useEffect(() => {
    authThunkCreator()
  }, [])

  return (
    <div className={styles.container}>
      <Breadcrumbs />
      <StyledHeader>
        <Typography variant="h1">Профиль</Typography>
        {isEdit ? (
          <Button onClick={handleSubmit(onSubmit)} color="primary" startIcon={<SaveIcon />}>
            Сохранить
          </Button>
        ) : (
          <IconButton color="inherit" onClick={() => setIsEdit(true)}>
            <PenIcon />
          </IconButton>
        )}
      </StyledHeader>
      <div className={styles.wrapper}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.formItem}>
            <Typography variant="h2" color="text.primary">
              Личные данные
            </Typography>
            <Grid className={styles.fields} container spacing={3}>
              <Grid item xs={6}>
                <TempInput
                  control={control}
                  label={errors?.firstname?.message ?? 'Имя'}
                  placeholder="Имя"
                  name="firstname"
                  error={!!errors?.firstname?.message}
                  disabled={!isEdit}
                  required={isEdit}
                />
              </Grid>
              <Grid item xs={6}>
                <TempInput
                  control={control}
                  label={errors?.lastname?.message ?? 'Фамилия'}
                  placeholder="Фамилия"
                  name="lastname"
                  error={!!errors?.lastname?.message}
                  disabled={!isEdit}
                />
              </Grid>
              <Grid item xs={6}>
                <TempInput
                  type="email"
                  control={control}
                  label={(stateErrors?.email || errors?.email?.message) ?? 'Почта'}
                  placeholder="Почта"
                  name="email"
                  error={!!stateErrors?.email || !!errors?.email?.message}
                  disabled={!isEdit}
                  required={isEdit}
                />
              </Grid>
              <Grid item xs={6}>
                <PhoneInput
                  control={control}
                  label={(stateErrors?.phone || errors?.phone?.message) ?? 'Телефон'}
                  placeholder="Телефон"
                  name="phone"
                  error={!!stateErrors?.phone || !!errors?.phone?.message}
                  disabled={!isEdit}
                />
              </Grid>
              <Grid item xs={6}>
                <Button
                  href="/profile/change_password"
                  color="primary"
                  startIcon={<PasswordIcon />}
                  sx={{ '& .MuiButton-startIcon': { width: 16, mr: '8px' } }}
                >
                  Изменить пароль
                </Button>
              </Grid>
            </Grid>
          </div>
          <div className={styles.formItem}>
            <Typography variant="h2" color="text.primary">
              Данные организации
            </Typography>
            <Grid className={styles.fields} container spacing={3}>
              <Grid container item xs={6} spacing={3}>
                <Grid item xs={12}>
                  <TempInput control={control} label="Адрес" placeholder="Адрес" name="address" disabled={!isEdit} />
                </Grid>
                <Grid item xs={12}>
                  <TempInput control={control} label="Сайт" placeholder="Сайт" name="site" disabled={!isEdit} />
                </Grid>
                <Grid item xs={12}>
                  <TempInput control={control} label="Компания" placeholder="Компания" name="company" disabled={!isEdit} />
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Textarea
                  control={control}
                  label={errors?.company_details?.message ?? 'Реквизиты'}
                  placeholder="Реквизиты"
                  name="company_details"
                  error={!!errors?.company_details?.message}
                  rows={8}
                  disabled={!isEdit}
                />
              </Grid>
            </Grid>
          </div>
        </form>
      </div>
    </div>
  )
}

/* eslint-disable no-unused-vars */
import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import { InputSearch } from '../../../components/Input/InputSearch'
import { debounce } from '@mui/material'
import styles from '../../../styles/Operations.module.css'
import { OperationsTab } from '../../../components/Operations/OperationsTab'
import { VgTable, VgTableColumnType } from '../../../components/VgTable/VgTable'
import { useDoubleClick } from '../../../hooks/useDoubleClick'
import { useActions } from '../../../hooks/useActions'
import { useTypeSelector } from '../../../hooks/useTypeSelector'
import { useNavigate } from 'react-router-dom'
import { Booking, statusTypes } from '../../../types/bookingsTypes'
import { MuiTabs } from '../../../components/MuiComponents/MuiTabs/MuiTabs'
import { StyledCell } from '../../../infrasturcture/theme/styled'
import { formatDate } from '../../../infrasturcture/utils/formatDate'
import { BitrixLink } from '../../../components/BitrixLink/BitrixLink'
import { OperationComment } from '../../../components/Operations/OperationComment'
import { useObserver } from '../../../hooks/useObserver'
import { Status } from '../../../components/Status/Status'
import { Option } from '../../../components/Options/Option'
import { OperationsFilter } from '../../../components/Operations/OperationsFilter'
import { OrderRow } from './OrderRow'

const ordersColumns: VgTableColumnType<Booking>[] = [
  {
    key: 'sku',
    title: 'Номер',
    width: '11%'
  },
  {
    key: 'status',
    title: 'Статус',
    render: (_, { status }) => <Status type={status} wordBreak />,
    width: '13%'
  },
  {
    key: 'user_firstname',
    title: 'Пользователь',
    width: '15%',
    render: (_, item) => (
      <Option
        text={`${item.user_lastname || ''} ${item.user_firstname}`.trim()}
        subText={item.user_company}
        fontWeight={500}
        variant="table"
      />
    )
  },
  {
    key: 'created_at',
    title: 'Дата создания',
    render: (_, item) => <StyledCell>{formatDate(item.created_at)}</StyledCell>,
    width: '13%'
  },
  {
    key: 'warehouse_name',
    title: 'Склад',
    width: '10%'
  },
  {
    key: 'updated_at',
    title: 'Дата готовности',
    width: '14%',
    render: (_, item) => <StyledCell variant="green"></StyledCell>
  },
  {
    key: 'bitrix_id',
    title: 'Id B24',
    render: (_, item) => <BitrixLink bitrixId={item.bitrix_id} />,
    width: '8%'
  },
  {
    key: 'comment',
    title: 'Комментарий',
    render: (_, { comment }) => <OperationComment>{comment}</OperationComment>,
    width: '16%'
  }
]

export const OrdersContent: React.FC = () => {
  const navigate = useNavigate()
  const { order, count, orders, limit, filters, isLoaded } = useTypeSelector((state) => state.orders)
  const { filterWarehouses } = useTypeSelector((state) => state.warehouses)
  const { products } = useTypeSelector((state) => state.products)
  const { managers } = useTypeSelector((state) => state.operations)
  const {
    setOrder,
    setOrders,
    getOrdersThunkCreator,
    getOrderThunkCreator,
    getProductsThunkCreator,
    getManagersThunkCreator,
    getFilterWarehousesThunkCreator,
    setOrderFilters,
    setOrderProducts
  } = useActions()
  const [query, setQuery] = useState<string>('')
  const [page, setPage] = useState<number>(1)

  const debouncedSearch = useCallback(
    debounce((sku: string) => setOrderFilters({ sku }), 800),
    []
  )

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/[^\d]/g, '')
    setQuery(value)
    debouncedSearch(value)
  }

  const handleClick = useDoubleClick(
    (id: string) => {
      if (id === String(order?.sku)) return setOrder(null)
      getOrderThunkCreator(id, navigate)
    },
    (id: string) => navigate(`/orders/${id}`)
  )

  const tabs = [
    {
      label: (
        <OperationsTab count={count} variant="orange">
          Все заявки
        </OperationsTab>
      ),
      content: (
        <VgTable
          onRowClick={(o) => handleClick(String(o.sku))}
          columns={ordersColumns}
          data={orders}
          renderCollapsibleRow={(b) => (b.id === order?.id ? <OrderRow /> : undefined)}
        />
      )
    }
    /* {
      label: (
        <OperationsTab count={orders.filter((o) => o.status === statusTypes.IN_PROCESS).length}>Активные заявки</OperationsTab>
      ),
      content: (
        <VgTable
          onRowClick={(o) => handleClick(String(o.sku))}
          columns={ordersColumns}
          data={orders.filter((o) => o.status === statusTypes.IN_PROCESS)}
          renderCollapsibleRow={(b) => (b.id === order?.id ? <OrderRow /> : undefined)}
        />
      )
    } */
  ]

  const isFiltered = useRef<boolean>(false)
  const totalPages = Math.ceil(count / limit)
  const lastElement = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    isFiltered.current = true
    setOrders([])
    setPage(1)
  }, [filters])

  useEffect(() => {
    if (isFiltered.current && page > 1) return
    getOrdersThunkCreator(limit, page, filters)
    isFiltered.current = false
  }, [page, filters])

  useEffect(() => {
    setOrder(null)
    setOrders([])
    setOrderProducts([])
    !filterWarehouses.length && getFilterWarehousesThunkCreator()
    !products.length && getProductsThunkCreator(null, null)
    !managers.length && getManagersThunkCreator()
  }, [])

  useObserver(lastElement, '100px', isLoaded, page < totalPages, () => {
    if (!isFiltered.current) setPage(page + 1)
  })

  return (
    <div className={styles.container}>
      <InputSearch onChange={handleSearch} value={query} placeholder="Введите номер заявки" />
      <OperationsFilter type="orders" />
      <MuiTabs className={styles.tabs} tabs={tabs} />
      <div style={{ padding: '1px' }} ref={lastElement}></div>
    </div>
  )
}

/* eslint-disable camelcase */
import React from 'react'
import { ReportCard } from './ui/ReportCard'
import { Typography } from '@mui/material'
import { reports } from '../../infrasturcture/reports/reports'
import { useNavigate } from 'react-router-dom'
import { Breadcrumbs } from '../../components/Breadcrumbs/Breadcrumbs'
import { StyledHeader } from '../../infrasturcture/theme/styled'
import styles from './Reports.module.css'

export const Reports: React.FC = () => {
  const navigate = useNavigate()

  return (
    <div className={styles.container}>
      <Breadcrumbs />
      <StyledHeader>
        <Typography variant="h1">Отчеты</Typography>
      </StyledHeader>
      <div className={styles.reportsContainer}>
        {reports.map((item, i) => (
          <ReportCard key={i} {...item} onClick={() => navigate(`/reports/${item.id}`)} />
        ))}
      </div>
    </div>
  )
}

import React from 'react'
import { statusTypesRus } from '../../types/bookingsTypes'
import styles from './Status.module.css'
import clsx from 'clsx'

interface StatusProps {
  type: string
  className?: string
  wordBreak?: boolean
}

export const Status: React.FC<StatusProps> = ({ type, className, wordBreak = false }) => {
  return (
    <div className={clsx(styles.status, className)}>
      <span className={clsx(styles.statusLabel, styles[type.toLowerCase()], { [styles.break]: wordBreak })}>
        {statusTypesRus[type]}
      </span>
    </div>
  )
}

import React from 'react'
import styles from './Sidebar.module.css'
import { NavLink } from 'react-router-dom'
import { Route } from '../../infrasturcture/routes/routes'
import clsx from 'clsx'

interface NestedLinkProps {
  route: Route
  onClick: () => void
}

export const NestedLink: React.FC<NestedLinkProps> = ({ route, onClick }) => {
  return (
    <li className={styles.item}>
      <NavLink
        onClick={onClick}
        className={({ isActive }) => clsx(styles.nestedLink, { [styles.active]: isActive })}
        to={route.path}
      >
        <span className={styles.nestedLinkText}>{route.label}</span>
      </NavLink>
    </li>
  )
}

import { instance } from '../infrasturcture/http'
import { Section } from '../types/sectionsTypes'

export default class SectionsApi {
  static async getSections() {
    const response = await instance.get('sections')
    return response.data.data
  }

  static async getOne(id: string) {
    const response = await instance.get(`sections/${id}`)
    return response.data.data
  }

  static async createSection(section: Section) {
    const response = await instance.post('sections', section)
    return response.data.data
  }

  static async editSection(section: Section) {
    const response = await instance.post(`sections/${section.id}`, section)
    return response.data.data
  }

  static async deleteSection(id: string) {
    const response = await instance.delete(`sections/${id}`)
    return response.data.data
  }
}

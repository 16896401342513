import React, { useEffect } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { Login } from './pages/login/Login'
import { routes } from './infrasturcture/routes/routes'
import { useActions } from './hooks/useActions'
import { useTypeSelector } from './hooks/useTypeSelector'
import { PrivateRoute } from './infrasturcture/routes/PrivateRoute'
import { GuestRoute } from './infrasturcture/routes/GuestRoute'
import { PageLoader } from './components/PageLoader/PageLoader'
import { DefaultLayout } from './components/Layouts/DefaultLayout'
import 'react-datepicker/dist/react-datepicker.css'
import './styles/CustomizeDatePicker.css'
import './styles/App.css'

const App: React.FC = () => {
  const { authThunkCreator } = useActions()
  const { isLoaded } = useTypeSelector((state) => state.user)

  useEffect(() => {
    authThunkCreator()
  }, [])

  return isLoaded ? (
    <PageLoader />
  ) : (
    <BrowserRouter>
      <Routes>
        <Route element={<DefaultLayout />}>
          {routes.map((item, i) => (
            <Route
              key={i}
              path={item.path}
              element={
                <PrivateRoute roles={item.roles}>
                  <item.component />
                </PrivateRoute>
              }
            />
          ))}
          <Route path="/" element={<Navigate to="/products" replace />} />
        </Route>
        <Route
          path="/login"
          element={
            <GuestRoute>
              <Login />
            </GuestRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  )
}

export default App

import { Box, styled } from '@mui/material'
import React, { MouseEvent, PropsWithChildren, useState } from 'react'

const StyledBox = styled(Box)<{ open?: boolean }>(({ theme, open }) => ({
  whiteSpace: open ? 'normal' : 'nowrap',
  overflow: open ? 'visible' : 'hidden',
  textOverflow: 'ellipsis',
  fontSize: 15,
  fontWeight: 500,
  color: theme.palette.secondary.main
}))

export const OperationComment: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const [open, setOpen] = useState<boolean>(false)

  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    setOpen((prev) => !prev)
  }

  return (
    <StyledBox onClick={handleClick} open={open}>
      {children}
    </StyledBox>
  )
}

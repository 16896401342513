import React from 'react'
import { CalendarNextIcon, CalendarPrevIcon } from '../../infrasturcture/icons'
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker'
import styles from './DateInput.module.css'

const monthNames = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь'
]

export const DateHeader: React.FC<ReactDatePickerCustomHeaderProps> = ({
  date,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled
}) => {
  return (
    <div className={styles.header}>
      <span className={styles.month}>
        {monthNames[date.getMonth()]} {date.getFullYear()}
      </span>
      <div className={styles.buttons}>
        <button className={styles.button} onClick={decreaseMonth} disabled={prevMonthButtonDisabled} type="button">
          <CalendarPrevIcon />
        </button>
        <button className={styles.button} onClick={increaseMonth} disabled={nextMonthButtonDisabled} type="button">
          <CalendarNextIcon />
        </button>
      </div>
    </div>
  )
}

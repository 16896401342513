import { statusTypes, statusTypesRus } from '../../types/bookingsTypes'
import { roles } from '../../types/userTypes'

type OptionsType = Array<{ label: string; value: string }>

export const getStatusOptions = (role: roles, status?: string): OptionsType => {
  if (role === roles.SUPER_MANAGER && ![statusTypes.CANCELED, statusTypes.SHIPPED_CLOSED].includes(status as statusTypes)) {
    return Object.entries(statusTypesRus).map(([value, label]) => ({ label, value }))
  }

  const formattedOptions = Object.entries(statusTypesRus).map(([value, label]) => ({ label, value }))
  const statusIndex = formattedOptions.findIndex((option) => option.value === status)
  return formattedOptions.slice(statusIndex)
}

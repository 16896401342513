/* eslint-disable no-unused-vars */
import { ErrorMessages } from './errorTypes'

export interface Manufacturer {
  id: string
  name: string
  created_at?: Date
  updated_at?: Date
}

export interface ManufacturersState {
  manufacturers: Manufacturer[]
  manufacturer: Manufacturer | null
  isLoaded: boolean
  errors: ErrorMessages
}

export enum ManufacturersActionTypes {
  GET_MANUFACTURERS = 'GET_MANUFACTURERS',
  GET_MANUFACTURER = 'GET_MANUFACTURER',
  UPDATE_MANUFACTURER = 'UPDATE_MANUFACTURER',
  SET_MANUFACTURER = 'SET_MANUFACTURER',
  SET_MANUFACTURERS_LOADED = 'SET_MANUFACTURERS_LOADED',
  SET_MANUFACTURERS_ERRORS = 'SET_MANUFACTURERS_ERRORS'
}

export interface GetManufacturersThunkCreator {
  type: ManufacturersActionTypes.GET_MANUFACTURERS
  payload: Manufacturer[]
}

export interface GetManufacturerThunkCreator {
  type: ManufacturersActionTypes.GET_MANUFACTURER
  payload: Manufacturer
}

export interface UpdateManufacturerThunkCreator {
  type: ManufacturersActionTypes.UPDATE_MANUFACTURER
  payload: Manufacturer
}

export interface SetManufacturerAction {
  type: ManufacturersActionTypes.SET_MANUFACTURER
  payload: Manufacturer | null
}

export interface SetManufacturersLoaded {
  type: ManufacturersActionTypes.SET_MANUFACTURERS_LOADED
  payload: boolean
}

export interface SetManufacturersErrorsAction {
  type: ManufacturersActionTypes.SET_MANUFACTURERS_ERRORS
  payload: ErrorMessages
}

export type ManufacturersAction =
  | GetManufacturersThunkCreator
  | GetManufacturerThunkCreator
  | UpdateManufacturerThunkCreator
  | SetManufacturerAction
  | SetManufacturersLoaded
  | SetManufacturersErrorsAction

import React, { SyntheticEvent, useCallback, useEffect, useId, useMemo, useRef, useState } from 'react'
import { Control, Controller, ControllerRenderProps } from 'react-hook-form'
import { ClickAwayListener, Grow, MenuList, Paper, Popper, Typography } from '@mui/material'
import { TempSearchItem } from './TempSearchItem'
import { genericMemo } from '../../infrasturcture/utils/genericMemo'
import clsx from 'clsx'
import { LabelSelected } from '../Label/LabelSelected'
import styles from './TempSearch.module.css'

interface Option<T> {
  label: string
  value: T
}

interface GroupedOptions<T> {
  [key: string]: Option<T>[]
}

interface CommonProps<T> {
  control: Control<any>
  label?: string
  placeholder: string
  name: string
  options: Option<T>[]
  compareKey: keyof T
  disabled?: boolean
  emptyMessage?: string
  className?: string
  inputClassName?: string
  renderOption?: (item: T) => React.ReactNode
}

interface IWithDisplayKeyProps<T> extends CommonProps<T> {
  displayKey: keyof T | ((item: T) => string)
  renderSelected?: never
}

interface IWithRenderSelectedProps<T> extends CommonProps<T> {
  renderSelected: (item: T, props: { key: React.Key; onClick: () => void; disabled?: boolean }) => React.ReactNode
  displayKey?: never
}

type TempSearchProps<T> = IWithDisplayKeyProps<T> | IWithRenderSelectedProps<T>

const TempSearchComponent = <T,>({
  control,
  name,
  label,
  placeholder,
  options,
  compareKey,
  displayKey,
  disabled = false,
  emptyMessage,
  className,
  inputClassName,
  renderOption,
  renderSelected
}: TempSearchProps<T>) => {
  const id = useId()
  const [open, setOpen] = useState(false)
  const [search, setSearch] = useState<string>('')
  const anchorRef = useRef<HTMLDivElement>(null)
  const prevOpen = useRef<boolean>(open)

  const searchOptions = useMemo(() => {
    return options
      ?.filter((item) => item.label?.toLowerCase().includes(search?.toLowerCase()))
      ?.sort((a, b) => a.label.localeCompare(b.label))
      .reduce<GroupedOptions<T>>((grouped, option) => {
        const key = option.label.charAt(0).toUpperCase()
        return { ...grouped, [key]: [...(grouped[key] || []), option] }
      }, {})
  }, [search, options])

  const handleSelect = (field: ControllerRenderProps<any, string>, value: T) => {
    const newValues = field.value.find((v: any) => v[compareKey] === value[compareKey])
      ? field.value.filter((v: any) => v[compareKey] !== value[compareKey])
      : [...field.value, value]
    field.onChange(newValues)
  }

  const toggleAll = (field: ControllerRenderProps<any, string>) => {
    field.value.length === options?.length ? field.onChange([]) : field.onChange(options?.map((o) => o.value))
  }

  const handleDelete = (value: any, field: ControllerRenderProps<any, string>) => {
    if (disabled) return
    field.onChange(field.value?.filter((v: any) => v[compareKey] !== value[compareKey]))
  }

  const handleToggle = useCallback(() => {
    setOpen((prevOpen) => !prevOpen)
  }, [])

  const handleClose = useCallback((event: Event | SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }, [])

  useEffect(() => {
    if (prevOpen.current === true && open === false) anchorRef.current!.focus()

    prevOpen.current = open
  }, [open])

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={[]}
      render={({ field }) => (
        <div className={clsx(styles.container, className)}>
          {label && (
            <label className={styles.label} htmlFor={id} id={`${id}-label`}>
              {label}
            </label>
          )}
          {!disabled && (
            <div
              className={clsx(styles.inputContainer, inputClassName)}
              ref={anchorRef}
              id="composition-button"
              aria-controls={open ? 'composition-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="true"
            >
              <input
                onClick={handleToggle}
                onChange={(e) => setSearch(e.target.value)}
                name={name}
                value={search}
                className={clsx(styles.input, inputClassName)}
                autoComplete="off"
                type="text"
                id={id}
                placeholder={placeholder}
              />
              <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal
                style={{ zIndex: 100, width: 'inherit' }}
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin: placement === 'bottom-start' ? 'center top' : 'center bottom'
                    }}
                  >
                    <Paper sx={{ maxHeight: 343, overflowY: 'auto' }}>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList id="composition-menu" aria-labelledby="composition-button">
                          <TempSearchItem
                            onClick={() => toggleAll(field)}
                            label="Выбрать все"
                            selected={field.value.length === options?.length}
                            sx={{ pt: renderOption ? '9px' : '4px', pb: renderOption ? '9px' : '4px' }}
                          />
                          {Object.entries(searchOptions).map(([key, options]) => (
                            <MenuList key={key}>
                              <Typography
                                variant="h3"
                                sx={{ fontWeight: 700, fontSize: '15px', lineHeight: '21px', p: '15px 33px' }}
                              >
                                {key}
                              </Typography>
                              {options.map((item) => (
                                <TempSearchItem
                                  key={String(item.value[compareKey])}
                                  onClick={() => handleSelect(field, item.value)}
                                  label={renderOption ? renderOption(item.value) : item.label}
                                  selected={!!field.value?.find((v: any) => v[compareKey] === item.value[compareKey])}
                                  sx={{ pt: renderOption ? '3px' : '4px', pb: renderOption ? '3px' : '4px' }}
                                />
                              ))}
                            </MenuList>
                          ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>
          )}
          <div className={styles.items}>
            {!field.value?.length && disabled && (
              <Typography sx={{ fontSize: 12, lineHeight: '16px', color: '#ABABAB99' }} component="span">
                {emptyMessage}
              </Typography>
            )}
            {field.value?.map((item: any) =>
              renderSelected ? (
                renderSelected(item, { key: item.id, onClick: () => handleDelete(item, field), disabled })
              ) : (
                <LabelSelected
                  key={item.id}
                  onClick={() => handleDelete(item, field)}
                  text={typeof displayKey === 'function' ? displayKey(item) : item[displayKey]}
                  disabled={disabled}
                />
              )
            )}
          </div>
        </div>
      )}
    />
  )
}

export const TempSearch = genericMemo(TempSearchComponent)

import { Box, Drawer, styled } from '@mui/material'
import React, { useState } from 'react'
import { FilterFooter } from './FilterFooter'
import { FilterHeader } from './FilterHeader'
import { FilterSelected } from './FilterSelected'
import styles from './Filter.module.css'

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  width: 316,
  '& .MuiDrawer-paper': {
    width: 316,
    borderRadius: '24px 0px 0px 24px'
  }
}))

interface FilterProps {
  onApply: () => void
  onReset: () => void
  onToggle?: () => void
  children: React.ReactNode
  selectedComponent: React.ReactNode
}

export const Filter: React.FC<FilterProps> = ({ onApply, onToggle, onReset, selectedComponent, children }) => {
  const [open, setOpen] = useState(false)

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen)
    onToggle?.()
  }

  const handleApply = () => {
    onApply()
    setOpen(false)
  }

  const handleReset = () => {
    onReset()
    setOpen(false)
  }

  return (
    <Box>
      <FilterSelected onOpen={toggleDrawer(true)}>{selectedComponent}</FilterSelected>
      <StyledDrawer open={open} anchor="right" onClose={toggleDrawer(false)}>
        <FilterHeader onClose={toggleDrawer(false)} />
        <div className={styles.content}>{children}</div>
        <FilterFooter onApply={handleApply} onReset={handleReset} />
      </StyledDrawer>
    </Box>
  )
}

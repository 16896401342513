import React, { useEffect, useState } from 'react'

export type FileReaderType = {
  file: File | null
  setFile: React.Dispatch<React.SetStateAction<File | null>>
  image: string | null
  setImage: React.Dispatch<React.SetStateAction<string | null>>
}

export const useFileReader = () => {
  const [file, setFile] = useState<File | null>(null)
  const [image, setImage] = useState<string | null>(null)

  useEffect(() => {
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setImage(reader.result as string)
      }
      reader.readAsDataURL(file)
    } else {
      setImage(null)
    }
  }, [file])

  return { file, setFile, image, setImage }
}

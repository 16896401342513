import React, { useId, useState } from 'react'
import styles from './Checkbox.module.css'
import { IconButton } from '@mui/material'
import { CheckedIcon } from '../../infrasturcture/icons'
import clsx from 'clsx'

interface CheckboxProps extends React.ComponentProps<'input'> {
  label?: React.ReactNode
  labelStyle?: React.CSSProperties
  labelPosition?: 'before' | 'after'
  error?: boolean
  onClick?: () => void
  shakeOnClick?: boolean
}

export const Checkbox: React.FC<CheckboxProps> = ({
  label,
  labelStyle,
  labelPosition = 'after',
  error = false,
  checked,
  className,
  onChange,
  onClick,
  disabled,
  shakeOnClick = false,
  ...rest
}) => {
  const id = useId()
  const [isShake, setIsShake] = useState<boolean>(false)

  const handleClick = () => {
    onClick?.()
    setIsShake(shakeOnClick)
  }

  return (
    <label
      className={clsx(styles.container, className, { [styles.shake]: isShake })}
      onAnimationEnd={() => setIsShake(false)}
      htmlFor={id}
      style={labelStyle}
    >
      {label && labelPosition === 'before' && <span className={styles.text}>{label}</span>}
      <IconButton color={error ? 'error' : 'primary'} onClick={handleClick} disabled={disabled}>
        <input
          className={styles.checkbox}
          type="checkbox"
          id={id}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          {...rest}
        />
        <span className={clsx(styles.customCheckbox, { [styles.error]: error })}>
          <CheckedIcon className={styles.customCheckbox_icon} />
        </span>
      </IconButton>
      {label && labelPosition === 'after' && <span className={styles.text}>{label}</span>}
    </label>
  )
}

import React, { PropsWithChildren } from 'react'
import { Typography } from '@mui/material'
import styles from './Filter.module.css'
import clsx from 'clsx'

interface FilterSectionProps extends PropsWithChildren<unknown> {
  title: string
  isCheckboxes?: boolean
}

export const FilterSection: React.FC<FilterSectionProps> = ({ title, children, isCheckboxes = true }) => {
  return (
    <div className={styles.item}>
      <Typography variant="h2" color="secondary.main">
        {title}
      </Typography>
      <div className={clsx(styles.item, { [styles.checkboxes]: isCheckboxes })}>{children}</div>
    </div>
  )
}

import React from 'react'
import { ReactComponent as ArrowIcon } from '../../assets/images/new/filter-button-icon.svg'
import { styled } from '@mui/material'

const StyledButton = styled('button')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: 32,
  padding: '8px 16px',
  fontFamily: 'Roboto, sans-serif',
  fontSize: 12,
  fontWeight: 400,
  lineHeight: 16,
  color: '#ababab99',
  border: `1px solid ${theme.palette.secondary.main}`,
  borderRadius: 8,
  backgroundColor: 'transparent',
  outline: 'none',
  cursor: 'pointer',
  marginLeft: 8,
  marginTop: 8
}))

export const FilterButton: React.FC<React.ComponentProps<'button'>> = ({ children, ...props }) => {
  return (
    <StyledButton {...props} type="button">
      {children}
      <ArrowIcon />
    </StyledButton>
  )
}

import React from 'react'

import { Box, Typography, styled } from '@mui/material'

const StyledText = styled(Typography, { shouldForwardProp: (prop) => prop !== 'type' })<{ type: 'label' | 'text' }>(
  ({ theme, type }) => ({
    fontWeight: type === 'label' ? 700 : 400,
    fontSize: type === 'label' ? 12 : 15,
    lineHeight: type === 'label' ? '16px' : '21px',
    color: type === 'label' ? theme.palette.primary.main : theme.palette.text.primary
  })
)

interface ReportCardProps {
  title: string
  description: string
  format?: string
  onClick: () => void
}

export const ReportCard: React.FC<ReportCardProps> = ({ title, description, format, onClick }) => {
  return (
    <Box onClick={onClick} sx={{ p: '22px 40px', backgroundColor: '#FFFFFF', borderRadius: '8px', cursor: 'pointer' }}>
      <Typography variant="h2" color="text.primary" sx={{ fontWeight: 700, mb: '16px' }}>
        {title}
      </Typography>
      <Box>
        <StyledText type="label">Описание</StyledText>
        <StyledText type="text" sx={{ mt: '12px' }}>
          {description}
        </StyledText>
      </Box>
      {format && (
        <Box sx={{ mt: '20px' }}>
          <StyledText type="label">Формат</StyledText>
          <StyledText type="text" sx={{ mt: '12px' }}>
            {format}
          </StyledText>
        </Box>
      )}
    </Box>
  )
}

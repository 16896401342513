import React, { useState } from 'react'
import { countries } from './countries'
import { StyledFormControl } from '../../infrasturcture/theme/styled'
import { InputLabel, Menu, MenuItem, OutlinedInput } from '@mui/material'
import { Control, Controller } from 'react-hook-form'
import InputMask from 'react-input-mask'
import styles from './PhoneInput.module.css'

interface PhoneInputProps {
  control: Control<any>
  label: string
  name: string
  error?: boolean
  fullWidth?: boolean
  placeholder?: string
  disabled?: boolean
  required?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
}

export const PhoneInput: React.FC<PhoneInputProps> = ({
  control,
  label,
  name,
  fullWidth = true,
  error = false,
  placeholder,
  disabled = false,
  required = false,
  onChange
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

  const beforeMaskedStateChange = ({ nextState }: any) => {
    const { value } = nextState
    return {
      ...nextState,
      value: value.endsWith('(') ? value.slice(0, -1) : value
    }
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field }) => {
        const country = /^(\+7|7)/.test(field.value) ? countries.ru : countries.by
        return (
          <StyledFormControl variant="outlined" fullWidth={fullWidth} error={error}>
            <InputLabel htmlFor="phone" {...(error ? { shrink: true } : {})}>
              {label}
            </InputLabel>
            <InputMask
              mask={country.mask}
              maskPlaceholder={null}
              value={field.value}
              onChange={(e) => {
                field.onChange(e)
                onChange?.(e)
              }}
              disabled={disabled}
              beforeMaskedStateChange={beforeMaskedStateChange}
            >
              <OutlinedInput
                id="phone"
                label={label}
                placeholder={placeholder}
                required={required}
                autoComplete="off"
                {...(error ? { notched: true } : {})}
              />
            </InputMask>
            {!disabled && (
              <div className={styles.menu} id="box" onClick={handleClick}>
                {country.icon}
              </div>
            )}
            <Menu
              id="box-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              MenuListProps={{ 'aria-labelledby': 'box' }}
              autoFocus={false}
              sx={{ top: 2, '& .MuiPaper-root': { width: 213 } }}
            >
              {Object.entries(countries).map(([key, value]) => (
                <MenuItem
                  key={key}
                  onClick={() => {
                    setAnchorEl(null)
                    field.onChange(value.code)
                  }}
                >
                  <span className={styles.icon}>{value.icon}</span>
                  <span className={styles.text}>{value.lang_ru}</span>
                  <span className={styles.code}>{value.code}</span>
                </MenuItem>
              ))}
            </Menu>
          </StyledFormControl>
        )
      }}
    />
  )
}

import { instance } from '../infrasturcture/http'
import { Manufacturer } from '../types/manufacturersTypes'

export default class ManufacturersApi {
  static async getManufacturers() {
    const response = await instance.get('manufacturers')
    return response.data.data
  }

  static async getOne(id: string) {
    const response = await instance.get(`manufacturers/${id}`)
    return response.data.data
  }

  static async createManufacturer(manufacturer: Manufacturer) {
    const response = await instance.post('manufacturers', manufacturer)
    return response.data.data
  }

  static async editManufacturer(manufacturer: Manufacturer) {
    const response = await instance.post(`manufacturers/${manufacturer.id}`, manufacturer)
    return response.data.data
  }

  static async deleteManufacturer(id: string) {
    const response = await instance.delete(`manufacturers/${id}`)
    return response.data.data
  }
}

import { Box, Typography, styled } from '@mui/material'
import React from 'react'
import { CloseSmallIcon } from '../../infrasturcture/icons'

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  backgroundColor: '#FCFCFC',
  border: '1px solid #ABABAB52',
  borderRadius: 8,
  boxShadow: '-1px 2px 3px 0px #00000008',
  height: 32,
  lineHeight: 0,
  fill: '#4B4B4B'
}))

interface LabelSelectedProps {
  text: string
  onClick: () => void
  disabled?: boolean
}

export const LabelSelected: React.FC<LabelSelectedProps> = ({ text, onClick, disabled = false }) => {
  return (
    <StyledBox sx={{ overflow: 'hidden' }}>
      <Box sx={{ p: '8px 10px' }}>
        <Typography component="span" sx={{ fontSize: 12, fontWeight: 400, color: '#4B4B4B', lineHeight: '16px' }}>
          {text}
        </Typography>
      </Box>
      {!disabled && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: 32,
            width: 32,
            borderLeft: '1px solid #ABABAB52',
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)'
            }
          }}
          onClick={onClick}
        >
          <CloseSmallIcon />
        </Box>
      )}
    </StyledBox>
  )
}

import React from 'react'
import styles from './Loader.module.css'

interface LoaderProps {
  className?: string
  style?: React.CSSProperties
}

export const Loader: React.FC<LoaderProps> = ({ style, className }) => {
  return (
    <div className={className ? `${styles.loaderContainer} ${className}` : styles.loaderContainer} style={style}>
      <div className={styles.loaderSpinner}>
        <div className={styles.loader}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  )
}

import React, { useEffect } from 'react'
import styles from '../../styles/Operations.module.css'
import { Breadcrumbs } from '../../components/Breadcrumbs/Breadcrumbs'
import { StyledHeader } from '../../infrasturcture/theme/styled'
import { Button, Typography } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { createOrderSchema } from '../../infrasturcture/yupSchemes/createOrderSchema'
import { SaveIcon } from '../../infrasturcture/icons'
import { useNavigate } from 'react-router-dom'
import { useTypeSelector } from '../../hooks/useTypeSelector'
import { OrderForm } from './ui/OrderForm'
import { useActions } from '../../hooks/useActions'
import { statusTypes } from '../../types/bookingsTypes'
import { OrderProducts } from './ui/OrderProducts'

export const CreateOrder: React.FC = () => {
  const navigate = useNavigate()
  const { productsInOrder } = useTypeSelector((state) => state.orders)
  const { filterWarehouses } = useTypeSelector((state) => state.warehouses)
  const { getFilterWarehousesThunkCreator, createOrderThunkCreator, setOrdersErrors, setOrderProducts } = useActions()

  const methods = useForm({ resolver: yupResolver(createOrderSchema), mode: 'onChange' })

  const onSubmit = (data: any) => {
    const warehouse = filterWarehouses.find((w) => w.id === data.warehouse_id)

    const order = {
      status: statusTypes.CONFIRMATION,
      type: 'ORDER',
      warehouse_id: data.warehouse_id,
      warehouse_name: warehouse?.name,
      bitrix_id: data.bitrix_id || null,
      comment: data.comment || null,
      productsInOrder: productsInOrder.map((p) => ({
        product_id: p.id,
        count: p.count,
        count_left: p.count_left,
        write_off_left: p.write_off_left
      }))
    }

    return createOrderThunkCreator(
      order,
      () => {
        setOrderProducts([])
        setOrdersErrors({})
        methods.reset({ warehouse_id: '', bitrix_id: '', comment: '' })
      },
      navigate
    )
  }

  useEffect(() => {
    setOrderProducts([])
    getFilterWarehousesThunkCreator()
  }, [])

  return (
    <div className={styles.container}>
      <Breadcrumbs />
      <StyledHeader>
        <Typography variant="h1">Новая заявка</Typography>
        <Button
          onClick={methods.handleSubmit(onSubmit)}
          disabled={methods.formState.isSubmitting}
          color="primary"
          startIcon={<SaveIcon />}
        >
          Сохранить
        </Button>
      </StyledHeader>
      <FormProvider {...methods}>
        <OrderForm type="create" onSubmit={onSubmit} />
      </FormProvider>
      <OrderProducts type="create" />
    </div>
  )
}

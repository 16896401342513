import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { WarehouseForm } from './ui/WarehouseForm'
import { SectionForm } from './ui/SectionForm'

type EntityType = 'warehouses' | 'sections'

export const AccessType: React.FC = () => {
  const [searchParams] = useSearchParams({ type: 'warehouses' })
  const type = searchParams.get('type') as EntityType

  return type === 'warehouses' ? <WarehouseForm /> : <SectionForm />
}

import { ThunkAction } from 'redux-thunk'
import { RootState } from '..'
import UserApi from '../../api/UserApi'
import {
  UsersAction,
  UsersActionTypes,
  SetLoaded,
  SetUsersErrorsAction,
  Users,
  SetUserAction,
  EditUserFields,
  CreateUserFields
} from '../../types/usersTypes'
import { logoutThunkCreator } from './userActions'
import { ErrorMessages } from '../../types/errorTypes'
import { errorsHandling } from '../../infrasturcture/utils/errorsHandling'
import { NavigateFunction } from 'react-router-dom'

type ThunkType = ThunkAction<void, RootState, unknown, UsersAction>

const setLoaded = (payload: boolean): SetLoaded => ({
  type: UsersActionTypes.SET_LOADED,
  payload
})

export const setUser = (payload: Users | null): SetUserAction => ({
  type: UsersActionTypes.SET_USER,
  payload
})

export const setUsersErrors = (payload: ErrorMessages): SetUsersErrorsAction => ({
  type: UsersActionTypes.SET_USERS_ERRORS,
  payload
})

export const getUsersThunkCreator = (): ThunkType => {
  return async (dispatch) => {
    try {
      dispatch(setLoaded(true))
      const data = await UserApi.getUsers()
      dispatch({
        type: UsersActionTypes.GET_USERS,
        payload: data
      })
    } catch (e: any) {
      dispatch(logoutThunkCreator())
    }
  }
}

export const getUserThunkCreator = (id: string, navigate: NavigateFunction): ThunkType => {
  return async (dispatch) => {
    try {
      dispatch(setLoaded(true))
      const data = await UserApi.getOne(id)
      dispatch({ type: UsersActionTypes.GET_USER, payload: data })
    } catch (e: any) {
      if ([403, 404, 503].includes(e.response.status)) {
        return navigate(`/${e.response.status}`)
      }
      dispatch(logoutThunkCreator())
    }
  }
}

export const createUsersThunkCreator = (user: CreateUserFields, cb: () => void, navigate: NavigateFunction): ThunkType => {
  return async (dispatch) => {
    try {
      await UserApi.createUser(user)
      cb()
    } catch (e: any) {
      switch (e.response.status) {
        case 422:
          dispatch(setUsersErrors(errorsHandling(e.response)))
          break
        case 503:
          navigate('/503')
          break
        default:
          dispatch(logoutThunkCreator())
      }
    }
  }
}

export const deleteUserThunkCreator = (id: string, cb: () => void): ThunkType => {
  return async (dispatch) => {
    try {
      await UserApi.deleteUser(id)
      cb()
    } catch (e: any) {
      dispatch(logoutThunkCreator())
    }
  }
}

export const updateUserThunkCreator = (
  id: string,
  userFields: EditUserFields & { mainWarehouses: string[] },
  cb: () => void,
  navigate: NavigateFunction
): ThunkType => {
  return async (dispatch) => {
    try {
      const { mainWarehouses, ...user } = userFields
      await UserApi.editUser(id, user)
      const payload = { ...user, warehouses: mainWarehouses }
      dispatch({ type: UsersActionTypes.UPDATE_USER, payload })
      cb()
    } catch (e: any) {
      if (e.response.status === 422) return dispatch(setUsersErrors(errorsHandling(e.response)))
      if ([403, 404, 503].includes(e.response.status)) return navigate(`/${e.response.status}`)
      dispatch(logoutThunkCreator())
    }
  }
}

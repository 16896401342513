import React from 'react'
import { CustomTooltipProps, defaultText } from './tooltipConfig'
import { MuiTooltip } from './MuiTooltip'
import { InfoIcon } from '../../../infrasturcture/icons'
import styles from './CustomTooltip.module.css'
import clsx from 'clsx'

export const CustomTooltip: React.FC<CustomTooltipProps> = ({ text, label, className }) => {
  const tooltipText = text || defaultText
  return (
    <div className={clsx(styles.container, className)}>
      <label className={styles.label}>{label}</label>
      <MuiTooltip
        title={tooltipText}
        leaveDelay={500}
        placement="right-end"
        PopperProps={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -15]
              }
            }
          ]
        }}
      >
        <InfoIcon className={styles.icon} />
      </MuiTooltip>
    </div>
  )
}

import React from 'react'
import styles from './Text.module.css'

interface TextProps {
  title: string
  subtitle?: string
}

export const Text: React.FC<TextProps> = ({ title, subtitle }) => {
  return (
    <div className={styles.container}>
      <span className={styles.title}>{title}</span>
      <span className={styles.subtitle}>{subtitle}</span>
    </div>
  )
}

import React from 'react'
import { Typography } from '@mui/material'
import { Breadcrumbs } from '../../components/Breadcrumbs/Breadcrumbs'
import { StyledHeader } from '../../infrasturcture/theme/styled'
import { OperationsButton } from './ui/OperationsButton'
import { OperationsContent } from './ui/OperationsContent'
import styles from '../../styles/Operations.module.css'

export const Operations: React.FC = () => {
  return (
    <div className={styles.container}>
      <Breadcrumbs />
      <StyledHeader sx={{ mb: '40px' }}>
        <Typography variant="h1">Операции</Typography>
        <OperationsButton />
      </StyledHeader>
      <OperationsContent />
    </div>
  )
}

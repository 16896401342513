import { operationTypes } from '../types/operationsTypes'
import { OrderPostingProduct } from '../types/ordersTypes'
import { useActions } from './useActions'
import { useTypeSelector } from './useTypeSelector'

export const useOrdersPosting = (type: string | undefined) => {
  const { setOrdersPosting, setOrdersErrors } = useActions()
  const { products } = useTypeSelector((state) => state.operations)
  const { ordersPosting } = useTypeSelector((state) => state.orders)

  const isAvailableToSelect = (value: OrderPostingProduct | OrderPostingProduct[]) => {
    if (type !== operationTypes.MOVING) return true

    if (Array.isArray(value)) {
      return value.every((p) => {
        if (p.selected) return true

        const availableCount = products.find((item) => item.id === p.id)?.count || 0

        const postingCount = ordersPosting
          .flatMap((o) => o.productsInOrder)
          .reduce((acc, item) => {
            if (item.id === p.id && item.selected) {
              const count = availableCount >= item.count_left ? item.count_left : availableCount
              return acc + count
            }
            return acc
          }, 0)

        return availableCount > postingCount
      })
    }

    if (value.selected) return true

    const availableCount = products.find((item) => item.id === value.id)?.count || 0
    const postingCount = ordersPosting
      .flatMap((o) => o.productsInOrder)
      .reduce((acc, item) => {
        if (item.id === value.id && item.selected) {
          const count = availableCount >= item.count_left ? item.count_left : availableCount
          return acc + count
        }
        return acc
      }, 0)

    return availableCount > postingCount
  }

  const onChange = (orderId: string, value: OrderPostingProduct | OrderPostingProduct[]) => {
    const isExist = isAvailableToSelect(value)

    if (!isExist) return setOrdersErrors({ ordersPosting: 'Необходимые на складе товары отсутствуют' })

    const isArray = Array.isArray(value)

    setOrdersPosting(
      ordersPosting.map((o) =>
        o.id === orderId
          ? {
              ...o,
              productsInOrder: isArray
                ? o.productsInOrder.map((p) => ({ ...p, selected: !p.selected }))
                : o.productsInOrder.map((p) => (p.id === value.id ? { ...p, selected: !p.selected } : p))
            }
          : o
      )
    )
  }

  return { onChange, isAvailableToSelect }
}

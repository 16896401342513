import { instance } from '../infrasturcture/http'

export default class AuthApi {
  static async login(email: string, password: string) {
    const response = await instance.post('login', { email, password })
    return response.data.data
  }

  static async logout() {
    const response = await instance.get('logout')
    return response.data.data
  }
}

import * as Yup from 'yup'

export const createProductSchema = Yup.object().shape({
  name: Yup.string().trim().required('Заполните поле имя товара'),
  section_id: Yup.string().required('Выберите раздел'),
  color: Yup.string().trim().required('Заполните поле цвет'),
  delivery_time: Yup.string()
    .required('Введите число')
    .matches(/^[0-9]+$/, 'Введите число')
    .max(9, 'Время доставки должно быть меньше 9 чисел'),
  sku: Yup.number().positive('Отрицательное число').integer().typeError('Введите число').required('Заполните поле код товара'),
  retail_price: Yup.string()
    .nullable(true)
    .matches(/^-?[0-9]+([.,][0-9]+)?$/, {
      excludeEmptyString: true,
      message: 'Введите число'
    }),
  purchase_price: Yup.string()
    .nullable(true)
    .matches(/^-?[0-9]+([.,][0-9]+)?$/, {
      excludeEmptyString: true,
      message: 'Введите число'
    })
})

import { formatISO } from 'date-fns'
import { instance } from '../infrasturcture/http'
import { BalanceFilters } from '../types/balancesTypes'

export default class BalancesApi {
  static async getBalances({ date }: BalanceFilters) {
    const response = await instance.get('balances', {
      params: { date: formatISO(date) }
    })
    return response.data.data
  }

  static async getSupplierBalances() {
    const response = await instance.get('supplier')
    return response.data.data
  }
}

import React, { useId } from 'react'
import { IconButton, InputLabel, OutlinedInput } from '@mui/material'
import { StyledBoxIcon, StyledFormControl } from '../../infrasturcture/theme/styled'
import { PasswordIcon, VisibilityHiddenIcon, VisibilityIcon } from '../../infrasturcture/icons'
import { Control, Controller } from 'react-hook-form'

interface InputPasswordProps {
  control: Control<any>
  name: string
  label: string
  placeholder?: string
  fullWidth?: boolean
  className?: string
  size?: 'small' | 'medium'
  error?: boolean
  startIcon?: boolean
  disabled?: boolean
  required?: boolean
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
}

export const InputPassword: React.FC<InputPasswordProps> = ({
  className,
  control,
  name,
  label,
  placeholder,
  fullWidth = true,
  size = 'small',
  error = false,
  startIcon = true,
  disabled = false,
  required = false,
  onChange
}) => {
  const id = useId()
  const [isShowPassword, setIsShowPassword] = React.useState(false)

  const toogleIsShowPassword = () => setIsShowPassword((prev) => !prev)

  const inputLabelStyle =
    size === 'medium'
      ? {
          transform: 'translate(70px, 24px) scale(1)',
          '&.MuiInputLabel-shrink': { transform: 'translate(30px, -9px) scale(0.8)' }
        }
      : {}

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field }) => (
        <StyledFormControl className={className} variant="outlined" fullWidth={fullWidth} error={error}>
          {startIcon && (
            <StyledBoxIcon error={error}>
              <PasswordIcon />
            </StyledBoxIcon>
          )}
          <InputLabel htmlFor={id} sx={inputLabelStyle} {...(error ? { shrink: true } : {})} required={required}>
            {label}
          </InputLabel>
          <OutlinedInput
            id={id}
            type={isShowPassword ? 'text' : 'password'}
            label={label}
            placeholder={placeholder}
            sx={{
              ...(size === 'medium' && {
                height: 68,
                '& .MuiOutlinedInput-notchedOutline': {
                  padding: '0 24px'
                }
              }),
              '& .MuiOutlinedInput-input': {
                paddingLeft: size === 'small' ? '24px' : '70px',
                paddingRight: size === 'small' ? '24px' : '70px'
              }
            }}
            {...field}
            value={field.value || ''}
            onChange={(e) => {
              field.onChange(e)
              onChange && onChange(e)
            }}
            {...(error ? { notched: true } : {})}
            disabled={disabled}
            required={required}
          />
          <IconButton
            color="primary"
            onClick={toogleIsShowPassword}
            sx={{ position: 'absolute', right: size === 'small' ? 16 : 22, top: '50%', transform: 'translateY(-50%)' }}
          >
            {isShowPassword ? <VisibilityIcon /> : <VisibilityHiddenIcon />}
          </IconButton>
        </StyledFormControl>
      )}
    />
  )
}

import { instance } from '../infrasturcture/http'
import { Warehouse } from '../types/warehousesTypes'

export default class WarehousesApi {
  static async getWarehouses({ isBooking = null, isRegion = null }: { isBooking?: boolean | null; isRegion?: boolean | null }) {
    const response = await instance.get('warehouses', { params: { is_booking: isBooking, is_region: isRegion } })
    return response.data.data
  }

  static async getOne(id: string) {
    const response = await instance.get(`warehouses/${id}`)
    return response.data.data
  }

  static async createWarehouse(warehouse: Warehouse) {
    const response = await instance.post('warehouses', warehouse)
    return response.data.data
  }

  static async deleteWarehouse(id: string) {
    const response = await instance.delete(`warehouses/${id}`)
    return response.data.data
  }

  static async editWarehouse(warehouse: Warehouse) {
    const response = await instance.post(`warehouses/${warehouse.id}`, warehouse)
    return response.data.data
  }

  static async updateWarehousesOrder(warehouse: { id: string; old: number; new: number }) {
    const response = await instance.post('warehouse-orders', warehouse)
    return response.data.data
  }

  static async filterWarehouses() {
    const response = await instance.get('filter/warehouses')
    return response.data.data
  }

  static async updateAccess(warehouseId: string, customers: string[]) {
    const response = await instance.post('/warehouses/access', { warehouseId, customers })
    return response.data.data
  }
}

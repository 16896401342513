import React from 'react'
import { Breadcrumbs } from '../../components/Breadcrumbs/Breadcrumbs'
import { StyledHeader } from '../../infrasturcture/theme/styled'
import { Button, Typography } from '@mui/material'
import { SaveIcon } from '../../infrasturcture/icons'
import { FormProvider, useForm } from 'react-hook-form'
import { Form } from './ui/Form'
import { yupResolver } from '@hookform/resolvers/yup'
import { sectionSchema } from '../../infrasturcture/yupSchemes/sectionSchema'
import { useActions } from '../../hooks/useActions'
import { useNavigate } from 'react-router-dom'
import styles from './Sections.module.css'

export const CreateSection: React.FC = () => {
  const navigate = useNavigate()
  const { createSectionThunkCreator } = useActions()
  const methods = useForm({ resolver: yupResolver(sectionSchema), mode: 'onChange' })

  const onSubmit = (data: any) => {
    return createSectionThunkCreator({ ...data, users: [] }, () => methods.reset(), navigate)
  }

  return (
    <div className={styles.container}>
      <Breadcrumbs />
      <StyledHeader>
        <Typography variant="h1">Новый раздел</Typography>
        <Button
          onClick={methods.handleSubmit(onSubmit)}
          disabled={methods.formState.isSubmitting}
          color="primary"
          startIcon={<SaveIcon />}
        >
          Сохранить
        </Button>
      </StyledHeader>
      <FormProvider {...methods}>
        <Form type="create" onSubmit={onSubmit} />
      </FormProvider>
    </div>
  )
}

import { ThunkAction } from 'redux-thunk'
import { RootState } from '..'
import SectionsApi from '../../api/SectionsApi'
import {
  Section,
  SectionsAction,
  SectionsActionTypes,
  SetSectionAction,
  SetSectionsErrorsAction,
  SetSectionsLoaded
} from '../../types/sectionsTypes'
import { SetAuthErrorAction } from '../../types/userTypes'
import { logoutThunkCreator } from './userActions'
import { NavigateFunction } from 'react-router-dom'
import { ErrorMessages } from '../../types/errorTypes'
import { errorsHandling } from '../../infrasturcture/utils/errorsHandling'

type ThunkType = ThunkAction<void, RootState, unknown, SectionsAction | SetAuthErrorAction>

export const setSection = (payload: Section | null): SetSectionAction => ({
  type: SectionsActionTypes.SET_SECTION,
  payload
})

export const setSectionsLoaded = (payload: boolean): SetSectionsLoaded => ({
  type: SectionsActionTypes.SET_SECTIONS_LOADED,
  payload
})

export const setSectionsErrors = (payload: ErrorMessages): SetSectionsErrorsAction => ({
  type: SectionsActionTypes.SET_SECTIONS_ERRORS,
  payload
})

export const getSectionsThunkCreator = (): ThunkType => {
  return async (dispatch) => {
    try {
      dispatch(setSectionsLoaded(true))
      const data = await SectionsApi.getSections()
      dispatch({ type: SectionsActionTypes.GET_SECTIONS, payload: data })
    } catch (e: any) {
      dispatch(logoutThunkCreator())
    }
  }
}

export const getSectionThunkCreator = (id: string, navigate: NavigateFunction): ThunkType => {
  return async (dispatch) => {
    try {
      dispatch(setSectionsLoaded(true))
      const data = await SectionsApi.getOne(id)
      dispatch({ type: SectionsActionTypes.GET_SECTION, payload: data })
    } catch (e: any) {
      if ([403, 404, 503].includes(e.response.status)) {
        return navigate(`/${e.response.status}`)
      }
      dispatch(logoutThunkCreator())
    }
  }
}

export const createSectionThunkCreator = (section: Section, cb: () => void, navigate: NavigateFunction): ThunkType => {
  return async (dispatch) => {
    try {
      await SectionsApi.createSection(section)
      cb()
    } catch (e: any) {
      switch (e.response.status) {
        case 422:
          dispatch(setSectionsErrors(errorsHandling(e.response)))
          break
        case 503:
          navigate('/503')
          break
        default:
          dispatch(logoutThunkCreator())
      }
    }
  }
}

export const editSectionThunkCreator = (section: Section, cb: () => void, navigate: NavigateFunction): ThunkType => {
  return async (dispatch) => {
    try {
      await SectionsApi.editSection(section)
      dispatch({ type: SectionsActionTypes.UPDATE_SECTION, payload: section })
      cb()
    } catch (e: any) {
      if (e.response.status === 422) return dispatch(setSectionsErrors(errorsHandling(e.response)))
      if ([403, 404, 503].includes(e.response.status)) return navigate(`/${e.response.status}`)
      dispatch(logoutThunkCreator())
    }
  }
}

export const deleteSectionThunkCreator = (id: string, cb: () => void, navigate: NavigateFunction): ThunkType => {
  return async (dispatch) => {
    try {
      await SectionsApi.deleteSection(id)
      cb()
    } catch (e: any) {
      if (e.response.status === 422) return dispatch(setSectionsErrors(errorsHandling(e.response)))
      if ([403, 404, 503].includes(e.response.status)) return navigate(`/${e.response.status}`)
      dispatch(logoutThunkCreator())
    }
  }
}

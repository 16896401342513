import React from 'react'
import styles from './Label.module.css'

interface LabelProps {
  text: string
}

export const Label: React.FC<LabelProps> = ({ text }) => {
  return <span className={styles.container}>{text}</span>
}

import React, { useEffect } from 'react'
import { Snackbar, SxProps, Typography } from '@mui/material'
import Slide, { SlideProps } from '@mui/material/Slide'
import clsx from 'clsx'
import { CloseMediumIcon } from '../../../infrasturcture/icons'
import styles from './SnacbarModal.module.css'

type TransitionProps = Omit<SlideProps, 'direction'>

const TransitionUp = (props: TransitionProps) => {
  return <Slide {...props} direction="down" />
}

interface CommonProps {
  open: boolean
  message?: string | null
  sx?: SxProps
  autoHideDuration?: number
  render?: () => void
  onCancel: () => void
  isError?: boolean
}

interface IWithActionsProps extends CommonProps {
  isActions?: true
  onAccept: () => void
}

interface IWithoutActionsProps extends CommonProps {
  isActions: false
  onAccept?: never
}

type SnacbarModalProps = IWithActionsProps | IWithoutActionsProps

export const SnacbarModal: React.FC<SnacbarModalProps> = ({
  open,
  message,
  isActions = true,
  isError = false,
  autoHideDuration = 4000,
  onAccept,
  onCancel,
  sx
}) => {
  const [transition, setTransition] = React.useState<React.ComponentType<TransitionProps> | undefined>(undefined)

  const handleTransition = (Transition: React.ComponentType<TransitionProps>) => () => setTransition(() => Transition)

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') return
    onCancel()
  }

  useEffect(() => {
    if (open) handleTransition(TransitionUp)()
  }, [open])

  return (
    <Snackbar
      autoHideDuration={autoHideDuration}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      sx={sx}
      TransitionComponent={transition}
      key={transition ? transition.name : ''}
      open={open}
      onClose={handleClose}
    >
      <div className={styles.container}>
        {isError && (
          <Typography variant="h2" color="error.main" sx={{ mb: '12px' }}>
            Ошибка
          </Typography>
        )}
        <p className={styles.message}>{message}</p>
        <button onClick={handleClose} className={styles.closeButton}>
          <CloseMediumIcon />
        </button>
        {isActions && (
          <div className={styles.action}>
            <button onClick={onAccept} className={styles.button}>
              Да
            </button>
            <button onClick={handleClose} className={clsx(styles.button, styles.red)}>
              Нет
            </button>
          </div>
        )}
      </div>
    </Snackbar>
  )
}

import { UsersAction, UsersState, UsersActionTypes } from '../../types/usersTypes'

const initialState: UsersState = {
  users: [],
  user: null,
  isLoaded: false,
  errors: {}
}

export const usersReducer = (state = initialState, action: UsersAction): UsersState => {
  switch (action.type) {
    case UsersActionTypes.GET_USERS:
      return {
        ...state,
        isLoaded: false,
        users: action.payload,
        user: null
      }
    case UsersActionTypes.GET_USER:
      return {
        ...state,
        user: action.payload,
        isLoaded: false
      }
    case UsersActionTypes.SET_USER:
      return {
        ...state,
        user: action.payload
      }
    case UsersActionTypes.UPDATE_USER:
      return {
        ...state,
        errors: {},
        user: action.payload
      }
    case UsersActionTypes.SET_LOADED:
      return {
        ...state,
        isLoaded: action.payload
      }
    case UsersActionTypes.SET_USERS_ERRORS:
      return {
        ...state,
        errors: action.payload
      }
    default:
      return state
  }
}

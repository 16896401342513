import React, { ChangeEvent, useState } from 'react'
import { Filter } from '../../components/Filter/Filter'
import { Manager, OperationFilters, operationTypes, operationTypesRus } from '../../types/operationsTypes'
import { useActions } from '../../hooks/useActions'
import { useTypeSelector } from '../../hooks/useTypeSelector'
import { FilterSection } from '../../components/Filter/FilterSection'
import { Checkbox } from '../../components/Checkbox/Checkbox'
import { FilterList } from '../../components/Filter/FilterList'
import { LabelSelected } from '../../components/Label/LabelSelected'
import { Warehouse } from '../../types/warehousesTypes'
import { Product } from '../../types/productsTypes'
import DatePicker from 'react-datepicker'
import ruLocale from 'date-fns/locale/ru'
import { DateHeader } from '../../components/DateInput/DateHeader'
import { Box } from '@mui/material'
import { formatDate } from '../../infrasturcture/utils/formatDate'
import { Option } from '../../components/Options/Option'
import { statusTypes, statusTypesRus } from '../../types/bookingsTypes'
import { OrderFilters } from '../../types/ordersTypes'

const initialOperationsFilters: OperationFilters = {
  type: null,
  managers: [],
  products: [],
  warehouses: [],
  startDate: null,
  endDate: null,
  sku: null
}

const initialBookingsFilters: OrderFilters = {
  status: null,
  managers: [],
  products: [],
  warehouses: [],
  startDate: null,
  endDate: null,
  sku: null
}

interface OperationsFilterProps {
  type: 'operations' | 'bookings' | 'orders'
}

export const OperationsFilter: React.FC<OperationsFilterProps> = ({ type }) => {
  const { setOperationFilters, setBookingFilters, setOrderFilters } = useActions()
  const managers = useTypeSelector((state) => state.operations.managers)
  const products = useTypeSelector((state) => state.products.products).sort((a, b) => a.name.localeCompare(b.name))
  const warehouses = useTypeSelector((state) => state.warehouses[type === 'operations' ? 'warehouses' : 'filterWarehouses'])
  const stateFilters = useTypeSelector((state) => state[type].filters)

  const setActions = {
    operations: setOperationFilters,
    bookings: setBookingFilters,
    orders: setOrderFilters
  }

  const setStateFilters = setActions[type]

  const initialFilters = type === 'operations' ? initialOperationsFilters : initialBookingsFilters

  const [filters, setFilters] = useState<OperationFilters | OrderFilters>(initialFilters)

  const handleTypeAndStatusChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    if (type === 'operations') {
      return setFilters((prev) => ({
        ...prev,
        type: (prev as OperationFilters).type !== value ? value : null
      }))
    }

    setFilters((prev) => ({
      ...prev,
      status: (prev as OrderFilters).status !== value ? value : null
    }))
  }

  const handleChange =
    (key: 'warehouses' | 'products' | 'managers', value: Warehouse | Product | Manager) => (e: ChangeEvent<HTMLInputElement>) => {
      const checked = e.target.checked
      setFilters((prev) => ({
        ...prev,
        [key]: checked
          ? [...prev[key], value]
          : (prev[key] as Array<Warehouse | Product | Manager>).filter((v) => v.id !== value.id)
      }))
    }

  const handleDelete = (key: 'type' | 'status' | 'managers' | 'warehouses' | 'products' | 'dates', value: string) => {
    if (key === 'type' || key === 'status') {
      setStateFilters({ [key]: null })
      return setFilters((prev) => ({ ...prev, [key]: null }))
    }
    if (key === 'dates') {
      setStateFilters({ startDate: null, endDate: null })
      return setFilters((prev) => ({ ...prev, startDate: null, endDate: null }))
    }
    const values = (filters[key] as Array<Warehouse | Product | Manager>).filter((v) => v.id !== value)
    setStateFilters({ [key]: values })
    setFilters((prev) => ({ ...prev, [key]: values }))
  }

  const handleToggle = () => setFilters(stateFilters)

  const handleApply = () => {
    filters && setStateFilters(filters)
  }

  const handleReset = () => {
    setStateFilters(initialFilters)
    setFilters(initialFilters)
  }

  return (
    <Filter
      onApply={handleApply}
      onReset={handleReset}
      onToggle={handleToggle}
      selectedComponent={
        <>
          {'type' in stateFilters && stateFilters.type && (
            <LabelSelected text={operationTypesRus[stateFilters.type]} onClick={() => handleDelete('type', '')} />
          )}
          {'status' in stateFilters && stateFilters.status && (
            <LabelSelected text={statusTypesRus[stateFilters.status]} onClick={() => handleDelete('status', '')} />
          )}
          {stateFilters.managers.map((item, i) => (
            <LabelSelected key={i} text={item.full_name} onClick={() => handleDelete('managers', item.id)} />
          ))}
          {stateFilters.startDate && (
            <LabelSelected
              text={`${formatDate(stateFilters.startDate)} ~ ${stateFilters.endDate && formatDate(stateFilters.endDate)}`.replace(
                / ~ null/,
                ''
              )}
              onClick={() => handleDelete('dates', '')}
            />
          )}
          {stateFilters.warehouses.map((item, i) => (
            <LabelSelected key={i} text={item.name} onClick={() => handleDelete('warehouses', item.id)} />
          ))}
          {stateFilters.products.map((item, i) => (
            <LabelSelected key={i} text={item.name} onClick={() => handleDelete('products', item.id)} />
          ))}
        </>
      }
    >
      <FilterSection title="Тип">
        {type === 'operations'
          ? Object.values(operationTypes).map((type) => (
              <Checkbox
                onChange={handleTypeAndStatusChange}
                key={type}
                label={operationTypesRus[type]}
                value={type}
                checked={type === (filters as OperationFilters).type}
                labelStyle={{ marginBottom: -4 }}
              />
            ))
          : Object.values(statusTypes).map((status) => (
              <Checkbox
                onChange={handleTypeAndStatusChange}
                key={status}
                label={statusTypesRus[status]}
                value={status}
                checked={status === (filters as OrderFilters).status}
                labelStyle={{ marginBottom: -4 }}
              />
            ))}
      </FilterSection>
      {!!managers.length && (
        <FilterSection title="Пользователь">
          {managers.slice(0, 5)?.map((manager, i) => (
            <Checkbox
              onChange={handleChange('managers', manager)}
              key={i}
              label={<Option text={manager.full_name} subText={manager.company} />}
              value={manager.id}
              checked={!!filters.managers.find((m) => m.id === manager.id)}
              labelStyle={{ marginBottom: 12 }}
            />
          ))}
          {managers.length > 5 && (
            <FilterList
              values={managers}
              searchKey="full_name"
              render={(manager) => (
                <Checkbox
                  onChange={handleChange('managers', manager)}
                  label={<Option text={manager.full_name} subText={manager.company} />}
                  value={manager.id}
                  checked={!!filters.managers.find((m) => m.id === manager.id)}
                  labelStyle={{ marginBottom: 12 }}
                />
              )}
            />
          )}
        </FilterSection>
      )}
      <FilterSection title="Дата" isCheckboxes={false}>
        <Box sx={{ mt: '20px', maxHeight: 387 }}>
          <DatePicker
            calendarClassName="small"
            renderCustomHeader={DateHeader}
            selected={filters.startDate}
            startDate={filters.startDate}
            endDate={filters.endDate}
            selectsRange
            inline
            onChange={(dates: any) => {
              const [startDate, endDate] = dates
              setFilters((prev) => ({ ...prev, startDate, endDate }))
            }}
            locale={ruLocale}
          />
        </Box>
      </FilterSection>
      {!!warehouses.length && (
        <FilterSection title="Склад">
          {warehouses.slice(0, 5)?.map((warehouse, i) => (
            <Checkbox
              onChange={handleChange('warehouses', warehouse)}
              key={i}
              label={warehouse.name}
              value={warehouse.id}
              checked={!!filters.warehouses.find((w) => w.id === warehouse.id)}
              labelStyle={{ marginBottom: -4 }}
            />
          ))}
          {warehouses.length > 5 && (
            <FilterList
              values={warehouses}
              searchKey="name"
              render={(warehouse) => (
                <Checkbox
                  onChange={handleChange('warehouses', warehouse)}
                  label={warehouse.name}
                  value={warehouse.id}
                  checked={!!filters.warehouses.find((w) => w.id === warehouse.id)}
                  labelStyle={{ marginBottom: -4 }}
                />
              )}
            />
          )}
        </FilterSection>
      )}
      {!!products.length && (
        <FilterSection title="Товар">
          {products.slice(0, 5)?.map((product, i) => (
            <Checkbox
              onChange={handleChange('products', product)}
              key={i}
              label={product.name}
              value={product.id}
              checked={!!filters.products.find((p) => p.id === product.id)}
              labelStyle={{ marginBottom: -4 }}
            />
          ))}
          {products.length > 5 && (
            <FilterList
              values={products}
              searchKey="name"
              render={(product) => (
                <Checkbox
                  onChange={handleChange('products', product)}
                  label={product.name}
                  value={product.id}
                  checked={!!filters.products.find((p) => p.id === product.id)}
                  labelStyle={{ marginBottom: -4 }}
                />
              )}
            />
          )}
        </FilterSection>
      )}
    </Filter>
  )
}

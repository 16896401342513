import React, { forwardRef } from 'react'
import DatePicker from 'react-datepicker'
import ruLocale from 'date-fns/locale/ru'
import styles from './DateInput.module.css'
import clsx from 'clsx'
import { CalendarIcon } from '../../infrasturcture/icons'
import { Button, PopperPlacementType, TextField } from '@mui/material'
import { DateHeader } from './DateHeader'
import { InputMask, InputMaskProps } from '@react-input/mask'
import { Control, Controller } from 'react-hook-form'

interface CommonProps {
  label?: string
  placeholder?: string
  error?: boolean
  type?: 'input' | 'button'
  maxDate?: Date
  minDate?: Date
  className?: string
  popperPlacement?: PopperPlacementType | undefined
  disabled?: boolean
}

interface DateInputFormProps extends CommonProps {
  control: Control<any>
  name: string
  selected?: never
  onChange?: never
}

interface DateInputDefaultProps extends CommonProps {
  control?: never
  name?: never
  selected?: Date | null | undefined
  onChange: (date: Date | null, event: React.SyntheticEvent<any> | undefined) => void
}

type DateInputProps = DateInputFormProps | DateInputDefaultProps

const ForwardedInputMask = forwardRef<HTMLInputElement, InputMaskProps>((props, forwardedRef) => {
  return (
    <InputMask
      ref={forwardedRef}
      mask="дд.мм.гггг"
      replacement={{ д: /\d/, м: /\d/, г: /\d/ }}
      showMask
      separate
      spellCheck={false}
      {...props}
    />
  )
})

const CustomInput = forwardRef((props: any, ref: any) =>
  props.type === 'button' ? (
    <Button onClick={props.onClick} ref={ref} color="primary" startIcon={<CalendarIcon />}>
      {props.value}
    </Button>
  ) : (
    <TextField
      {...props}
      type="text"
      inputRef={ref}
      label={props.label}
      InputLabelProps={props.error ? { shrink: true } : {}}
      InputProps={{
        inputComponent: ForwardedInputMask
      }}
      error={props.error}
      variant="outlined"
      fullWidth
      autoComplete="off"
    />
  )
)

export const DateInput: React.FC<DateInputProps> = ({
  control,
  name,
  onChange,
  selected,
  type = 'input',
  maxDate,
  minDate,
  label,
  placeholder,
  error = false,
  className,
  popperPlacement,
  disabled = false
}) => {
  return (
    <div className={clsx(styles.container, className)}>
      {control ? (
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, value, ref } }) => (
            <DatePicker
              popperPlacement={popperPlacement}
              locale={ruLocale}
              renderCustomHeader={DateHeader}
              selected={value}
              onChange={onChange}
              dateFormat="dd.MM.yyyy"
              maxDate={maxDate}
              minDate={minDate}
              customInput={<CustomInput ref={ref} type={type} label={label} error={error} placeholder={placeholder} />}
              disabled={disabled}
            />
          )}
        />
      ) : (
        <DatePicker
          popperPlacement={popperPlacement}
          locale={ruLocale}
          renderCustomHeader={DateHeader}
          selected={selected}
          onChange={onChange}
          dateFormat="dd.MM.yyyy"
          maxDate={maxDate}
          minDate={minDate}
          customInput={<CustomInput type={type} label={label} error={error} placeholder={placeholder} />}
        />
      )}
    </div>
  )
}

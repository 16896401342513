import React from 'react'
import { VgTableColumnType } from '../../../components/VgTable/VgTable'
import { ProductInBooking } from '../../../types/bookingsTypes'
import { ProductInfo } from '../../../components/Products/ProductInfo'
import { StyledCell } from '../../../infrasturcture/theme/styled'
import { money } from '../../../types/productsTypes'
import { store } from '../../../store'
import { Count } from '../../../components/Operations/Count'
import {
  minusBookingProduct,
  plusBookingProduct,
  removeBookingProduct,
  setBookingsCountErrors
} from '../../../store/actions/bookingsActions'
import { ProductInOrder } from '../../../types/ordersTypes'
import { addOrderProduct, minusOrderProduct, plusOrderProduct, updateOrderProduct } from '../../../store/actions/ordersActions'
import { Checkbox } from '../../../components/Checkbox/Checkbox'
import { Box } from '@mui/material'
import { OperationMenu } from '../../../components/Operations/OperationMenu'

interface Options {
  type: 'create' | 'edit'
  operationType: 'order' | 'booking'
  disabled: boolean
}

const handlePlus = (product: ProductInBooking) => {
  const availableCount = store.getState().bookings.products.find((p) => p.id === product.id)?.count

  if (product.count === availableCount) {
    return store.dispatch(
      setBookingsCountErrors({
        id: product.id,
        message: `Всего в наличии: ${availableCount} шт. Хотите заказать этот товар?`
      })
    )
  }
  store.dispatch(plusBookingProduct(product.id))
}

const handleEditPlus = (product: ProductInBooking) => {
  const findProductCount = store.getState().bookings.products.find((p) => p.id === product.id)?.count || 0
  const prevProductCount = product.prev_count || 0
  const availableCount = prevProductCount + findProductCount

  if (product.count === availableCount) {
    return store.dispatch(
      setBookingsCountErrors({
        id: product.id,
        message: `Всего в наличии: ${availableCount} шт. Хотите заказать этот товар?`
      })
    )
  }
  store.dispatch(plusBookingProduct(product.id))
}

const handleAddProductInOrder = (product: ProductInBooking) => {
  const isExist = store.getState().orders.productsInOrder.find((p) => p.id === product.id)
  if (!isExist) store.dispatch(addOrderProduct({ ...product, count: 1, count_left: 1 }))
}

export const getBookingTableConfig = ({ type, disabled, operationType = 'booking' }: Options) => {
  if (type === 'create') {
    const bookingProductsColumns: VgTableColumnType<ProductInBooking>[] = [
      {
        key: 'name',
        title: 'Товар',
        render: (_, product) => <ProductInfo product={product} isShowTooltip={false} />,
        width: '34%'
      },
      {
        key: 'color',
        title: 'Цвет',
        render: (_, { color }) => <StyledCell>{color}</StyledCell>,
        width: '22%'
      },
      {
        key: 'purchase_price',
        title: 'Розничная цена',
        render: (_, item) => (item.purchase_price ? `${item.purchase_price} ${money.RUB}` : ''),
        width: '22%'
      },
      {
        key: 'count',
        title: 'Количество',
        render: (_, product) => {
          return (
            <Count
              {...(!disabled
                ? {
                    onPlus: () => handlePlus(product),
                    onMinus: () => store.dispatch(minusBookingProduct(product.id)),
                    disabledPlus: store.getState().bookings.countErrors?.id === product.id
                  }
                : {})}
            >
              {product.count}
            </Count>
          )
        },
        textAlign: 'center',
        width: '22%'
      }
    ]

    const orderProductsColumns: VgTableColumnType<ProductInOrder>[] = [
      {
        key: 'name',
        title: 'Товар',
        render: (_, product) => <ProductInfo product={product} isShowTooltip={false} />,
        width: '30%'
      },
      {
        key: 'color',
        title: 'Цвет',
        render: (_, { color }) => <StyledCell>{color}</StyledCell>,
        width: '15%'
      },
      {
        key: 'purchase_price',
        title: 'Розничная цена',
        render: (_, item) => (item.purchase_price ? `${item.purchase_price} ${money.RUB}` : ''),
        width: '20%'
      },
      {
        key: 'delivery_time',
        title: 'Время доставки',
        render: (_, item) => `${item.delivery_time} дн`,
        width: '20%'
      },
      {
        key: 'count',
        title: 'Количество',
        render: (_, { id, count }) => {
          return (
            <Count
              {...(!disabled
                ? {
                    onPlus: () => store.dispatch(plusOrderProduct(id)),
                    onMinus: () => store.dispatch(minusOrderProduct(id))
                  }
                : {})}
            >
              {count}
            </Count>
          )
        },
        textAlign: 'center',
        width: '15%'
      }
    ]

    return { bookingProductsColumns, orderProductsColumns }
  }

  const bookingProductsColumns: VgTableColumnType<ProductInBooking>[] = [
    {
      key: 'name',
      title: 'Товар',
      render: (_, product) => <ProductInfo product={product} isShowTooltip={false} />,
      width: '20%'
    },
    {
      key: 'color',
      title: 'Цвет',
      render: (_, { color }) => <StyledCell>{color}</StyledCell>,
      width: '14%'
    },
    {
      key: 'purchase_price',
      title: 'Розничная цена',
      render: (_, item) => (item.purchase_price ? `${item.purchase_price} ${money.RUB}` : ''),
      width: '15%'
    },
    {
      key: 'manufacturer_name',
      title: 'Производитель',
      render: (_, item) => <StyledCell>{item.manufacturer_name}</StyledCell>,
      width: '15%'
    },
    {
      key: 'write_off_left',
      title: 'Осталось списать',
      render: (_, item) => <Count>{item.write_off_left}</Count>,
      textAlign: 'center',
      width: '15%'
    },
    {
      key: 'count',
      title: 'Количество',
      render: (_, product) => {
        return (
          <Count
            {...(!disabled
              ? {
                  onPlus: () => handleEditPlus(product),
                  onMinus: () => store.dispatch(minusBookingProduct(product.id)),
                  disabledPlus: store.getState().bookings.countErrors?.id === product.id,
                  disabledMinus: product.write_off_left === 0
                }
              : {})}
          >
            {product.count}
          </Count>
        )
      },
      textAlign: 'center',
      width: '15%'
    },
    {
      key: 'id',
      title: '',
      render: (_, item) =>
        !disabled ? (
          <OperationMenu
            onAdd={() => handleAddProductInOrder(item)}
            onDelete={() => store.dispatch(removeBookingProduct(item.id))}
          />
        ) : undefined,
      width: '6%',
      textAlign: 'center'
    }
  ]

  const orderProductsColumns: VgTableColumnType<ProductInOrder>[] = [
    {
      key: 'name',
      title: 'Товар',
      render: (_, product) => <ProductInfo product={product} isShowTooltip={false} />,
      width: '22%'
    },
    {
      key: 'color',
      title: 'Цвет',
      render: (_, { color }) => <StyledCell>{color}</StyledCell>,
      width: '10%'
    },
    {
      key: 'purchase_price',
      title: 'Розничная цена',
      render: (_, item) => (item.purchase_price ? `${item.purchase_price} ${money.RUB}` : ''),
      width: '13%'
    },
    {
      key: 'checkbox',
      title: 'Заказан',
      render: (_, product) => (
        <Box sx={{ m: '-10px 0' }}>
          <Checkbox
            onChange={(e) => store.dispatch(updateOrderProduct({ ...product, checkbox: e.target.checked }))}
            checked={product.checkbox}
            disabled={disabled}
          />
        </Box>
      ),
      width: '11%',
      textAlign: 'center'
    },
    {
      key: 'delivery_time',
      title: 'Время доставки',
      render: (_, item) => `${item.delivery_time} дн`,
      width: '12%',
      textAlign: 'center'
    },
    {
      key: 'count_left',
      title: operationType === 'booking' ? 'Осталось списать' : 'Осталось оприходовать',
      render: (_, item) => <Count>{operationType === 'booking' ? item.write_off_left : item.count_left}</Count>,
      textAlign: 'center',
      width: '16%'
    },
    {
      key: 'count',
      title: 'Количество',
      render: (_, product) => {
        return (
          <Count
            {...(!disabled
              ? {
                  onPlus: () => store.dispatch(plusOrderProduct(product.id)),
                  onMinus: () => store.dispatch(minusOrderProduct(product.id)),
                  disabledMinus: product.write_off_left === 0
                }
              : {})}
          >
            {product.count}
          </Count>
        )
      },
      textAlign: 'center',
      width: '16%'
    }
  ]

  return { bookingProductsColumns, orderProductsColumns }
}

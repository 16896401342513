import React from 'react'
import styles from './VgTable.module.css'
import clsx from 'clsx'
import { VgTableColumnType } from './VgTable'

interface VgTableTheadProps<T> {
  columns: VgTableColumnType<T>[]
}

export const VgTableThead = <T,>({ columns }: VgTableTheadProps<T>) => {
  return (
    <thead className={styles.thead}>
      <tr className={styles.row}>
        {columns.map(({ title, width, textAlign, style }, i) => (
          <td key={i} className={clsx(styles.cell, styles.alignLeft)} style={{ width, textAlign, ...style }}>
            {title}
          </td>
        ))}
      </tr>
    </thead>
  )
}
